import React, { useEffect, useState } from "react";
// import { TextInput } from '../../commons/InputGroup/TextInput';
import { TextInput } from "../../commons/InputGroupMui/TextInput";
import PhoneInput from "../../commons/InputGroupMui/PhoneInput";

export function PatintBrief(props) {
  return (
    <>
      <div className="row" >
        <div className="col-6">
          {/* <TextInput label='Ad' id ='first_name' defultValue={props.defultValue?.first_name} disabled/> 
                <TextInput label='Telefon Numarası' id ='tel_no'  defaultValue={props.defultValue?.tel_no} disabled/>  */}
          <TextInput
            label={"Ad"}
            id={"first_name"}
            value={props.defultValue?.first_name}
            disabled
            fullWidth
          />
        </div>
        <div className="col-6">
          <PhoneInput
            id="tel_no"
            required
            label="Telefon Numarası"
            defaultValue={props.defultValue?.tel_no} 
            disabled
          />
        </div>
      </div>
      <div className="row" style={{height:'15px'}}></div>
      <div className="row" >
        <div className="col-6">
          <TextInput
            label="Soyad"
            id="last_name"
            value={props.defultValue?.last_name}
            disabled
            fullWidth
          />
        </div>
        <div className="col-6">
          <TextInput
            label="Email"
            id="email"
            value={props.defultValue?.email}
            disabled
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
