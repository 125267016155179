import react, { useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import TableHeadMui from '@mui/material/TableHead';
import TableRowMui from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import TableSortLabel from '@mui/material/TableSortLabel';
import './TableHeadComponent.css';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';


export default function TableHead(props){
    const {headers,
           filter,
           onClickSearch,
           isRightActions,
           isLeftActions,
           isGeoJson,
           id,
           order, 
           orderBy,
           onRequestSort,
           fontSize,
           filtersvalues,
           rightActionWidth } = props;
    const [filterLabel,setFilterLabel] = useState('');
    const [lastInd,setLastInd] = useState(-1);
    const [filterKeys,setFilterKeys] = useState({});
    const [filterkey,setFilterKey] = useState('');
    
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const onClickFilter= (d,ind,label)=>{
        let isOpen = document.getElementById('filterPopup').style.display
        if(ind == lastInd){
            if(isOpen == 'flex'){
                document.getElementById('filterPopup').style.height = '0px';
                document.getElementById('filterPopup').style.display = 'none';
                setFilterLabel('');
                setFilterKey('');
            }else{
                setFilterLabel(label);
                setFilterKey(headers[ind]? filterKeys[headers[ind].id]: '');
                var rect = document.getElementById('tableCell'+ind).getBoundingClientRect();console.log(rect);
                document.getElementById('filterPopup').style.display = 'flex';
                document.getElementById('filterPopup').style.width = rect.width +'px';
                document.getElementById('filterPopup').style.height = '150px';
                document.getElementById('filterPopup').style.top = rect.bottom+'px';
                document.getElementById('filterPopup').style.left = rect.left+'px';
                //document.getElementById('filter').value = '';    
            }
        }else{
            document.getElementById('filterPopup').style.height = '0px';
            document.getElementById('filterPopup').style.display = 'none';
            
                
            setFilterLabel(label);
            setFilterKey(headers[ind]? filterKeys[headers[ind].id]: '');
            var rect = document.getElementById('tableCell'+ind).getBoundingClientRect();
            document.getElementById('filterPopup').style.display = 'flex';
            document.getElementById('filterPopup').style.height = '150px';
            document.getElementById('filterPopup').style.width = rect.width +'px';
            document.getElementById('filterPopup').style.top = rect.bottom+'px';
            document.getElementById('filterPopup').style.left = rect.left+'px';
            document.getElementById('filter').value = '';
            setLastInd(ind);
        }
    };

    const closePopup = ()=>{
        //sleep(1000).then(()=> document.getElementById('filterPopup').style.display = 'none');
        document.getElementById('filterPopup').style.display = 'none';
    };

    const onClickClearFilter = (ind) => {
        let json = filterKeys ;
        delete json[headers[ind].id];
        setFilterKeys(json);
        onClickSearch(json);
    };

    const createSortHandler = (property,header) => (event) => {
        onRequestSort(event, property,header);
    };

    const onClickAra=()=>{
        let key = document.getElementById('filter').value;
        if(key != ''){
            let json = filterKeys;
            json[headers[lastInd].id] = key;
            setFilterKeys(json);
            //console.log(json);
            document.getElementById('filterPopup').style.display = 'none';
            
            onClickSearch(json);
        }
    };

    useEffect(() => {
        setFilterKeys(filtersvalues);   
    },[filterKeys]);
    return (<>
        <TableHeadMui id = {id}>
            <TableRowMui>
                {isLeftActions?
                    <TableCell
                        style={{ borderLeft:'1px solid lightgray',width:'40px'}}
                        align={'right'}
                        padding={'normal'}
                        />
                    :""}
                {headers.map((header,ind) =>{
                    return <TableCell
                        id  = {'tableCell'+ind}
                        sx={{ borderLeft:'1px solid lightgray'}}
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        size = 'small'
                        >
                        <div style={{alignItems:'center',display:'flex',justifyContent:'space-between'}}>
                            <div style={{width:filter?'80%':'100%',height:'45px',alignItems:'center',display:'flex'}}>
                            {header.isSortable ?
                                <TableSortLabel
                                    active={orderBy.id === header.id}
                                    direction={orderBy.id === header.id ? order : 'asc'}
                                    onClick={createSortHandler(header.id , header)}
                                    >
                                        <label style={{fontSize:fontSize?fontSize:''}} ><b>{header.label?header.label:header.id}</b></label>
                                        {orderBy === header.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                </TableSortLabel>
                            :
                                <label style={{fontSize:fontSize?fontSize:''}}><b>{header.label?header.label:header.id}</b></label>
                            }    
                            </div>
                            {filter &&
                                <div >
                                    <IconButton
                                        id = {'filterbtn'+ind} 
                                        onClick={(d)=> onClickFilter(d,ind,header.label)}>
                                        <FilterListIcon style={
                                            filterKeys[header.id] ? {color:'blue'}:{color:'black'}
                                        }/>
                                    </IconButton>
                                    {filterKeys[header.id]? 
                                        <IconButton
                                            id = {'filterbtn'+ind} 
                                            onClick={()=> onClickClearFilter(ind)}>
                                            <ClearOutlinedIcon />
                                        </IconButton>
                                       :
                                       <></> 
                                    }
                                </div>
                            }
                        </div> 
                    </TableCell>
                })}
                {isRightActions?
                    <TableCell
                        style={{ borderLeft:'1px solid lightgray',width:rightActionWidth?rightActionWidth:''}}
                        align={'right'}
                        padding={'normal'}
                    />
                :
                    ""
                }
            </TableRowMui>
        </TableHeadMui>
        <div className="filterPopup" id="filterPopup" /*onMouseLeave={()=>closePopup()}*/>
            <div className="row" style={{width:'100%'}}>
                <div className="col-12" style={{margin:'5px'}}>
                    {filterLabel}
                </div>
                <div className="col-12" style={{margin:'5px'}}>
                    <div class="form-group">
                        <input
                            style={{backgroundColor:'#f1f1f1',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 2px'}} 
                            type="text" 
                            class="form-control form-control" 
                            id="filter" 
                            placeholder=""
                            value={filterkey}
                            onChange ={(e)=>setFilterKey(e.target.value)}/>
                    </div>
                </div>
                <div className="col-12" style={{marginTop:'5px',justifyContent:'center',display:'flex',alignItems:'baseline'}}>
                    <button 
                        style={{borderRadius:'8px',
                                width:'80%',
                                backgroundColor:'#00aeff'}} 
                        type="button" 
                        class="btn btn-info"
                        onClick={()=> onClickAra()}>
                        Ara
                    </button>
                </div>
            </div>
            
            
        </div>
        </>);
}

TableHead.propTypes = {
    headers :PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                numeric: PropTypes.bool,
                disablePadding: PropTypes.bool,
                label: PropTypes.string,
            })).isRequired,
};