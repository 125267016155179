import React, { useState } from "react";
import "./SubServicesComponent.css";
import logo   from '../../../../../assets/logo.svg';
import { t } from "i18next";

export function SubServicesComponent(props) {
  const onClickSubService=(id)=>{
    console.log(id);
  }
  return (
    <div className="subServicesComponentContainer">
      <div className="ServicTitleContainer">
        <div className="ServicTitleInnerContainer">
          <h2>{props.title}</h2>
        </div>
      </div>
      <div className="ServicimgContainer">
        <div className="ServicimgInnerContainer">
          <img 
            style={{width:'100%',height:'100%',objectFit:'cover'}}
            src = {props.img}
          />
        </div>  
      </div>
      <div className="ServicinfoContainer">
        <div className="ServicinfoInnerContainer">
          <div className="ServicinfoListContainer">
            {props.list}
          </div>
        </div>
      </div>
      <div className="ServicLogoContainer">
        <img src={logo} style={{width:'90%'}} alt="SHEREEN CARE"></img>
      </div>
      <div className="ServicContactContainer">
        <label className="font-size-lg" >{t(`Service.contact.text.0`)}</label>
        <label className="font-size-m" dangerouslySetInnerHTML={{__html:t('Service.contact.text.1')}}></label>
        <i class="fa-solid fa-phone" style={{fontSize:'50px',color:'grey'}}></i>
        <label className="font-size-s"><b>(+90) 533 371 1359</b></label>
        <label className="font-size-s"><b>(+39) 377 365 5295</b></label>
        <label className="font-size-s"><b>(+43) 681 81713286</b></label>
      </div>
    </div>
  );
}
