import React from 'react'
import {NavBar} from '../../../navBar/NavBar'
import BlogPageHome from './BlogPageHome/BlogPageHome'


function BlogPageMain() {
    return (
        <>
            <div id="BlogPageMainBody" className="BlogPageMainBody">
                <div className="BlogPageMainContainer">
                    <BlogPageHome/>
                </div>
            </div>
        </>
    )
}

export default BlogPageMain