import React from 'react'
import './blogSidebar.css'
import logo from '../../../../../../assets/logo.svg'

function BlogSidebar() {
  return (
    <>
      <div className="blogSidebar">
          <div className="sidebarItem">
              <span className="sidebarTitle">ABOUT</span>
              <img src={logo} className="sidebarIMG" alt="SHEREEN CARE"/>
              <p>We are a healthcare company that provides medical services in our clinics and partner hospitals.We introduce complete services to our patients to make their health journey a fabulous experience.</p>
          </div>
          <div className="sidebarItem">
            <span className="sidebarTitle">SERVICES</span>
            <ul className="sidebarList">
              <li className="sidebarListItem">BEAUTY SERVICES</li>
              <li className="sidebarListItem">DENTAL CARE</li>
              <li className="sidebarListItem">HAIR TRANSPLANT</li>
              <li className="sidebarListItem">BEAUTY PROCEDURES</li>
              <li className="sidebarListItem">PLASTIC SURGERY</li>
            </ul>
          </div>
          <div className="sidebarItem">
            <span className="sidebarTitle">FOLLOW US</span>
            <div className="sidebarSocial">
              <i class="sidebarIcon fa-brands fa-square-facebook"></i>
              <i class="sidebarIcon fa-brands fa-tiktok"></i>
              <i class="sidebarIcon fa-brands fa-google"></i>
              <i class="sidebarIcon fa-brands fa-square-instagram"></i>
              <i class="sidebarIcon fa-brands fa-linkedin"></i>
            </div>
          </div>
      </div>
    </>
  )
}

export default BlogSidebar