import React, { useEffect, useState } from 'react';
import './style.css';

export function UserTable(props){

    useEffect(()=>{
        return()=>{};
    },[props.list])
    return (<>
        <div className='col-12' style={{width:'100%',paddingInline:'25px'}}>
            <div className="tbl-header">
                <table className='userTable' cellpadding="0" cellspacing="0" border="0">
                    <thead >
                        <tr>
                            <th>Personel Adı</th>
                            <th>Kullanıcı Adı</th>
                            <th>EMAIL</th>
                            <th>Telefon Numarası</th>
                            <th width='80px'></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tbl-content">
            <table className='userTable'>
                <tbody>
                    {props.list?.map((e,ind)=>{
                        return <> 
                        <tr className='userTableRow'>
                            <td>{e.first_name + " " + e.last_name}</td>
                            <td>{e.username}</td>
                            <td>{e.email}</td>
                            <td>{e.tel_no}</td>
                            <td width='80px'>
                                <div style={{display:'flex',width:'100%',height:'100%',justifyContent:'space-evenly'}}>
                                    <i class="fa-solid fa-user-pen" style={{fontSize:'16px',color:'blue',cursor:'pointer'}} onClick={()=> props.onClickEdit(e.id)}></i>
                                    <i class="fa-solid fa-trash-can" style={{fontSize:'16px',color:'red',cursor:'pointer'}} onClick={()=> props.onClickDelete(e.id,ind)}></i>
                                </div>
                            </td>
                        </tr>
                    </>})}
                </tbody>
            </table>
            </div>
        </div>
    
    
    
    
    </>)
}