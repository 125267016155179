import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Services } from '../Component/Services/Services';


export function ServicesRouter(){

    useEffect(()=>{
        return ()=>{};
    },[])

    return (
        <Route exact path = '/app/services'>
            <Services/>
        </Route>      
    )
}