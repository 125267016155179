import React, { useEffect } from 'react';
import './MainRoutes.css';
import { Route } from 'react-router-dom';
import { DashBoard } from '../Component/DashBoard/DashBoard';

export function DashBoardRouter(){

    useEffect(()=>{
        return ()=>{};
    },[])

    return (
        <Route exact path = '/app/dashboard'>
            <DashBoard/>
        </Route>
        
    )
}