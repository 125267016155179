import React from "react";
import "./AboutShereenCare2.css";
import aboutwalpaper from "../../../../../assets/aboutwallpaper.jpg";
import i18next, { t } from "i18next";
export default function AboutShereenCare2() {
  return (
    <div className="aboutSherrenCare">
      <div className="content">
        <div className="header">
          <div className={i18next.language?"header-text-AR":"header-text"}>{t('ABOUT_SHEREEN_CARE.header')}</div>
        </div>
        <div className="body">
          <div className="body-inner">
            <img src={aboutwalpaper} />
            <div className="body-innerone">
              <div className="textone" dangerouslySetInnerHTML={{__html:t('ABOUT_SHEREEN_CARE.content.body.text1')}}>
              </div>
            </div>
            <div className="body-innertwo">
              <div className="texttwo" dangerouslySetInnerHTML={{__html:t('ABOUT_SHEREEN_CARE.content.body.text2')}}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
