import React, { useEffect, useState } from "react";
import "./ServicesPageMenu.css";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";

export function ServicesPageMenu(props) {
    const [isOpen , setIsOpen] = useState(false);
    const [subSevices , setSubServices] = useState([]);
    const handleClick = (event) => {
        if(event == 1){
            setSubServices([
                {id : 0  , text : t(`Service.navbar.0.items.0`)},
                {id : 1  , text : t(`Service.navbar.0.items.1`)},
                {id : 2  , text : t(`Service.navbar.0.items.2`)},
                {id : 3  , text : t(`Service.navbar.0.items.3`)},
                {id : 4  , text : t(`Service.navbar.0.items.4`)},
                {id : 5  , text : t(`Service.navbar.0.items.5`)},
                {id : 6  , text : t(`Service.navbar.0.items.6`)},
                {id : 7  , text : t(`Service.navbar.0.items.7`)},
                {id : 8  , text : t(`Service.navbar.0.items.8`)},
                {id : 9  , text : t(`Service.navbar.0.items.9`)},
                {id : 10 , text : t(`Service.navbar.0.items.10`)},
                {id : 11 , text : t(`Service.navbar.0.items.11`)},
                {id : 12 , text : t(`Service.navbar.0.items.12`)},
                {id : 13 , text : t(`Service.navbar.0.items.13`)},
                {id : 14 , text : t(`Service.navbar.0.items.14`)},
            ])
        }else if(event == 2){
            setSubServices([
                {id : 15 , text : t(`Service.navbar.1.items.0`)},
                {id : 16 , text : t(`Service.navbar.1.items.1`)},
                {id : 17 , text : t(`Service.navbar.1.items.2`)},
                {id : 18 , text : t(`Service.navbar.1.items.3`)},
                {id : 19 , text : t(`Service.navbar.1.items.4`)},
                {id : 20 , text : t(`Service.navbar.1.items.5`)},
                {id : 21 , text : t(`Service.navbar.1.items.6`)},
                {id : 22 , text : t(`Service.navbar.1.items.7`)},
                {id : 23 , text : t(`Service.navbar.1.items.8`)},
                {id : 24 , text : t(`Service.navbar.1.items.9`)},
                {id : 25 , text : t(`Service.navbar.1.items.10`)},
                {id : 26 , text : t(`Service.navbar.1.items.11`)},
            ])
        }else if(event == 3){
            setSubServices([
                {id : 27 , text : t(`Service.navbar.2.items.0`)},
                {id : 28 , text : t(`Service.navbar.2.items.1`)},
                {id : 29 , text : t(`Service.navbar.2.items.2`)},
                {id : 30 , text : t(`Service.navbar.2.items.3`)},
                {id : 31 , text : t(`Service.navbar.2.items.4`)},
            ])
        }else if(event == 4){
            setSubServices([
                {id : 32 , text : t(`Service.navbar.3.items.0`)},
                {id : 33 , text : t(`Service.navbar.3.items.1`)},
                {id : 34 , text : t(`Service.navbar.3.items.2`)},
                {id : 35 , text : t(`Service.navbar.3.items.3`)},
                {id : 36 , text : t(`Service.navbar.3.items.4`)},
                {id : 37 , text : t(`Service.navbar.3.items.5`)},
                {id : 38 , text : t(`Service.navbar.3.items.6`)},
            ])
        }else if(event == 5){
            setSubServices([
                {id : 39 , text : t(`Service.navbar.4.items.0`)},
                {id : 40 , text : t(`Service.navbar.4.items.1`)},
                {id : 41 , text : t(`Service.navbar.4.items.2`)},
                {id : 42 , text : t(`Service.navbar.4.items.3`)},
                {id : 43 , text : t(`Service.navbar.4.items.4`)},
                {id : 44 , text : t(`Service.navbar.4.items.5`)},
                {id : 45 , text : t(`Service.navbar.4.items.6`)},
                {id : 46 , text : t(`Service.navbar.4.items.7`)},
                {id : 47 , text : t(`Service.navbar.4.items.8`)},
                {id : 48 , text : t(`Service.navbar.4.items.9`)},
                {id : 49 , text : t(`Service.navbar.4.items.10`)},
                {id : 50 , text : t(`Service.navbar.4.items.11`)},
                {id : 51 , text : t(`Service.navbar.4.items.12`)},
                {id : 52 , text : t(`Service.navbar.4.items.13`)},
                {id : 53 , text : t(`Service.navbar.4.items.14`)},
            ])
        }
        setIsOpen(true)
    };
    const handleClose = () => {
        setIsOpen(false)
    };
    const onClickSubService=(id) => {
        if(props.onClickSubService){
            props.onClickSubService(id);
        }
    }

    const list = (group) => (
        <Box
          className = 'drawerBody'
          role="presentation"
          onClick={()=>handleClose()}
          onKeyDown={()=>handleClose()}
        >
          <List>
            {subSevices.map((subService, index) => (
              <ListItem key={subService.id} disablePadding>
                <ListItemButton
                    onClick={()=> onClickSubService(subService.id)}
                >
                  <label className="serviceMenuListItem">{subService.text}</label>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
    );

    useEffect(()=>{
        return()=>{};
    },[subSevices]);
  
    return (
    <>
        <div className="sevicesMenuContainer">
            <Button
                id="basic-button"
                onClick={()=>handleClick(1)}
                color ='darkColor'
                size="small"
                className="navItem"
            >
               <b> {t(`Service.navbar.0.name`)}</b> 
            </Button>
           
            <Button
                id="basic-button"
                onClick={()=>handleClick(2)}
                color ='darkColor'
                size="small"
                className="navItem"
            >
                <b>{t(`Service.navbar.1.name`)}</b>
            </Button>
            

            <Button
                id="basic-button"
                onClick={()=>handleClick(3)}
                color ='darkColor'
                size="small"
                className="navItem"
            >
                <b>{t(`Service.navbar.2.name`)}</b>
            </Button>
            
            <Button
                id="basic-button"
                onClick={()=>handleClick(4)}
                color ='darkColor'
                size="small"
                className="navItem"
            >
                <b>{t(`Service.navbar.3.name`)}</b>
            </Button>
            <Button
                id="basic-button"
                onClick={()=>handleClick(5)}
                color ='darkColor'
                size="small"
                className="navItem"
            >
                <b>{t(`Service.navbar.4.name`)}</b>
            </Button>
            <Drawer
                anchor={'left'}
                open={isOpen}
                onClose={()=> handleClose()}
                PaperProps={{
                    sx: {
                      backgroundColor: "rgb(185, 185, 185)",
                    }
                }}
            >
                {list()}
            </Drawer>
        </div>
      
    </>
    );
}
