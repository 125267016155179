import React, { useEffect, useState } from "react";
import { SocialMediaNavBar } from "./socialMediaNavBar/SocialMediaNavBar";
import "./NavBar.css";
import logo from "../../../assets/logo.svg";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Select from "@mui/material/Select";
import enflag from "../../../assets/doctor.jpg"
import { t } from "i18next";

export function NavBar(props) {
  let json = [
    { ad: "EN", kod: 0 },
    { ad: "TR", kod: 1 },
    { ad: "AR", kod: 2 },
  ];

  const handleChange = (event) => {
    const selectedLanguageCode = event.target.value;
    const selectedLanguage = json.find((d) => d.kod == selectedLanguageCode);
    console.log(selectedLanguage,"seleccc")
  
    if (selectedLanguage) {
      const selectedLanguageName = selectedLanguage.ad;
      console.log(selectedLanguageName,"gelen ad");
      props.lang(selectedLanguageName)
    }
  }
  const onClickCloseMenu = () => {
    document.getElementById("navBarBody").classList.remove("open");
    document.getElementById("navBarBtn").classList.add("show");
    document.getElementById("navBarBtn").classList.remove("hide");
  };

  const onClickOpenMenu = () => {
    document.getElementById("navBarBody").classList.add("open");
    document.getElementById("navBarBtn").classList.remove("show");
    document.getElementById("navBarBtn").classList.add("hide");
  };

  const onClickItem = (id, pageId = null) => {
    let items = document.getElementById("navBarItemsBody").children;
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove("selected");
    }
    document.getElementById(id).classList.add("selected");
    if (pageId !== null) {
      document.getElementById(pageId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  useEffect(() => {
    
    console.log(props)
    return () => {
      
    }
  }, [])
  
  

  useEffect(() => {
    window.addEventListener("scroll", function () {
      console.log("scrola girdi");
      // Kaydırma olayı gerçekleştiğinde yapılacak işlemler buraya yazılır
      onClickCloseMenu();
    });
  }, []);

  return (
    <>
      <div id="navBarBtn" className="navBarBtn show">
        <div
          className="navBarBtnIconContainer"
          onClick={() => onClickOpenMenu(true)}
        >
          <i class="fa-solid fa-bars navBarBtnIcon"></i>
        </div>
        <div className="navBarBtnLabelContainer">{t('MENU.1')}</div>
        <div className="navbarSocialBlock">
          <div>
            <i class="fas fa-phone-alt"></i>
            &nbsp;
            <a className="tel" href="tel:+905333711359">
              (+90) 533 371 1359
            </a>
          </div>
          <div>
            <i class="fas fa-at"></i>
            &nbsp;
            <a
              className="mail"
              href="mailto:?subject=Konu&body=İçerik&to=info@shereencare.com"
            >
              info@shereencare.com
            </a>
          </div>
          <select onChange={(e)=>handleChange(e)} className="languageSelect">
            {json.map((d)=>{
              return <option selected = {props.detectedLang == d.ad} value={d.kod} >{d.ad}</option>
            })}
          </select>
        </div>
      </div>
      <div id="navBarBody" className="navBarBody">
        <div className="navBarHeader">
        <select onChange={(e)=>handleChange(e)} className="languageSelect">
          {json.map((d)=>{
            return <option selected = {props.detectedLang == d.ad} value={d.kod} >{d.ad}</option>
          })}
          </select>
          <div className="navBarHeaderLabel">
            <label>{t('MENU.1')}</label>
          </div>
          <div className="navBarHeaderIconContainer">
            <i
              onClick={() => onClickCloseMenu()}
              class="fa-solid fa-angle-left navBarHeaderIcon"
            />
          </div>
        </div>
        <div className="navBarItemsContainer">
          <div id="navBarItemsBody" className="navBarItemsBody">
            <div
              id="home"
              onClick={() => onClickItem("home", "HomePageBody")}
              className="navBarItem selected"
            >
              <label className="navBarItemLabel">{t('Home.1')}</label>
            </div>
            <div
              id="about"
              onClick={() => onClickItem("about", "AboutPageBody")}
              className="navBarItem"
            >
              <label className="navBarItemLabel">{t('About.1')}</label>
            </div>
            <div
              id="services"
              onClick={() => onClickItem("services", "ServicePageBody")}
              className="navBarItem"
            >
              <label className="navBarItemLabel">{t('Services.1')}</label>
            </div>
            <div
              id="clinics"
              onClick={() => onClickItem("clinics", "ClinicsPageBody")}
              className="navBarItem"
            >
              <label className="navBarItemLabel">{t('Clinics.1')}</label>
            </div>
            <div id = 'contact' onClick={()=>onClickItem('contact','ContactUsPageBody')} className="navBarItem">
              <label className="navBarItemLabel">{t('Contact.1')}</label>
            </div> 
            {/* <div id = 'blog' onClick={()=>onClickItem('blog')} className="navBarItem">
             <Link to="/blog-page-main" > 
              <label className="navBarItemLabel" style={{color:"white"}}>Blog</label>
             </Link>
            </div>  */}
          </div>
        </div>
        <div className="openNavbarSocialBlock" >
          <div>
            <i class="fas fa-phone-alt"></i>
            &nbsp;
            <a className="tel" href="tel:+905333711359">(+90) 533 371 1359</a>
          </div>
          <div>
            <i class="fas fa-at"></i>
            &nbsp;
            <a className="mail" href="mailto:?subject=Konu&body=İçerik&to=info@shereencare.com">info@shereencare.com</a>
          </div>
            
        </div>
        <div className="socialContainer">
            <SocialMediaNavBar/>
        </div>
        <div className="logoContainer">
            <img src={logo} className="svglogo" alt="SHEREEN CARE"></img>
        </div>
      </div>
    </>
  );
}
