//import logo from './logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { SideBar } from './layout/SideBar/SideBar';
import { TopBar } from './layout/TopBar/TopBar';
import {BrowserRouter as Router , Route} from 'react-router-dom';
import {Login} from './pages/Login';
import { MainRoutes } from './Routes/MainRoutes';
import { MainComponent } from './Routes/MainComponent';
import { LandingPage } from './pages/landingPage/LandingPage';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import useAxiosPrivate from './hooks/useAxiosPrivate';
import { useEffect } from 'react';
import BlogPageDetails from './pages/landingPage/pages/blogsPage/BlogPageDetail/BlogPageDetails'
import BlogPageMain from './pages/landingPage/pages/blogsPage/BlogPageMain/BlogPageMain';
import { useTranslation } from 'react-i18next';
import { LoadingLogo } from './layout/LoadingLogo/LoadingLogo';
import { getCookie } from './utils/cookieUtils';
import axios from 'axios';
import { setUserInfo } from './Redux/Actions/userActions';
import { useState } from 'react';
function App(){
  const { t, i18n } = useTranslation();
  const [ready , setReady] = useState(false);
  const user = useSelector(state => state.getUserInfo);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const language=(lang)=>{
    i18n.changeLanguage(lang);
    //console.log(i18n.language)
  }

  const checkSession= async()=>{
    setReady(false)
    axiosPrivate.get('/refresh', {
      withCredentials: true
    })
    .then((res)=>{
      dispatch(setUserInfo({
        first_name : res.data.user.first_name,
        last_name  : res.data.user.last_name,
        username   : res.data.user.username,
        auth       : res.data.token ,
        id         : res.data.user.id,
        auth       : res.data.token
      }));
    })
    .catch((err)=>{})
    .finally(()=>{setReady(true)});
    //console.log(response)
    
  }

  useEffect(()=>{
    //console.log(i18n.language)
    checkSession();
    return()=>{ }
  },[])
  return (<>
    <ThemeProvider theme={theme}>
      {ready&&
        <Router>
          <Route exact path='/'>
            {user.auth?<Redirect to = '/app'/>:<LandingPage lang={language} detectedLang = {i18n.language}/>}
          </Route>
          <Route exact path='/login'>
            {user.auth?<Redirect to = '/app'/>:<Login/>}
          </Route>
          <Route path='/app' >
            {user.auth?<MainComponent/>:<Redirect to = '/'/>}
          </Route>
          <Route path='/blog-page-details' >
            <BlogPageDetails/>
          </Route>
          <Route path='/blog-page-main' >
            <BlogPageMain/>
          </Route>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Router>
      }
    </ThemeProvider> 
  </>);
}

export default App;
