import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Users } from '../Component/Users/Users';

export function UsersRouter(){

    useEffect(()=>{
        return ()=>{};
    },[])

    return (
        <Route exact path = '/app/users'>
            <Users/>
        </Route>      
    )
}