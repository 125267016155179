import React from 'react';
import './style.css';
export default function PopupComponent(props){
    return (<>
    {props.isOpen &&
    <div className='popup' onClick={()=> props.onClickClose()}>
        <div 
            className='popupBody' 
            style={{
                width :props.width?props.width : '',
                height : props.height?props.height : ''
            }} 
            onClick = {(e)=>{e.stopPropagation()}} 
        >
            <div className='row' style={{width:'100%',height:'100%', display:'flex',justifyContent:'center',margin:'0px',padding:'0px'}}>
                <div className='col-11' style={{textAlign:'center',height:'5%',margin:'0px'}}>
                    <label style={{marginTop:'5px',fontWeight:'bold'}}>
                        {props.title}
                    </label>
                </div>
                <div className='col-1' style={{textAlign:'end',height:'5%',padding:'0px'}}>
                    <button type='button' className='btn btn-light' style={{borderRadius:'0px 8px 0px 0px'}} onClick={()=> props.onClickClose()}>
                        <i className='fas fa-times'></i>
                    </button>
                </div>
                <hr style={{width :'90%',height:'1px',marginBottom:'-1px'}}/>
                <div style={{height:'92%',width:'100%'}}>
                    {props.children}
                </div>
            </div>
        </div>
    </div>}
    </>);
}