
import React from "react"; 
import './style.css';   
import { TextField } from "@mui/material";


export function TextAreaInput(props){
    return (<>
    
    <TextField
          autoComplete        = {props.autoComplete}
          autoFocus           = {props.autoFocus}
          classes             = {props.classes}
          color               = {props.color}
          defaultValue        = {props.defaultValue ? props.defaultValue : ''}
          disabled            = {props.disabled}
          FormHelperTextProps = {props.FormHelperTextProps}
          id                  = {props.id}
          InputLabelProps     = {props.InputLabelProps}
          inputProps          = {props.InputProps}
          inputRef            = {props.inputRef}
          margin              = {props.margin}
          maxRows             = {props.maxRows}
          minRows             = {props.minRows}
          multiline
          name                = {props.name}
          onChange            = {props.onChange?(e)=>props.onChange(e):()=>{}}
          placeholder         = {props.placeholder?props.label:''}
          rows                = {props.rows}
          select              = {props.select}
          SelectProps         = {props.SelectProps}
          size                = {props.size}
          type                = {props.type}
          value               = {props.value? props.value : ''}
          label               = {props.label}
          required            = {props.required}
          variant="standard"
          fullWidth = {props.fullWidth}
          helperText = {props.helperText ? props.error ? props.helperText :'' :''}
          error = {props.error}
          InputProps={{
            defaultValue : props.defaultValue ? props.defaultValue : '', 
            id :props.id,
            sx :{
              background:props.backgroundColor,     /// input background color 
              zIndex:'1',
              borderRadius:'4px 4px 0px 0px',
              color :props.inputFontColor,              /// input font color 
              paddingLeft:'5px',
              ...props.inputSx
            },
            ...props.InputProps
          }}
          sx={{
            
            input :{
              paddingLeft :'5px',
              ...props.sx?.input,
            },
            '& label': {
              color: props.labelColorNF,             /// label color 
              zIndex:'2',
              marginLeft:'5px',
              ...(props.sx?props.sx['& label']:{}),
            },
            '& label.Mui-focused': {
              color: props.labelColorAF,             /// label color after focused
              ...(props.sx?props.sx['& label.Mui-focused']:{}),
            },
            '& .MuiInput-underline:after': {     /// border color when focused 
              borderBottomColor: props.labelColorAF,
              ...(props.sx?props.sx['& .MuiInput-underline:after']:{}),
            },
            '& .MuiInput-underline:before': {    /// border color when not focused 
              borderBottomColor: props.labelColorNF,
              ...(props.sx?props.sx['& .MuiInput-underline:before']:{}),
            },
            
          }}
        />    
        {/* <Textarea
            slotProps={{
                textarea: {
                    id: props.id,
                }
            }}
            minRows={props.minRows?props.minRows:2}
            maxRows={props.maxRows?props.maxRows:10}
            placeholder={props.placeholder?props.placeholder:"Type in here…"}
            variant="soft"
            sx={{
                background : props.backgroundColor?props.backgroundColor:'transparent',
                borderBottom: '2px solid',
                borderColor: props.borderColor,
                color : props.inputFontColor?props.inputFontColor:'',
                borderRadius: 0,
                '&:hover': {
                    borderColor: props.borderColorhover,
                },
                '&::before': {
                    border: `1px solid ${props.borderColorFocused}`,
                    transform: 'scaleX(0)',
                    left: 0,
                    right: 0,
                    bottom: '-2px',
                    top: 'unset',
                    transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                    borderRadius: 0,
                },
                '&:focus-within::before': {
                    transform: 'scaleX(1)',
                },
            }}
        />  */}
    </>);
}