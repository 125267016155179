import React, { useEffect } from 'react';
import Tabel from '../../commons/TableComponent/TableComponent';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useState } from 'react';
import PopupComponent from '../../commons/PopupComponent';
import { RequestPeeker } from '../forms/RequestPeeker';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export function InProcessRequests(props){
    const axiosPrivate = useAxiosPrivate();
    const history      = useHistory();
    const [totalCount , setTotalCount]           = useState(0);
    const [tempFilters,setTempFilters]           = useState({});
    const [isLoading , setIsLoading]             = useState(false);
    const [selectedPage,setSelectedPage]         = useState(0);
    const [selectedPageSize,setSelectedPageSize] = useState(25);
    const [data,setData]                         = useState([]);
    const [selectedRequest , setSelectedRequest] = useState(null);
    const [popup,setPopup]                       = useState(false);
    
    const headers = [
        {id : 'first_name',numeric:false,disablepadding:false,label:'Ad',isSortable:true},
        {id : 'last_name',numeric:false,disablepadding:false,label:'Soyad',isSortable:true},
        {id : 'tel_no',numeric:false,disablepadding:false,label:'Telefon Numarası',isSortable:false},
        {id : 'country',numeric:false,disablepadding:false,label:'Uyruk',isSortable:false},
        {id : 'created_at',numeric:false,disablepadding:false,label:'Oluşturma Tarihi',isSortable:true,isDate:true,pattern:'yyyy-MM-dd'},   
    ];

    const getNewRequestsCount= async (filters)=>{
        await axiosPrivate.post('/requests/inprocess/count',filters)
        .then((res)=>{
            //console.log(res.data);
            setTotalCount(res.data.count);
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    const onChangePage=async(e)=>{
        console.log('asdasd');
        setSelectedPage(e);
        await getRequestList(e,selectedPageSize,tempFilters);
    };

    const onChangePageSize=async(e)=>{
        console.log('selected page size',e)
        setSelectedPageSize(e);
        await getRequestList(selectedPage,e,tempFilters);
    };

    const onChangeFilter=async(page,rowPerPage,filter)=>{
        setTempFilters(filter);
        await getNewRequestsCount(filter);
       //console.log(filter);
        await getRequestList(selectedPage,selectedPageSize,filter);
    }
    const getRequestList= async (pageNum,pageSize,filters)=>{
        await axiosPrivate.post('/requests/inprocess/'+pageNum+'/'+pageSize,filters)
        .then((res)=>{
            //console.log(res.data);
            setData(res.data.rows);
            //setTotalCount(res.data.count);
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    }
    const getData= async()=>{
        console.log('sssssssssssssssssssssss')
        await getNewRequestsCount(tempFilters);
        await getRequestList(selectedPage,selectedPageSize,tempFilters);
    }

    const onClickPick=(e,ind)=>{

        console.log(e,ind);
        history.push('/app/requests/inprocess/'+e.id);
    };

    const onClosePopup=(d)=>{
        setSelectedRequest(null);
        setPopup(false);
        if(d){
            getData();
        }
    };

    useEffect(()=>{
        console.log("useEffect")
        getData();
        return ()=>{};
    },[])

    return (<>
        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{width:'95%',height:'95%'}}>
                <Tabel
                    headers = {headers}
                    data    = {data}
                    getTotalCount = {totalCount}
                    isFilter 
                    filtersvalues = {tempFilters}
                    onChangedfilter = {(page,rowPerPage,filter)=> onChangeFilter(page,rowPerPage,filter)}
                    isPagination
                    isLoading = {isLoading}
                    selectedPage = {selectedPage}
                    onChangePage = {(e)=> onChangePage(e)}
                    selectedpageSize = {selectedPageSize}
                    onChangePageSize = {(e)=> onChangePageSize(e)}
                    isToptActions
                    isRightActions
                    topActionsOptions = {(e)=>{return <>
                        <button
                            className='btn btn-success btn-sm form-control'
                            style={{width:'120px',height:'85%', display:'flex',justifyContent:'space-between',alignItems:'center'}}
                        >
                            <i className='far fa-file-excel' style={{fontSize:"18px"}}/>
                            &nbsp;
                            <b>Excel</b>
                        </button>
                    </>}}
                    rightActionsOptions = {(e,ind)=>{return <>
                            <i className='far fa-edit tableActionIcon' style={{fontSize:"26px"}}  onClick={()=> onClickPick(e,ind)}/>
                            
                    </>}}
                />
            </div>
        </div> 
    </>)
}