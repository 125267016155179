import { useDispatch, useSelector } from 'react-redux';
import axios from '../api/axios';
import { setUserInfo } from '../Redux/Actions/userActions';


const useRefreshToken = () => {
    //const { setAuth } = useAuth();
    let userInfo = useSelector(state => state.getUserInfo);
    const dispatch = useDispatch();
    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        dispatch(setUserInfo({
            ...userInfo,
            auth : response.data.token
        }));
        // setAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(response.data.accessToken);
            // return { ...prev, accessToken: response.data.accessToken }
        // });

        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;