import React, { useState } from "react";
import { TopBar } from "../layout/TopBar/TopBar";
import { SideBar } from "../layout/SideBar/SideBar";
import { MainRoutes } from "./MainRoutes";

export function MainComponent(props){
    return(<>
        <TopBar/>
        <SideBar/>
        <MainRoutes/>
    </>);
}