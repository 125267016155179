import { TextField } from "@mui/material";
import React, { useEffect } from "react"; 
import { ThemeProvider } from "@mui/material";
import './style.css';   
import theme from '../../../utils/theme'

//import TextField from '@material-ui/core/TextField';
export function DateInput(props){
  

   

    useEffect(()=>{
      
        return()=>{};
    },[theme,props.defaultValue,props.value])
   
    return (<>
    <ThemeProvider theme={theme}>
        <TextField
          autoComplete        = {props.autoComplete}
          autoFocus           = {props.autoFocus}
          classes             = {props.classes}
          color               = {props.color}
          // defaultValue        = {props.defaultValue}
          disabled            = {props.disabled}
          FormHelperTextProps = {props.FormHelperTextProps}
          id                  = {props.id}
          //InputLabelProps     = {props.InputLabelProps}
          inputProps          = {props.InputProps}
          inputRef            = {props.inputRef}
          margin              = {props.margin}
          maxRows             = {props.maxRows}
          minRows             = {props.minRows}
          multiline           = {props.multiline}
          name                = {props.name}
          onChange            = {props.onChange?(e)=>props.onChange(e):()=>{}}
          placeholder         = {props.placeholder?props.label:''}
          rows                = {props.rows}
          select              = {props.select}
          SelectProps         = {props.SelectProps}
          size                = {props.size}
          type                = {props.type || 'date'}
          // value               = {props.value}
          label={props.label}
          required = {props.required}
          variant="standard"
          fullWidth = {props.fullWidth}
          helperText = {props.helperText ? props.error ? props.helperText :'' :''}
          error = {props.error}
          InputProps={{
            value : props.value ? props.value  : '' ,
            defaultValue : props.defaultValue ? props.defaultValue : '', 
            id :props.id,
            sx :{
              background:props.backgroundColor,     /// input background color 
              zIndex:'1',
              borderRadius:'4px 4px 0px 0px',
              ...props.inputSx
            },
            ...props.InputProps
          }}
          sx={{
            
            input :{
              ...props.sx?.input,
              color :props.inputFontColor,              /// input font color 
              paddingLeft :'5px'
            },
            '& label': {
              color: props.labelColorNF,             /// label color 
              zIndex:'2',
              marginLeft:'5px',
              ...(props.sx?props.sx['& label']:{}),
            },
            '& label.Mui-focused': {
              color: props.labelColorAF,             /// label color after focused
              ...(props.sx?props.sx['& label.Mui-focused']:{}),
            },
            '& .MuiInput-underline:after': {     /// border color when focused 
              borderBottomColor: props.labelColorAF,
              ...(props.sx?props.sx['& .MuiInput-underline:after']:{}),
            },
            '& .MuiInput-underline:before': {    /// border color when not focused 
              borderBottomColor: props.labelColorNF,
              ...(props.sx?props.sx['& .MuiInput-underline:before']:{}),
            },
            
          }}
          InputLabelProps={{
            shrink: true,
            ...props.InputLabelProps
          }}
        />    
    </ThemeProvider>
     
    </>);
}