import React, { useEffect , useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { failed, loading, removeLoading } from '../../commons/alerts';
import { useHistory, useParams } from 'react-router-dom';
import CardView from '../../commons/CardView';


export function TextAreaInput(props){
    useEffect(()=>{
        if(props.id){
            if(props.value){
                document.getElementById(props.id).value = props.value;
            }
        }
        return ()=>{};
    },[props.value])
    
    return (<>
    <div className={props.col?'col-'+props.col:'col-6'}>
        <div class="input-group">
            <span 
                class="input-group-text" 
                id="basic-addon3" 
                style={{width:props.lwidth?props.lwidth:'25%',fontWeight:'500'}}
            >   
                <div style={{width:'100%',height:'100%',overflow:'hidden'}}>
                    <div className='row'>
                        <div className='col-12'>
                        <label>{props.label}</label>
                    
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                        <label style={{fontSize:'10px'}}>{props.info}</label>
                        </div>
                    </div>
                </div>
                
            </span>
            <textarea 
                type="text" 
                class="form-control form-control-sm" 
                id={props.id} 
                aria-describedby="basic-addon3"
                defaultValue={props.defaultValue}
                disabled = {props.disabled}
                
            />
        </div>
    </div>
    </>)
}