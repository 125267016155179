import React from "react";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import PatentAdd from "../Component/Patent/PatentComponents/PatentAdd";
import { Patent } from "../Component/Patent/Patent";
import { PatentList } from "../Component/Patent/PatentComponents/PatentList";
export const PatentRouter = () => {
  return (
    <div style={{width:'100%',height:'100%',overflow:'auto'}}>
        <Route exact path = '/app/patent'>
            <Patent/>
        </Route>
        <Route exact path='/app/patent/list'>
            <PatentList/>
        </Route>
        <Route exact path='/app/patent/add'>
            <PatentAdd/>
        </Route>
        <Route exact path='/app/patent/edit/:id'>
            <PatentAdd/>
        </Route>
    </div>
  );
};
