import React from 'react';
import './MainRoutes.css';
import { Route ,Redirect } from 'react-router-dom';
import { DashBoardRouter } from './DashBoardRouter';
import { UsersRouter } from './UsersRouter';
import { RequestRouter } from './RequestRoutes';
import { PatentRouter } from './PatentRouter';
import { ServicesRouter } from './ServicesRouter';
import { EventsRouter } from './EventsRouter';


export function MainRoutes(){
    return (
        <div id='mainRoutes' className='mainRoutes'>
            <Route exact  path = '/app'>
                <Redirect to='/app/dashboard'/>
            </Route>
            <Route  path = '/app/dashboard'>
                <DashBoardRouter/>
            </Route>
            <Route  path = '/app/users'>
                <UsersRouter/>
            </Route>
            <Route path = '/app/requests'>
                <RequestRouter/>
            </Route>
            <Route path = '/app/patent'>
                <PatentRouter/>
            </Route>
            <Route path = '/app/services'>
                <ServicesRouter/>
            </Route>
            <Route path = '/app/events'>
                <EventsRouter/>
            </Route>
        </div>
    )
}