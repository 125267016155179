import React from "react";
import { CountryPickerInput } from "../../commons/InputGroupMui/CountryPickerInput";
import { TextInput } from "../../commons/InputGroupMui/TextInput";
import { TextAreaInput } from "../../commons/InputGroupMui/TextAreaInput";

export default function PatentAddress(props) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <CountryPickerInput
            onChange={(e) =>
              props.setForm({ ...props.form, country: e })
            }
            defaultValue={props.form.country}
            id="PatintInfoFormCountery"
            label="Countery"
            fullWidth
          />
        </div>
        <div className="col-12">
          <TextInput
            onChange={(e) =>
              props.setForm({ ...props.form, city: e.target.value })
            }
            value={props.form.city}
            id="PatintInfoFormCity"
            label="Şehir"
            fullWidth
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextAreaInput
            onChange={(e) =>
              props.setForm({ ...props.form, full_address: e.target.value })
            }
            value={props.form.full_address}
            id="PatintInfoFormFullAdreess"
            label="Açık Adres"
            multiline
            minRows={2}
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
