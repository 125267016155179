import React from "react";
import "./OzelDonatim.css";
import medicalimg from "../../../../../assets/servicesImg/27.jpg";
import medicalLogo from "../../../../../assets/donatim-dis-logo-profil (2).png";
import i18next, { t } from "i18next";
export default function OzelDonatim() {
  return (
    <div className="OzelDonatim">
      <div className="content">
        <div className="header">
          <div className={i18next.language=='AR'?"header-text-AR":"header-text"}>
            {t("ClinicsPage.Clinics.1.clinic_name")}
          </div>
        </div>
        <div className="body">
          <img src={medicalLogo} alt="Medical Logo" className="logo-image" />
          <img
            src={medicalimg}
            alt="Medical Park"
            className="background-image"
          />
          <div className="body-inner">
            <div className="body-inner-text" >
              <div dangerouslySetInnerHTML={{__html:t("ClinicsPage.Clinics.1.text")}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
