import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Requests } from '../Component/Requests/Requests';
import { NewRequests } from '../Component/Requests/New/NewRequests';
import { FirsetProcess } from '../Component/Requests/forms/FirsetProcess';
import { InProcessRequests } from '../Component/Requests/InProcess/InProcessRequests';



export function RequestRouter(){

    useEffect(()=>{
        return ()=>{};
    },[])

    return (<>
    <div style={{width:'100%',height:'100%',overflow:'auto'}}>
        <Route exact path = '/app/requests'>
            <Requests/>
        </Route>
        <Route exact path = '/app/requests/new'>
            <NewRequests/>
        </Route>
        <Route exact path = '/app/requests/new/:id'>
            <FirsetProcess/>
        </Route>
        <Route exact path = '/app/requests/inprocess'>
            <InProcessRequests/>
        </Route>
        <Route exact path = '/app/requests/inprocess/:id'>
            <FirsetProcess inProcess/>
        </Route>
    </div>
                  
    </>)
}