import React, { useEffect , useState } from 'react';

import './style.css';

export function ServiceTable(props){
    const onClickDelete=(ind)=>{
        if(props.onClickDelete){
            props.onClickDelete(ind);
        }
    }
    const onClickEdit=(ind)=>{
        if(props.onClickEdit){
            props.onClickEdit(ind);
        }
    }
    return (<>
        <div className='row'>
            <div className='col-12'>
                <table className='serviceListTable'>
                    <thead>
                        <tr>
                            <th width='15%'>Servis</th>
                            <th width='15%'>Alt Servis</th>
                            <th width='15%'>Klinik</th>
                            <th width='15%'>personel</th>
                            <th width='5%'>Adet</th>
                            <th width='16%'>Birim Fiyatı</th>
                            <th width='12%'>İndirim</th>
                            <th width='16%'>Toplam</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.list?.map((d,ind)=>{
                            return <tr style={{background:props.selectedRow==ind ?'var(--sub-color)':''}}>
                                <td>{d.service?.label}</td>
                                <td>{d.subService?.label}</td>
                                <td>{d.clinic?.label}</td>
                                <td>{d.personel?.label}</td>
                                <td className='isNumeric'>{d.quantity}</td>
                                <td className='isNumeric'>{d.ppu}{d.currencySimbol||''}</td>
                                <td className='isNumeric'>%{d.discount}</td>
                                <td className='isNumeric'>{d.quantity*d.ppu*((100-d.discount)/100)}{d.currencySimbol||''}</td>
                                <td className='isActionBtn'>
                                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'space-evenly',alignItems:'center'}}>
                                        <i class="fa-solid fa-pen-to-square iconBlue" onClick={()=> onClickEdit(ind)}></i>
                                        <i class="fa-solid fa-trash-can iconRed" onClick={()=> onClickDelete(ind)}></i>
                                    </div>
                                </td>
                            </tr>
                        })}
                        {props.list?.length > 0 ?<>
                            {props.list.find(d => d.currency == 1) &&
                                <tr>
                                    <td 
                                        colSpan={7}
                                        className='isNumeric'
                                    >
                                        {'Toplam TL'}
                                    </td>
                                    <td className='isNumeric'>
                                        {props.list.filter(d=> d.currency == 1).reduce((a,u)=> a+ (u.quantity*u.ppu*((100-u.discount)/100)),0)}{'TL'}
                                    </td>
                                </tr>
                            }
                            {props.list.find(d => d.currency == 2) &&
                                <tr>
                                    <td 
                                        colSpan={7}
                                        className='isNumeric'
                                    >
                                        {'Toplam DOLLAR'}
                                    </td>
                                    <td className='isNumeric'>
                                        {props.list.filter(d=> d.currency == 2).reduce((a,u)=> a+ (u.quantity*u.ppu*((100-u.discount)/100)),0)}{'$'}
                                    </td>
                                </tr>
                            }
                        </>:<>
                            
                        </>}
                    </tbody>
                </table>
            </div>
        </div>
    </>);
}