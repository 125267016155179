import React, { useEffect, useState } from "react";
import Tabel from "./TableComponent/TableComponent";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { 
    DeleteWarn, 
    removeLoading, 
    success, 
    warning , 
    loading as Loading
} from "./alerts";
import { _serverURL } from "../../config/config";
import SlideInComponent from "./SlideInComponent";
import PopupComponent from "./PopupComponent";

export function PatentDocument(props) {
    const axiosPrivate = useAxiosPrivate();
    const [isSlideInOpen,setIsSlideInOpen] = useState(false);
    const [selectedDoc,setSelectedDoc] = useState();

    const headers = [
        {id : 'name',numeric:false,disablepadding:false,label:'Belge Adı',isSortable:true},
        {id : 'uploaded_at',numeric:false,disablepadding:false,label:'Yükleme Tarihi',isSortable:true,isDate:true,pattern:'yyyy-MM-dd'},   
    ];
    const [loading , setLoading] = useState(false);
    const [data    , setData   ] = useState([]);

    const onClickShowDocument=(e,ind)=>{
        if(props.id){
            axiosPrivate.get('/document/'+e.id,{responseType:'blob'})
            .then((res)=>{
                console.log(res.data);
                //window.open(URL.createObjectURL(res.data),'_blank')
                setIsSlideInOpen(true);
                setSelectedDoc(URL.createObjectURL(res.data))
            }).catch((err)=>{
                console.log(err);
                warning('asd');
            }).finally(()=>{removeLoading()})
        }else if(props.patientDocuments){
            //window.open(URL.createObjectURL(props.patientDocuments[ind]),'_blank')
            setIsSlideInOpen(true);
                setSelectedDoc(URL.createObjectURL(props.patientDocuments[ind]))
        }
        
    }
    const onClickDeleteDocument=(e,ind)=>{
        DeleteWarn(
            'Belgeyi Sil',
            'Emin misiniz?',
            ()=>{
                if(props.id){
                    Loading();
                    axiosPrivate.delete('/document/'+e.id)
                    .then((res)=>{
                        success(res.data.msg);
                        console.log(res.data.msg);
                        data.splice(ind,1);
                        setData([...data]);
                        //setSideBarItem(res.data.rows);
                    }).catch((err)=>{
                        console.log(err);
                        warning('asd');
                    }).finally(()=>{removeLoading()})
                }else if(props.setPatientDocuments){
                    Loading();
                    props.patientDocuments.splice(ind,1);
                    props.setPatientDocuments([...props.patientDocuments]);
                    removeLoading();
                }
            }
        )
    }

    const handleFileChange = async(e)=>{
        if(e.length == 0){return};
        if(props.id){
            let formdata = new FormData();
            for(let i = 0 ; i < e.length ; i++){
                formdata.append('files',e[i]);
            }
            formdata.append('patient_id',props.id);
            await axiosPrivate.post('/document/to/patient',formdata,{headers: {'Content-Type': 'multipart/form-data'}})
            .then((res)=>{
                res.data.notifications.forEach(n => {
                    if(n.status){
                        success(n.msg);
                    }else{
                        warning(n.msg);
                    }
                });
                getPatientDocuments();   
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{
                document.getElementById('request_process_document').value  = null;
            })
        }else if(props.setPatientDocuments){
            for(let i = 0 ; i < e.length ; i++){
                props.patientDocuments.push(e[i]);
            }
            setData([...props.patientDocuments]);
            props.setPatientDocuments([...props.patientDocuments]);
            document.getElementById('request_process_document').value  = null;
        }
    }
    const getPatientDocuments=()=>{
        if(props.id){
            setLoading(true);
            axiosPrivate.get('/document/get/by/patient/'+props.id)
            .then((res)=>{
                console.log(res.data);
                setData(res.data.rows);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{setLoading(false)})
        }else if(props.patientDocuments){
            setData(props.patientDocuments);
        }
    };

    useEffect(()=>{
        getPatientDocuments();
        return()=>{};
    },[props.id])

    return (<>
        <div className="row">
            <div className="col-12">
                <Tabel
                    headers = {headers}
                    data    = {props.id ?data:props.patientDocuments?props.patientDocuments:[]}
                    isRightActions
                    isToptActions
                    rightActionWidth= '100px'
                    rightActionsOptions = {(e,ind)=>{return <>
                        <i class="fa-regular fa-eye  iconBlue" style={{fontSize:"26px"}} onClick={()=> onClickShowDocument(e,ind)}></i>
                        <i class="fa-solid fa-trash-can iconRed" onClick={()=> onClickDeleteDocument(e,ind)}/>
                    </>}}
                    isLoading = {loading}
                    topActionsOptions = {(e,ind)=>{return <>
                        <input
                            id = 'request_process_document'
                            type="file"
                            accept=".pdf,.doc,.docx,.jpg,.jpeg"
                            multiple
                            onChange={(e)=> handleFileChange(e.target.files)}
                            className='btn btn-secondary btn-sm form-control'
                            style={{width:'250px',height:'85%', display:'flex',justifyContent:'space-between',alignItems:'center'}}
                        >        
                        </input>
                    </>}}
                />
            </div>
        </div>
        <PopupComponent 
            title = ''
            isOpen = {isSlideInOpen} 
            onClickClose={(e)=> {setIsSlideInOpen(false);setSelectedDoc()}}
            width = '95%'
            height = '95%'
        >
            <iframe 
                style={{width:'100%',height:'100%'}}
                src = {selectedDoc}
            />
        </PopupComponent>
    </>)
}
