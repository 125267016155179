import React from 'react'
import './posts.css'
import BlogPost from '../BlogPost/BlogPost'


function BlogPosts() {
  return (
    <>
        <div className="blogPosts">
            <BlogPost/>
            <BlogPost/>
            <BlogPost/>
            <BlogPost/>
            <BlogPost/>
            <BlogPost/>
        </div>
    </>
  )
}

export default BlogPosts