import React, { useEffect } from 'react';
import './style.css';
export default function SlideInComponent(props){
    
    const onClickClose = () => {
        document.getElementById('slideInMainCmp').classList.remove('openSlidIn');
        document.getElementById('slideInContainer').classList.remove('openSlidIn');
        if(props.onClose){props.onClose()}
    }
    useEffect(()=>{
        if(props.isOpen){
            document.getElementById('slideInMainCmp').classList.add('openSlidIn');
            document.getElementById('slideInContainer').classList.add('openSlidIn');
        }else{
            document.getElementById('slideInMainCmp').classList.remove('openSlidIn');
            document.getElementById('slideInContainer').classList.remove('openSlidIn');
        }

        return()=>{
            // document.getElementById('slideInMainCmp').classList.remove('open');
            // document.getElementById('slideInContainer').classList.remove('open'); 
        }
    },[props.isOpen])
    return (<>
        <div id='slideInMainCmp' className='slideInMainCmp' onClick={()=> onClickClose()}>
            <div id ='slideInContainer' className='slideInContainer' style={{width:props.width?props.width:''}} onClick={(e)=> e.stopPropagation()}>
                <div className='slideInTitle'>
                    <div className='slideInTitleTitle'>
                        <label>{props.title}</label>
                    </div>
                    <div className='slideInTitleBtn'>
                        <i class="fa-solid fa-xmark icon" onClick={()=> onClickClose()}></i>
                    </div>
                </div>
                <div className='slideInBody'>
                    {props.isOpen && props.children}
                </div>
            </div>
        </div>
        
    </>)
};