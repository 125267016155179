import * as actionTypes from "../Actions/actionTypes";
import { initialState } from "../initialState";

export const getUserInfo = (state = initialState.user,action)=>{
    switch(action.type){
        case actionTypes.USER_INFO:
            return action.payload;
        default : 
            return state;
    }
}