import React, { useState } from "react";
import facebook  from "../../../../assets/icons8_Facebook_32.png";
import tiktok    from "../../../../assets/icons8_tiktok_32.png";
import google    from "../../../../assets/icons8_google_old_32.png";
import instagram from "../../../../assets/icons8_Instagram_32.png";
import linkedin  from "../../../../assets/icons8_linkedin_32.png";
import           './SocialMediaNavBar.css'
export function SocialMediaNavBar(props){
    const onClickSocial=(link)=>{
        window.open(link);
    }
    return(<>
        <div className="socialNavBarContainer">
            <img
                className="socialNavBarIcon"
                src={facebook}
                onClick={() =>
                  onClickSocial("https://www.facebook.com/shereencaregroup")
                }
            />
            <img
                className="socialNavBarIcon"
                src={tiktok}
                onClick={() =>
                  onClickSocial("https://www.tiktok.com/@shereencare")
                }
            />
            <img
                className="socialNavBarIcon"
                src={google}
                onClick={() =>
                  onClickSocial("https://www.google.com/search?q=shereen+care")
                }
            />
            <img
                className="socialNavBarIcon"
                src={instagram}
                onClick={() =>
                  onClickSocial("https://www.instagram.com/shereencaredental/")
                }
            />
            <img
                className="socialNavBarIcon"
                src={instagram}
                onClick={() =>
                  onClickSocial("https://instagram.com/shereencare_italy?igshid=OGQ5ZDc2ODk2ZA==")
                }
            />
            <img
                className="socialNavBarIcon"
                src={instagram}
                onClick={() =>
                  onClickSocial("https://www.instagram.com/shereencaregroup/")
                }
            />
            <img
                className="socialNavBarIcon"
                src={linkedin}
                onClick={() =>
                  onClickSocial("https://www.linkedin.com/company/shereen-care-group/")
                }
            />
        </div>
    </>);
}