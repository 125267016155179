import React from "react";
import "./WhoWeServed2.css";
import doctor from "../../../../../assets/doctor.jpg";
import i18next, { t } from "i18next";
export default function WhoWeServed2() {
  return (
    <div className="whoWeServed2">
      <div className="content">
        <div className="header">
          <div className={i18next.language=='AR'?"header-text-AR":"header-text"}> {t("WHO_WE_SERVED.content.header")}</div>
        </div>
        <div className="body">
          <div className="body-inner">
            <img src={doctor} />
            <div className="body-innerone">
              <div className="body-innerCircleBody">
                <div className="circle-one">
                  <div className="circle-one-body"  dangerouslySetInnerHTML={{__html:t('WHO_WE_SERVED.content.body.text1')}}>
                  </div>
                </div>
                <div className="circle-two">
                  <div className="circle-two-body" dangerouslySetInnerHTML={{__html:t('WHO_WE_SERVED.content.body.text2')}}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
