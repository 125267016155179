import { toast } from 'react-toastify';
export const success = (msg)=>{
    toast.success(msg);
};

export const failed = (msg)=>{
    toast.error(msg || 'Bir hata oluştu');
};

export const warning = (msg)=>{
    toast.warn(msg);
};

export const info = (msg)=>{
    toast.info(msg);
};
