import React, { useEffect , useState } from 'react';
import { TextInput } from '../../commons/InputGroupMui/TextInput';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { DateInput } from '../../commons/InputGroup/DateInput';

export function ReferenceInfo(props){
    const axiosPrivate   = useAxiosPrivate();
    const [data,setData] = useState();

    const getReferenceData=()=>{
        if(props.id){
            axiosPrivate.get('/patient/reference/'+props.id)
            .then((res)=>{
                setData(res.data.reference);
            }).catch((err)=>{

            }).finally(()=>{});
        }else if (props.data){
            setData(props.data);
        }
        
    };
    
    useEffect(()=>{
        console.log(props);
        getReferenceData();
        return()=>{};
    },[props.id,props.data]);
    return (<>
        <div className='row'>
            <div className='col-6'>
                <TextInput label='Ad' id ='first_name' defaultValue={data?.first_name} disabled fullWidth/> 
            </div>
            <div className='col-6'>
                <TextInput label='Telefon Numarası' id ='tel_no'  defaultValue={data?.tel_no} disabled fullWidth/> 
            </div>
        </div>
        <div className='row' style={{height:'15px'}}></div>
        <div className='row' >
            <div className='col-6'>
                <TextInput label='Soyad' id ='last_name'  defaultValue={data?.last_name} disabled fullWidth/>
            </div>
            <div className='col-6'>
                <TextInput label='Email' id ='email'  defaultValue={data?.email} disabled fullWidth/>
            </div>
        </div>
        <div className='row' style={{height:'15px'}}></div>
        <div className='row'>
                <div className='col-12'>
                    <TextInput label='Başlangıç Tarihi' id ='date'  defaultValue={data?.reference_start_time} disabled fullWidth/>
                </div>
        </div>
    </>)
}