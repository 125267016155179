import React, { useEffect, useState } from "react";
import logo   from '../../../../assets/logo.svg';
import { VideoComponent } from "../../components/videoComponent/VideoComponent";
import { _serverURLvideo } from '../../../../config/config';
import './HomePage.css';
import foto1 from '../../../../assets/1.jpeg';
import foto2 from '../../../../assets/2.jpeg';
import foto3 from '../../../../assets/3.jpeg';
import { t } from "i18next";
let index = 0 ;
export function HomePage(props){
    const videos = [
        {video :foto1 , duration : 5 , header : t('BEAUTY_SERVICES.1') , headerColor : '#000' , btnColor :'darkColor'},
        {video :foto1 , duration : 5 , header : t('BEAUTY_SERVICES.1'), headerColor : '#000' , btnColor :'darkColor'},
        {video :foto1 , duration : 5 , header : t('BEAUTY_SERVICES.1') , headerColor : '#000' , btnColor :'darkColor'},
    ];
    const [width, setWidth] = useState(window.innerWidth);
    const [selectedVideo,setSelectedVideo] = useState(videos[0]);
    const handleWindowSizeChange = () => {
        console.log(window.innerWidth);
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        // const intervalId = setInterval(() => {
        //   index = (index + 1) % videos.length;
        //   console.log(videos[index])
        //   setSelectedVideo(videos[index]);
        // }, 5000);
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
          //clearInterval(intervalId); // Clear the interval when the component unmounts
        };
    }, []);

    useEffect(()=>{
        return()=>{};
    },[selectedVideo]);


    return(<>
        <div id ='HomePageBody' className="HomePageBody">
            <div className="homeLogoContainer">
                <img src={logo} className="svglogo" alt="SHEREEN CARE"></img>
            </div>
            <div className="VideoContainer">
                <VideoComponent 
                    videos={videos}  
                    isMobil = {width < 768}
                />   
            </div>
            
            {/* <div className="labelContainer">

            </div> */}
        </div>
    </>);
}