import { CssBaseline, FormControl, FormHelperText, Input, InputLabel, NativeSelect, makeStyles } from "@mui/material";
import React, { useEffect } from "react"; 
import './style.css';   
import theme from "../../../utils/theme";
import { ThemeProvider } from "@emotion/react";

export function SelectInput(props){
    useEffect(()=>{

    },[props.options])
    return (<>
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <FormControl
            fullWidth
            sx={{
            
                
                '& label': {
                  color: props.labelColorNF,             /// label color 
                  zIndex:'2',
                  marginLeft:'5px',
                  ...(props.sx?props.sx['& label']:{}),
                },
                '& label.Mui-focused': {
                  color: props.labelColorAF,             /// label color after focused
                  ...(props.sx?props.sx['& label.Mui-focused']:{}),
                },
                '& .MuiInput-underline:after': {     /// border color when focused 
                  borderBottomColor: props.labelColorAF,
                  ...(props.sx?props.sx['& .MuiInput-underline:after']:{}),
                },
                '& .MuiInput-underline:before': {    /// border color when not focused 
                  borderBottomColor: props.labelColorNF,
                  ...(props.sx?props.sx['& .MuiInput-underline:before']:{}),
                },
                
            }}
        >
            <InputLabel 
                variant="standard" 
                htmlFor="uncontrolled-native"
                disabled= {props.disabled}
                sx ={{
                    
                }}
            >
                {props.label}
            </InputLabel>
            <NativeSelect
                disabled= {props.disabled}
                defaultValue={props.defaultValue}
                value={props.value?props.value:''}
                onChange = {props.onChange?(e)=>props.onChange(e):()=>{}}
                inputProps={{
                    name: props.name,
                    id: props.id,
                    sx :{
                        background:props.backgroundColor,     /// input background color 
                        zIndex:'1',
                        borderRadius:'4px 4px 0px 0px',
                        color : props.inputFontColor,
                        paddingLeft :'5px',
                        ...props.inputSx
                      },
                    ...props.InputProps
                }}
                
                required = {props.required}
            >
                <option disabled selected value={''} ></option>
                {props.options?.map((option)=>{
                    return <option style={{color :'black'}}  value={option.value}>{option.label}</option>
                })}
            </NativeSelect>
        </FormControl>
    </ThemeProvider>
    </>);
}