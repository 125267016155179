import React, { useState } from "react";
import "./ContactForm.css";
import { SocialMediaNavBar } from "../../navBar/socialMediaNavBar/SocialMediaNavBar";
import { TextInput } from "../../../../Component/commons/InputGroupMui/TextInput";
import PhoneInput from "../../../../Component/commons/InputGroupMui/PhoneInput";
import { EmailInput } from "../../../../Component/commons/InputGroupMui/EmailInput";
import { SelectInput } from "../../../../Component/commons/InputGroupMui/SelectInput";
import { CountryPickerInput } from "../../../../Component/commons/InputGroupMui/CountryPickerInput";
import { TextAreaInput } from "../../../../Component/commons/InputGroupMui/TextAreaInput";
//import { Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "axios";
import { _serverURL } from "../../../../config/config";
import { success } from "../../../../utils/alerts";
import { loading as Loading , removeLoading } from "../../../../Component/commons/alerts";
import logo from '../../../../assets/logo.svg';
import { t } from "i18next";


export function ContactForm(props) {
  const inputFontColor = '#ffffff';
  const labelColorNF   = '#808080';
  const labelColorAF   = '#00ca88';
  const backgroundColor = '#333333';
  const [errorfn , setErrorfn] = useState(false);
  const [errorln , setErrorln] = useState(false);
  const [errortn , setErrortn] = useState(false);
  const [errore  , setErrore ] = useState(false);
  const [loading , setLoading] = useState(false);
  const [disabled , setDisabled] = useState(false);
  const [form , setForm] = useState({});

  const onSubmit=(e)=>{
    
    // let first_name = document.getElementById('firstNameAddRequestForm').value
    // let last_name  = document.getElementById('lastNameAddRequestForm').value
    // let tel_no     = document.getElementById('PhoneNumberNameAddRequestForm').value
    // let email      = document.getElementById('EmailAddRequestForm').value
    let flag = false;
    //setLoading(true);
    if(!form?.first_name){setErrorfn(true);flag=true;}
    if(!form?.last_name){setErrorln(true);flag=true;}
    if(!form?.tel_no){setErrortn(true);flag=true;}
    if(!form?.email){setErrore(true);flag=true;}
    if(flag){return};
    // let json = {
    //   first_name: first_name,
    //   last_name: last_name,
    //   tel_no: tel_no,
    //   email: email,
    //   service_id: document.getElementById('ServiceAddRequestForm').value,
    //   country : document.getElementById('NationalityAddRequestForm').value,
    //   notes: document.getElementById('NotesAddRequestForm').value,
    // };
    // console.log(json)
    Loading()
    axios.post(_serverURL + "/landingpage/post/request", form)
    .then((res) => {
      console.log(res)
      success('ok thsfsdfsd');
      setErrorfn(false);
      setErrorln(false);
      setErrortn(false);
      setErrore(false);
      setDisabled(true);
      
      //notify();
      //setSent(true);
    })
    .catch((err) => {
      console.log('ssssss')
      console.log(err);
    }).finally(()=>{removeLoading()});
    
  }
  let servicesJSON=[
    {label:'Hair Transplant',value:1},
    {label:'Dental',value:2},
    {label:'Weight loss',value:3},
    {label:'Aesthetic surgeries',value:4},
    {label:'Organs Transplant',value:5},
    {label:'Cancer Treatment',value:6},
    {label:'Cardiovascular surgery',value:7},
    {label:'Orthopedic surgery',value:8},
    {label:'Other',value:9},

]
  


  return (
    <>
      <div className="homeForm">
        <div className="homeLeft">
          <div className="row" style={{height:'50px',width:'100%'}}>
            <div className="col-12" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{color :labelColorAF,fontSize:'large'}}>
                {t('ContactPage.header.0')}
              </label>
            </div>
          </div>
          <div className="row" style={{height:'25px',width:'100%'}}>
            <div className="col-12" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{color :'rgba(255,255,255,.5)',fontSize:'small'}}>
              {t('ContactPage.header.1')}
              </label>
            </div>
          </div>
          <div className="row" style={{padding:'0px',height:'calc(100% - 115px)',width:'100%',overflowY:'auto'}}>
            <div className="col-12" style={{margin:'0px',padding:'0px'}}>
              <form autoComplete='off' onSubmit={(e)=> onSubmit(e)} style={{height:'100%',margin:'0px'}}>
                <div className='row' style={{width:'100%' , height :'100%' ,overflowY:'auto'}}>
                  <div className='col-12'>
                    <div className='row' style={{ margin:'0px'}}>
                      <div className='col-6' style={{marginTop:'0px'}}>
                        <TextInput
                            id="firstNameAddRequestForm"
                            fullWidth
                            label={t("ContactPage.form.0")}
                            placeholder 
                            //defaultValue=""
                            required
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            error           = {errorfn}
                            helperText      = {t("ContactPage.form.1")}
                            disabled        = {disabled}
                            value={form?.first_name}
                            onChange={(e)=> setForm({...form , first_name : e.target.value})}
                        />
                      </div>
                      <div className='col-6' style={{marginTop:'0px'}}>
                        <TextInput
                            id="lastNameAddRequestForm"
                            fullWidth
                            placeholder
                            label={t("ContactPage.form.2")}
                            //defaultValue=""
                            required
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            error           = {errorln}
                            helperText      = {t("ContactPage.form.3")}
                            disabled        = {disabled}
                            value={form?.last_name}
                            onChange={(e)=> setForm({...form , last_name : e.target.value})}
                        />
                      </div>
                      <div className={window.innerWidth>768 ? 'col-6' :'col-12' } style={{marginTop:'10px'}} >
                        <PhoneInput
                            id = "PhoneNumberNameAddRequestForm"
                            required
                            //defaultValue=""
                            placeholder
                            label={t("ContactPage.form.4")} 
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            error           = {errortn}
                            helperText      = {t("ContactPage.form.5")}
                            disabled        = {disabled}
                            defaultValue={form?.tel_no}
                            onChange={(e)=> setForm({...form , tel_no : e})}
                        />
                      </div>
                      <div className={window.innerWidth>768 ? 'col-6' :'col-12' } style={{marginTop:'10px'}}>
                        <EmailInput
                            id="EmailAddRequestForm"
                            fullWidth
                            placeholder
                            label={t("ContactPage.form.6")}
                            //defaultValue=""
                            required
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            error           = {errore}
                            helperText      = {t("ContactPage.form.7")}
                            disabled        = {disabled}
                            value={form?.email}
                            onChange={(e)=> setForm({...form , email : e.target.value})}
                        />
                      </div>
                      <div className='col-12' style={{marginTop:'10px'}}>
                        <SelectInput
                            id="ServiceAddRequestForm"
                            options = {servicesJSON}
                            label={t("ContactPage.form.8")}
                            required
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            disabled        = {disabled}
                            value={form?.service_id}
                            onChange={(e)=> setForm({...form , service_id : e.target.value})}
                        />
                      </div>
                      <div className='col-12' style={{marginTop:'10px'}}>
                        <CountryPickerInput
                            id = "NationalityAddRequestForm"
                            label = {t("ContactPage.form.9")}
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            disabled        = {disabled}
                            defaultValue={form?.country}
                            onChange={(e)=> setForm({...form , country : e})}
                        />
                      </div>
                      <div className='col-12' style={{marginTop:'20px'}}>
                        <TextAreaInput
                            placeholder = {'notes...'}
                            id = "NotesAddRequestForm"
                            multiline
                            fullWidth
                            minRows = {3}
                            maxRows = {5}
                            label = {t("ContactPage.form.10")}
                            inputFontColor = {inputFontColor}
                            labelColorNF = {labelColorNF}
                            labelColorAF = {labelColorAF}
                            backgroundColor = {backgroundColor}
                            disabled        = {disabled}
                            value={form?.notes}
                            onChange={(e)=> setForm({...form , notes : e.target.value})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row" style={{height:'35px',width:'100%'}}>
            <div className="col-12" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <LoadingButton 
                variant="contained" 
                size = 'small' 
                color = 'logoColor'
                style={{
                  width:'80%',
                }} 
                endIcon={<SendIcon />}
                //loading={loading}
                disabled = {disabled}
                onClick={()=>onSubmit()}
                //loadingPosition="end"
                sx ={{
                  '.Mui-disabled':{
                    background:'red'
                  }
                }}
              >
                <span>{t("ContactPage.form.11")}</span>
              </LoadingButton>
            </div>
          </div>
        </div>
        <div className="homeRight">
          <div className="row topSection" style={{margin:'0',padding:'0'}}>
            <div className="col-12" style={{width:'100%',height:'100%'}}>
                <SocialMediaNavBar/>
            </div>
          </div>
          <div className="row middleSection" style={{margin:'0',padding:'0'}}>
            <div className="col-12" style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <label className="motto">{t("ContactPage.header.1")}</label>
                <div className="body row" style={{}}>
                  <div className="col-12" style={{margin:'0',padding:'0'}}>
                    <div style={{width:'100%',height:'100%', display:'flex',justifyContent:'space-evenly',flexDirection:'column'}}>
                      <div className="row">
                        <div className="col-12">
                          <label style={{color :labelColorAF , fontSize:'large'}}>{t("ContactPage.rightForm.0.content.0.head")}</label>
                        </div>
                        <div className="col-12" style={{paddingLeft:'20px',marginTop:'20px'}}>
                          <label style={{color : labelColorNF , fontSize:'small'}}>
                          {t("ContactPage.rightForm.0.content.0.text")}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label style={{color :labelColorAF , fontSize:'large'}} >{t("ContactPage.rightForm.0.content.1.head")}</label>
                        </div>
                        <div className="col-12" style={{paddingLeft:'20px',marginTop:'20px'}}>
                          <label style={{color : labelColorNF , fontSize:'small'}}>{t("ContactPage.rightForm.0.content.1.text.0")}</label><br/>
                          <label style={{color : labelColorNF , fontSize:'small'}}>{t("ContactPage.rightForm.0.content.1.text.1")}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label style={{color :labelColorAF , fontSize:'large'}}>{t("ContactPage.rightForm.0.content.2.head")}</label>
                        </div>
                        <div className="col-12" style={{paddingLeft:'20px',marginTop:'20px'}}>
                          <label style={{color : labelColorNF , fontSize:'small'}}>{t("ContactPage.rightForm.0.content.2.text.0")}</label><br/>
                          <label style={{color : labelColorNF , fontSize:'small'}}>{t("ContactPage.rightForm.0.content.2.text.1")}</label><br/>
                          <label style={{color : labelColorNF , fontSize:'small'}}>{t("ContactPage.rightForm.0.content.2.text.2")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="row bottomSection" style={{margin:'0',padding:'0'}}>
            <div className="col-12" style={{width:'100%',height:'100%',overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center'}}>
              <img src={logo}style={{height:'150%',width:'100%'}} alt="SHEREEN CARE"></img>   
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
