import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { useSelector } from "react-redux";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    let auth = useSelector(state => state.getUserInfo);
    let isRefreshing = false;
    let failedQueue = [];

    const shouldIntercept = (error) => {
        try {
            return error.response.status === 403;
        } catch (e) {
            return false;
        }
    };

    const attachTokenToRequest = (request, token) => {
        request.headers['Authorization'] = 'Bearer ' + token;
        if (!request.headers['Content-Type'] || request.headers['Content-Type'].startsWith('multipart/form-data')){
            request.headers['Content-Type'] = `multipart/form-data`;
        }
    };

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        failedQueue = [];
    };
    
    useEffect(() => { //console.log('here is the problem')
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.auth}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => {return response},
            async (error) => {
                if (!shouldIntercept(error)) {
                    return Promise.reject(error);
                }
                if (error.config._retry || error.config._queued) {
                    return Promise.reject(error);
                }
                const originalRequest = error.config;
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({resolve, reject})
                    }).then(token => {
                        originalRequest._queued = true;
                        attachTokenToRequest(originalRequest, token);
                        return axiosPrivate(originalRequest);
                    }).catch(err => {
                        return Promise.reject(error); 
                    })
                }
                originalRequest._retry = true;
                isRefreshing = true;
                return new Promise(async(resolve, reject) => {
                    const newAccessToken = await refresh();
                    attachTokenToRequest(originalRequest, newAccessToken);
                    processQueue(null, newAccessToken);
                    resolve(axiosPrivate(originalRequest));
                    isRefreshing = false;
                });
            }
        );
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [refresh,auth])

    return axiosPrivate;
}
export default useAxiosPrivate;