import React, { useState } from "react";
import "./Login.css";
import axios from 'axios';
import {useDispatch} from 'react-redux';
import { setUserInfo } from "../Redux/Actions/userActions";
import { _serverURL } from "../config/config";
import { setCookie } from "../utils/cookieUtils";

export function Login() {
    const dispatch = useDispatch();
    const onLogin=(e)=>{
        e.preventDefault();
        const {
            username,
            password,
        } = e.target;
        console.log(username.value,password.value)
        axios.post(_serverURL+'/login',{username:username.value,password:password.value},{headers: { 'Content-Type': 'application/json' },withCredentials: true})
        .then((res)=>{
            console.log(res.data);
            dispatch(setUserInfo({
                first_name : res.data.user.first_name,
                last_name  : res.data.user.last_name,
                username   : res.data.user.username,
                auth       : res.data.token ,
                id         : res.data.user.id,
                rols       : res.data.user.rols,
            }));
            //setCookie('token',res.data.token)
        }).catch((err)=>{
            console.log(err,'hata');
        }).finally(()=>{});
    }
    return (<>
    <div className="login-body">
        <div className="wrapper">
            <form autoComplete="off" onSubmit={(e)=> onLogin(e)}>
                <h1>LOGIN</h1>
                <div className="input-box">
                    <input id = 'username' type="text" placeholder="User Name" required/>
                    <i class='bx bxs-user'></i>
                </div>
                <div className="input-box">
                    <input id = 'password' type="password" placeholder="password" required/>
                    <i class='bx bxs-lock-alt' ></i>
                </div>
                <button type="submit" className="btn">LOGIN</button>
            </form>
        </div>
    </div>
    </>);
}
