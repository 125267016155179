import React, { useState } from "react";
import "./ContactUsPage.css";
import { ContactForm } from "../../components/contactForm/ContactForm";
export function ContactUsPage(props) {
  return (
    <>
      <div id="ContactUsPageBody" className="ContactUsPageBody">
        <div className="ContactUsContainer">
          <ContactForm />
        </div>
      </div>
    </>
  );
}
