import React, { useEffect, useState } from "react";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { TextInput } from "../../commons/InputGroupMui/TextInput";
import CardView3 from "../../commons/CardView3";
import { TextAreaInput } from "../../commons/InputGroupMui/TextAreaInput";
import { SelectInput } from "../../commons/InputGroupMui/SelectInput";
import { EmailInput } from "../../commons/InputGroupMui/EmailInput";
import { genderList } from "../../../constants";
import AsyncSelect from 'react-select/async';
import { warning } from "../../commons/alerts";
import { CountryPickerInput } from "../../commons/InputGroupMui/CountryPickerInput";
import PhoneInput from "../../commons/InputGroupMui/PhoneInput";

export function PatintInfoForm(props){
    const axiosPrivate = useAxiosPrivate();
    const [patient , setPatient] = useState();
    const [patientSelected , setPAtientSelected] = useState(false);
    const onClickClose=()=>{
        if(props.onClose){
            props.onClose()
        }
    };

    const onClickSave=(e)=>{
        e.preventDefault();
        if(props.onSavePatent){
            props.onSavePatent(patient);
        }
    }

    const getData=async(id)=>{
        await axiosPrivate.get('/patient/id/'+id)
        .then((res)=>{
            console.log(res.data);
            setPatient(res.data.patient)
            // setRequestInfo(
            //     res.data.patient.first_name,
            //     res.data.patient.last_name,
            //     res.data.patient.tel_no,
            //     res.data.patient.email,
            // );
        }).catch((err)=>{
            console.log(err);
            warning('Error Occured while retriving patient info');
        }).finally(()=>{})
    }

    const setRequestInfo=(fn,ln,tel,email)=>{
        document.getElementById('PatintInfoFormFirstName').value = fn;
        document.getElementById('PatintInfoFormLastName').value = ln;
        //document.getElementById('PatintInfoFormTelNo').value = tel;
        document.getElementById('PatintInfoFormEmail').value = email;
    };

    const onSelectPatient=(e)=>{
        if(e){
            getData(e.value);
            setPAtientSelected(true);
        }else{
            setPAtientSelected(false);
            setRequestInfo(
                props.data?.first_name,
                props.data?.last_name,
                props.data?.tel_no,
                props.data?.email,
            );
        }
    }

    const promiseOptions = async (inputValue) =>{
        if(inputValue.length >= 3){
            let suggestions = []
            await axiosPrivate.post('/patient/suggestions',{search:inputValue})
            .then((res)=>{
                suggestions = res.data.rows.map((data) => {return {
                    label:data.first_name + ' ' + data.last_name + ' ('+data.tel_no+')', 
                    value:data.id
                }});
            })
            return suggestions;
        }
    }
    useEffect(()=>{
        if(props.id){
            getData(props.id);
        }else if(props.data){
            console.log(props.data)
            setPatient({
                first_name   : props.data.first_name,
                last_name    : props.data.last_name,
                tel_no       : props.data.tel_no,
                country      : props.data.country,
                gender       : props.data.gender ,
                email        : props.data.email ,
                nationality  : props.data.nationality ,
                dob          : props.data.dob ,
                national_id  : props.data.national_id ,
                passport_id  : props.data.passport_id ,
                city         : props.data.city ,
                full_address : props.data.full_address 

            });
        }
        return()=>{}
    },[props.id , patientSelected]);

    return (
    <>
    
    <form style={{width:'100%',height:'100%'}} autoComplete="off" onSubmit={(e)=>onClickSave(e)}>
        <div style={{width:'100%',height:'calc(100% - 50px)',overflowY:'auto',overflowX:'hidden',padding:'2px'}}>
            <div className="row">
                {!props.id &&
                    <div className="col-12">
                        <AsyncSelect 
                            placeholder = 'Hasta Ara... '
                            isClearable
                            defaultOptions
                            onChange={(e)=> onSelectPatient(e)} 
                            loadOptions={promiseOptions}
                        />
                    </div>
                }
            </div>
            <CardView3 title='Hasta Bilgileri'>
                <div className="row">
                    <div className="col-6">
                        <TextInput
                            id="PatintInfoFormFirstName"
                            label="Ad"
                            value={patient?.first_name}
                            onChange={(e)=> setPatient({...patient,first_name:e.target.value})}
                            required
                            disabled = {props.id || patientSelected}
                            fullWidth
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            id="PatintInfoFormLastName"
                            label="Soyad"
                            value={patient?.last_name}
                            onChange={(e)=> setPatient({...patient,last_name:e.target.value})}
                            required
                            disabled = {props.id || patientSelected}
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row">
                    {/* <TextInput
                        id="PatintInfoFormNationality"
                        label="Uyruk"
                        col={6}
                        lwidth="30%"
                        defaultValue={props.id || patientSelected ? patient?.nationality : props.data?.nationality}
                        disabled = {props.id || patientSelected}
                    /> */}
                    <div className="col-12">
                        <CountryPickerInput
                            id="PatintInfoFormNationality"
                            label="Uyruk"
                            defaultValue={patient?.nationality}
                            onChange={(e)=> setPatient({...patient,nationality:e})}
                            disabled = {props.id || patientSelected}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectInput
                            id="PatintInfoFormDOB"
                            label="Doğum Tarihi"
                            defaultValue={props.id || patientSelected ? patient?.dob : props.data?.dob}
                            disabled = {props.id || patientSelected}
                        />
                    </div>
                    <div className="col-6">
                        <SelectInput
                            id = 'PatintInfoFormGender'
                            label = 'Cinsiyet' 
                            options = {genderList.map((d)=> {return {value:d.value,label:d.ad}})}
                            value={patient?.gender}
                            onChange={(e)=> setPatient({...patient,gender:e.target.value})}
                            disabled = {props.id || patientSelected}
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PhoneInput
                            id="PatintInfoFormTelNo"
                            label="Telefon Numarası"
                            defaultValue={patient?.tel_no}
                            onChange={(e)=> setPatient({...patient,tel_no:e})}
                            disabled = {props.id || patientSelected}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <EmailInput
                            id="PatintInfoFormEmail"
                            label="Email"
                            fullWidth
                            value={ patient?.email}
                            onChange={(e)=> setPatient({...patient,email:e.target.value})}
                            disabled = {props.id || patientSelected}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextInput
                            id="PatintInfoFormNationalID"
                            label="Kimlik Numarası"
                            fullWidth
                            value={patient?.national_id}
                            onChange={(e)=> setPatient({...patient,national_id:e.target.value})}
                            disabled = {props.id || patientSelected}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextInput
                            id="PatintInfoFormPassPortID"
                            label="Pasaport Numarası"
                            fullWidth
                            value={ patient?.passport_id}
                            onChange={(e)=> setPatient({...patient,passport_id:e.target.value})}
                            disabled = {props.id || patientSelected}
                        />
                    </div>
                </div>
            </CardView3>
            <div style={{height:'4px' , width:'100%'}}></div>
            <CardView3 title='Hasta Adresi'>
                <div className="row">
                    <div className="col-12">
                        <CountryPickerInput
                            id="PatintInfoFormCountery"
                            label="Countery"
                            defaultValue={patient?.country}
                            onChange={(e)=> setPatient({...patient,country:e})}
                            disabled = {props.id || patientSelected}
                            fullWidth
                        />
                    </div>
                    <div className="col-12">
                        <TextInput
                            id="PatintInfoFormCity"
                            label="Şehir"
                            fullWidth
                            value={patient?.city}
                            onChange={(e)=> setPatient({...patient,city:e.target.value})}
                            disabled = {props.id || patientSelected}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextAreaInput
                            id="PatintInfoFormFullAdreess"
                            label="Açık Adres"
                            value={patient?.full_address}
                            onChange={(e)=> setPatient({...patient,full_address:e.target.value})}
                            disabled = {props.id || patientSelected}
                            multiline
                            minRows = {2}
                            fullWidth
                        />
                    </div>
                </div> 
            </CardView3>
        </div>
        <div style={{width:'100%',height:'50px',display:'flex',justifyContent:'end',alignItems:'center',paddingInline:'20px',borderTop:'1px #ddd solid'}}>
            <button type="button" class="btn btn-danger" style={{width:'150px',marginRight:'20px'}} onClick={()=> onClickClose()}>İptal</button>
            <button type="submit" class="btn btn-success" style={{width:'150px'}}>Kaydet</button>
        </div>
    </form>
    </>
  );
}
