function compaireDate(a , b , pattern){
  if(!a){
    return 1;
  }else if(!b){
    return -1;
  }
  let yyyyi = pattern.indexOf("yyyy");
  let yyyya = parseInt(a.substring(yyyyi,yyyyi+4)) ;
  let yyyyb = parseInt(b.substring(yyyyi,yyyyi+4)) ;
  if(yyyyb < yyyya){
    return -1 ;
  }else if(yyyyb > yyyya){
    return 1 ;
  }else{
    let MMi = pattern.indexOf("MM");
    let MMa = parseInt(a.substring(MMi,MMi+2)) ;
    let MMb = parseInt(b.substring(MMi,MMi+2)) ;
    if(MMb < MMa){
      return -1 ;
    }else if(MMb > MMa){
      return 1 ;
    }else{
      let ddi = pattern.indexOf("dd");
      let dda = parseInt(a.substring(ddi,ddi+2)) ;
      let ddb = parseInt(b.substring(ddi,ddi+2)) ;
      if(ddb < dda){
        return -1 ;
      }else if(ddb > dda){
        return 1 ;
      }else{
        return 0 ;
      } 
    }
  }
}

function descendingComparator(a, b, orderBy) {
  //console.log(a, b, orderBy);
    if(orderBy.numeric){
      if (parseFloat(b[orderBy.id]) < parseFloat(a[orderBy.id])) {
        return -1;
      }
      if (parseFloat(b[orderBy.id]) > parseFloat(a[orderBy.id])) {
        return 1;
      }
      return 0;
    }else if(orderBy.isDate){
      if(orderBy.pattern){
        /*
        if (parseFloat(b[orderBy.id]) < parseFloat(a[orderBy.id])) {
          return -1;
        }
        if (parseFloat(b[orderBy.id]) > parseFloat(a[orderBy.id])) {
          return 1;
        }
        */
        return compaireDate(a[orderBy.id],b[orderBy.id],orderBy.pattern);

      }else{
        descendingComparator(a, b, {...orderBy,pattern:'dd-MM-yyyy'})
      }
    }else{
      if (b[orderBy.id] < a[orderBy.id]) {
        return -1;
      }
      if (b[orderBy.id] > a[orderBy.id]) {
        return 1;
      }
      return 0;
    }
}
  
export function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
export function stableSort(array, comparator) {
    if(!array)return[];
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
  