import React from "react";
import { Calendar as Cal, momentLocalizer } from "react-big-calendar";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import moment from "moment";
require('globalize/lib/cultures/globalize.culture.tr-TR');

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const DragAndDropCalendar = withDragAndDrop(Cal);

const Calendar = ({
    events = [],
    date = new Date(),
    onNavigate,
    view = "week",
    onView,
    culture='tr-TR',
    views = {
      day: true,
      month: true,
      week: true,
      //myweek: MyWorkWeek
    },
    getNow = () => new Date(),
    accessors,
    selectable = false,
    
    ...props
  }) => {
    const localizer = momentLocalizer(moment);
  
    return (
      <DragAndDropCalendar
        {...{
          localizer,
          events,
          date,
          onNavigate,
          view,
          onView,
          views,
          getNow,
          accessors,
          selectable,
          min:new Date(0, 0, 0, 9, 0, 0),
          max:new Date(0, 0, 0, 18, 0, 0)
        }}
        messages={{
          myweek: "My Week",
          week: 'Hafta',
          day: 'Gün',
          month: 'Ay',
          previous: 'Önceki',
          next: 'Sonraki',
          today: 'Bugün',
        }}
        resizable
        {...props}
      />
    );
  };
  
  export default Calendar;
  