import React, { useEffect } from 'react';
import './style.css';
export default function CardView2(props){
    const onClickIcon=()=>{
        if(props.onClickIcon){
            props.onClickIcon();
        }
    }
    
    useEffect(()=>{
        return ()=>{};
    },[])
    return (<>
    <div className='cardView2'>
        <div className='cardViewHeader2'>
            <div className='row ' style={{width:'100%',height:'100%'}}>
                <div className='col-12' style={{width:'100%',height:'100%'}}>
                    <div style={{width:'100%',height:'100%',position:'relative',display:'flex',alignItems:'center'}}>

                        <div style={{width:'100%',height:'100%' ,display:'flex',alignItems:'center',justifyContent:'center',position:'absolute',top:'0',left:'0'}}>
                            <label>{props.title}</label> 
                        </div>
                        {props.isIcon &&
                            <div style={{width:"30px",height:'30px',display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',top:'0',right:'0'}}>
                                <i class="fa-solid fa-bars iconWhite" onClick={()=> onClickIcon()}></i>
                            </div>    
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className='cardViewBody2'>
            <div className='row'>
                <div className='col-12'>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
    </>);
}