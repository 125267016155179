import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Button, InputAdornment, Paper } from '@mui/material';
import { loading, removeLoading } from '../../commons/alerts';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { TextInput } from '../../commons/InputGroupMui/TextInput';
import { SelectInput } from '../../commons/InputGroupMui/SelectInput';
import { failed, success } from '../../../utils/alerts';
export function SubServiceDetailComponent(props){
    const axiosPrivate = useAxiosPrivate();
    const [service,setService]= useState();
    const getServiceDetails=(ser)=>{
        if(ser?.id){
            loading('');
            axiosPrivate.get('/services/sub/'+ser.id)
            .then((res)=>{
                console.log(res.data.subService);
                setService(res.data.subService);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{removeLoading()})
        }else{

            setService({service_id:ser?.service_id});
        }
    }
    const onClickSave=()=>{
        if(service.id){
            loading('');
            axiosPrivate.put('/services/sub/'+service.id,service)
            .then((res)=>{
                success(res.data.message)
                if(props.onSave){
                    props.onSave(res.data.service)
                }
            }).catch((err)=>{
                console.log(err);
                failed(err.response.data.message);
            }).finally(()=>{removeLoading()})
        }else{
            loading('');
            axiosPrivate.post('/services/sub',service)
            .then((res)=>{
                success(res.data.message)
                if(props.onSave){
                    props.onSave(res.data.service)
                }
            }).catch((err)=>{
                console.log(err);
                failed(err.response.data.message);
            }).finally(()=>{removeLoading()})
        }
    }
    const onClickCancel=()=>{
        if(props.onClickCancel){
            setService()
            props.onClickCancel();
        }
    }
    useEffect(()=>{
        getServiceDetails(props.subService);
        return ()=>{};
    },[props.subService])
    return(<>
        <Paper sx={{height:'100%'}}>
            <div style={{height:'90%',padding:'10px'}}>
                <TextInput
                    fullWidth
                    label='Hizmet adı'
                    value={service?.name}
                    onChange={(e)=>{setService({...service,name:e.target.value})}}
                />
                <Paper sx={{marginTop:'20px',padding:'10px'}}>
                    <TextInput
                        fullWidth
                        label='Hizmet TL'
                        type='number'
                        value={service?.cost_tl}
                        onChange={(e)=>{setService({...service,cost_tl:e.target.value})}}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">TL</InputAdornment>,
                        }}
                    />
                    <TextInput
                        fullWidth
                        label='Hizmet DOLLAR'
                        type='number'
                        value={service?.cost_dollar}
                        onChange={(e)=>{setService({...service,cost_dollar:e.target.value})}}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                    <SelectInput
                        label = 'Para Birimi'
                        options = {[{label:'TL',value:'1'},{label:'DOLAR',value:'2'}]} 
                        fullWidth
                        value={service?.default_currency}
                        onChange={(e)=>{setService({...service,default_currency:e.target.value})}}
                    />
                    <TextInput
                        fullWidth
                        label='Hizmet indirim'
                        type='number'
                        value={service?.discount}
                        onChange={(e)=>{setService({...service,discount:e.target.value})}}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </Paper>
                
            </div>
            <div style={{height:'10%',display:'flex',justifyContent:'space-evenly',alignItems:'center'}}>
                <Button 
                    variant="outlined" 
                    startIcon={<CloseIcon />}
                    sx={{width:'40%'}}
                    onClick={onClickCancel}
                    color='error'
                    disabled = {!props.subService}
                >   
                    IPTAL
                </Button>
                <Button 
                    variant="outlined" 
                    startIcon={<SaveIcon />}
                    sx={{width:'40%'}}
                    onClick={onClickSave}
                    disabled = {!props.subService}
                >   
                    KAYDET
                </Button>
            </div>
        </Paper>
    </>)
}