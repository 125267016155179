import React from "react";
import "./OurService2.css";
import womenwallpaper from "../../../../../assets/womenwallpaper.jpg";
import i18next, { t } from "i18next";

export default function OurServices2() {
  console.log(i18next.language,"dil")
  return (
    <div className="ourService2">
      <div className="content">
        <div className="header">
          <div className={i18next.language=='AR'?"header-text-AR":"header-text"}>{t('OUR_GOALS.content.header')}</div>
        </div>
        <div className="body">
          <div className="body-inner">
            <img src={womenwallpaper} />
            <div className="body-innerone">
              <div className="circle1">
                <div className="circle-inner">
                  <div className="circleHead">
                    <div className="circle-head-text">1</div>
                  </div>
                  <div className="circleBody">
                    <div className="circle-body-text" dangerouslySetInnerHTML={{__html:t('OUR_GOALS.content.body.text1')}}>
                    </div>
                  </div>
                </div>
              </div>
              <div className="circle2">
                <div className="circle-inner">
                  <div className="circleHead">
                    <div className="circle-head-text">2</div>
                  </div>
                  <div className="circleBody">
                    <div className="circle-body-text" dangerouslySetInnerHTML={{__html:t('OUR_GOALS.content.body.text2')}}>
                    </div>
                  </div>
                </div>
              </div>
              <div className="circle3">
                <div className="circle-inner">
                  <div className="circleHead">
                    <div className="circle-head-text">3</div>
                  </div>
                  <div className="circleBody">
                    <div className="circle-body-text" dangerouslySetInnerHTML={{__html:t('OUR_GOALS.content.body.text3')}}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
