import React from "react";
import "./ShereenCareBeautyCenter.css";
import medicalimg from "../../../../../assets/IMG_7265.JPG";
import medicalLogo from "../../../../../assets/logo.svg";
import i18next, { t } from "i18next";

export default function ShereenCareBeautyCenter() {
  return (
    <div className="MedicalPark">
      <div className="content">
        <div className="header">
          <div className={i18next.language=='AR'?"header-text-AR": "header-text"}>
            {t("ClinicsPage.Clinics.2.clinic_name")}
          </div>
        </div>
        <div className="body">
          <img src={medicalLogo} alt="Medical Logo" className="logo-image" />
          <img
            src={medicalimg}
            alt="Medical Park"
            className="background-image"
          />
          <div className="body-inner">
            <div className="body-inner-text" >
              <div dangerouslySetInnerHTML={{__html:t('ClinicsPage.Clinics.2.text')}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
