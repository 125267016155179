import React, { useState } from "react";
import facebook  from "../../../assets/icons8_Facebook_32.png";
import tiktok    from "../../../assets/icons8_tiktok_32.png";
import google    from "../../../assets/icons8_google_old_32.png";
import instagram from "../../../assets/icons8_Instagram_32.png";
import linkedin  from "../../../assets/icons8_linkedin_32.png";
import           './SocialMediaBar.css';

export function SocialMediaBar(props){
    const onMouseEnter=(id)=>{
        console.log(id);
        document.getElementById(id).classList.add('fontSize16');
        document.getElementById(id).classList.add('opacity1');
        
    }
    const onMouseLeave=(id)=>{
        console.log(id);
        document.getElementById(id).classList.remove('fontSize16');
        document.getElementById(id).classList.remove('opacity1');
    }
    const onClickSocial=(link)=>{
        window.open(link);
    }
    return(<>
        <div className="socialMediaBarContainer">
            <div className="socialMediaBarBody">
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='facebookId' className="socialMediaBarLabe">
                            FACEBOOK
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('facebookId')}
                        onMouseLeave={()=>onMouseLeave('facebookId')}
                    >
                        <img
                            className="socialNavBarIcon"
                            src={facebook}
                            onClick={() =>
                                onClickSocial("https://www.facebook.com/shereencaregroup")
                            }
                        />
                    </div>
                </div>
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='tiktokId' className="socialMediaBarLabe">
                            TIKTOK
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('tiktokId')}
                        onMouseLeave={()=>onMouseLeave('tiktokId')}
                    >
                        <img
                            className="socialNavBarIcon"
                            src={tiktok}
                            onClick={() =>
                                onClickSocial("https://www.tiktok.com/@shereencare")
                            }
                        />
                    </div>
                </div>
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='googleId' className="socialMediaBarLabe">
                            GOOGLE
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('googleId')}
                        onMouseLeave={()=>onMouseLeave('googleId')}
                    >
                        <img
                            className="socialNavBarIcon"
                            src={google}
                            onClick={() =>
                                onClickSocial("https://www.google.com/search?q=shereen+care")
                            }
                        />
                    </div>
                </div>
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='instagram1Id' className="socialMediaBarLabe">
                            INSTAGRAM
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('instagram1Id')}
                        onMouseLeave={()=>onMouseLeave('instagram1Id')}
                    >
                        <img
                            className="socialNavBarIcon"
                            src={instagram}
                            onClick={() =>
                                onClickSocial("https://www.instagram.com/shereencaredental/")
                            }
                        />
                    </div>
                </div>
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='instagram2Id' className="socialMediaBarLabe">
                            INSTAGRAM
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('instagram2Id')}
                        onMouseLeave={()=>onMouseLeave('instagram2Id')}
                    >
                        <img
                            className="socialNavBarIcon"
                            src={instagram}
                            onClick={() =>
                                onClickSocial("https://instagram.com/shereencare_italy?igshid=OGQ5ZDc2ODk2ZA==")
                            }
                        />
                    </div>
                </div>
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='instagram3Id' className="socialMediaBarLabe">
                            INSTAGRAM
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('instagram3Id')}
                        onMouseLeave={()=>onMouseLeave('instagram3Id')}
                    >
                        <img
                            className="socialNavBarIcon"
                            src={instagram}
                            onClick={() =>
                                onClickSocial("https://www.instagram.com/shereencaregroup/")
                            }
                        />
                    </div>
                </div>
                <div className="socialMediaBarItemContainer">
                    <div className="socialMediaBarLabelContainer">
                        <label id='linkedinId' className="socialMediaBarLabe">
                            LINKEDIN
                        </label>
                    </div>
                    <div 
                        className="socialMediaBarIconContainer"
                        onMouseEnter={()=>onMouseEnter('linkedinId')}
                        onMouseLeave={()=>onMouseLeave('linkedinId')}
                    >
                        <img
                            className="socialNavBarIcon spicialSize"
                            src={linkedin}
                            onClick={() =>
                                onClickSocial("https://www.linkedin.com/company/shereen-care-group/")
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    </>);
};