import React, { useState } from "react";
import "./ClinicPage2.css";
import MedicalPark from "./clinicComponent/MedicalPark";
import logo from "../../../../assets/shereen_care_logo1.png";
import logo2 from "../../../../assets/donatim-dis-logo-profil (2).png";
// import logo3 from "../../../../assets/target-audience.png";
import OzelDonatim from "./clinicComponent/OzelDonatim";
import { t } from "i18next";
import ShereenCareBeautyCenter from "./clinicComponent/ShereenCareBeautyCenter";

export default function ClinicPage2() {
  const [page, setPage] = useState(0);
  const [activeButton, setActiveButton] = useState(0);
  const maxPage = 2;
  const next = () => {
    setPage((d) => (d + 1) % (maxPage + 1));
  };
  const previos = () => {
    setPage((d) => (d - 1 + maxPage + 1) % (maxPage + 1));
  };
  const handleButtonClick = (ind) => {
    setPage(ind);
    setActiveButton(ind);
  };
  return (
    <div id="ClinicsPageBody" className="clinicPage2">
      <div className="content">
        <div className="header">
          <div className="header-text">{t("ClinicsPage.header.0")}</div>
        </div>
        <div className="body">
        {page == 0 && <ShereenCareBeautyCenter />}
        {page == 1 && <OzelDonatim />}
        </div>
        <div className="footer">
          <div className="foot-inner">
            <div className="foot-button">
              <button
                type="button"
                className={`btn btn-sm ${
                  activeButton === 0 ? "activeButton" : ""
                }`}
                onClick={() => handleButtonClick(0)}
              >
                <img src={logo}></img>
              </button>
              <button
                type="button"
                className={`btn btn-sm ${
                  activeButton === 1 ? "activeButton" : ""
                }`}
                onClick={() => handleButtonClick(1)}
              >
                <img src={logo2}></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
