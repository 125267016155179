import React from 'react'
import { useParams } from 'react-router-dom';
import BlogSidebar from '../BlogPageMain/BlogSidebar/BlogSidebar';
import { NavBar } from '../../../navBar/NavBar';
import './BlogPageDetails.css'
import BlogPageSingleDetailPost from './BlogPageSingleDetailPost/BlogPageSingleDetailPost';


function BlogPageDetails() {

  const {id}=useParams();

  return (
    <>
      <div className="BlogPageDetails">
        <NavBar/>
        <BlogPageSingleDetailPost/>
        <BlogSidebar/>        
      </div>
      
      {/* <p>Blog Id : {id}</p> */}
    </>
  )
}

export default BlogPageDetails