import React from 'react'
import './header.css'
import { ThemeProvider,useTheme } from '@emotion/react'
import {NavBar} from '../../../../navBar/NavBar'

//Navbarın altındaki alt bölüm
function BlogPageHeader() {
  const theme = useTheme();

  return (
    <>
    <NavBar/>
    <ThemeProvider theme={theme}>
      <div className="header">
        <div className="headerTitles">
          <span className='headerTitleSm'color='logoColor.main'>
          Shereen Care
            </span>
          <span className="headerTitleLg" color='logoColor.main'>
          Blog
          </span>
        </div>
        <img className='headerImg' src="https://images.pexels.com/photos/2383010/pexels-photo-2383010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
      </div>
      </ThemeProvider>
    </>
  )
}

export default BlogPageHeader