import React, { useEffect, useState } from 'react';
import Tabel from '../../commons/TableComponent/TableComponent';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { DeleteWarn, loading, removeLoading } from '../../commons/alerts';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { TextInput } from '../../commons/InputGroupMui/TextInput';


export function ServiceComponent(props){
    const axiosPrivate = useAxiosPrivate();
    const [services,setServices]= useState([]);
    const [dialogOpen , setDialogOpen] = useState(false);
    const [selectedServices,setSelectedService] = useState();
    const getServices=()=>{
        loading('');
        axiosPrivate.get('/services/list')
        .then((res)=>{
            console.log(res.data.rows);
            
            setServices(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{removeLoading()})
    };
    const onSelectService=(service)=>{
        if(props.onSelectService){
            props.onSelectService(service);
        }
    }
    const onEditService=(e,service)=>{
        e.stopPropagation();
        setSelectedService(service);
        setDialogOpen(true);
    }
    const onCloseDialog=()=>{
        setDialogOpen(false);
        setSelectedService();
    }
    const handleSubmit=()=>{
        if(selectedServices?.id){
            loading('');
            axiosPrivate.put('/services/'+selectedServices.id,selectedServices)
            .then((res)=>{
                setDialogOpen(false)
                let ind = services.findIndex((d)=>d.id==selectedServices.id);
                services[ind] = res.data.service;
                setServices([...services]);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{removeLoading()})
        }else{
            loading('');
            axiosPrivate.post('/services',selectedServices)
            .then((res)=>{
                console.log(res.data.rows);
                setDialogOpen(false)
                setServices([...services,res.data.service]);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{removeLoading()})
        }
    }
    const onClickDelete=(e,service,ind)=>{
        e.stopPropagation();
        DeleteWarn('Hizmet Sil','Emin misiniz',()=>{
            loading('');
            axiosPrivate.delete('/services/'+service.id)
            .then((res)=>{
                services.splice(ind,1);
                setServices([...services]);
                setDialogOpen(false)
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{removeLoading()})
        })

    }

    
    useEffect(()=>{
        getServices();
        return ()=>{};
    },[])
    return(
        <Paper sx={{height:'100%'}}>
            <div style={{height:'90%'}}>
                <Tabel
                    data={services}
                    headers={[{id:'name',label:'Hizmetler',isSortable:true}]}
                    onClickRow={onSelectService}
                    isRightActions
                    rightActionsOptions = {(e,ind)=>{return <>
                        <IconButton aria-label="delete" color='primary' onClick={(evt)=>onEditService(evt,e)}>
                            <EditIcon fontSize='small'/>
                        </IconButton>
                        <IconButton aria-label="delete" color='error' onClick={(evt)=>onClickDelete(evt,e,ind)}>
                            <DeleteIcon fontSize='small'/>
                        </IconButton>
                    </>}}
                />
            </div>
            <div style={{height:'10%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Button 
                    variant="outlined" 
                    startIcon={<AddIcon />}
                    sx={{width:'80%'}}
                    onClick={()=> setDialogOpen(true)}
                >   
                    HİZMET EKLE
                </Button>
            </div>
            <Dialog
                open={dialogOpen}
                fullWidth
                maxWidth={'xs'}
                onClose={()=> onCloseDialog()}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{selectedServices?.id?'HİZMET Güncelle':"HİZMET EKLE"}</DialogTitle>
                <DialogContent>
                    <TextInput
                        fullWidth
                        label='Hizmet adı'
                        value={selectedServices?.name}
                        onChange={(e)=>{setSelectedService({...selectedServices,name:e.target.value})}}
                    />
                </DialogContent>
                <DialogActions >
                    <Button color='error' onClick={()=> onCloseDialog()}>Iptal</Button>
                    <Button onClick={()=>handleSubmit()}>{selectedServices?.id?'Güncelle':"EKLE"}</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}