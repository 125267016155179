import React from "react";
import { TextInput } from "../../commons/InputGroupMui/TextInput";

export default function ReferanceInfos(props) {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <TextInput
            disabled
            onChange={(e) =>
              props.setForm({
                ...props.form,
                referance_first_name: e.target.value,
              })
            }
            value={props.form.referance_first_name}
            label="Ad"
            id="first_name"
            fullWidth
          />
        </div>
        <div className="col-6">
          <TextInput
          disabled
            onChange={(e) =>
              props.setForm({ ...props.form, referance_tel_no: e.target.value })
            }
            value={props.form.referance_tel_no}
            label="Telefon Numarası"
            id="tel_no"
            fullWidth
          />
        </div>
      </div>
      <div className="row" style={{ height: "15px" }}></div>
      <div className="row">
        <div className="col-6">
          <TextInput
          disabled
            onChange={(e) =>
              props.setForm({
                ...props.form,
                referance_last_name: e.target.value,
              })
            }
            value={props.form.referance_last_name}
            label="Soyad"
            id="last_name"
            fullWidth
          />
        </div>
        <div className="col-6">
          <TextInput
          disabled
            onChange={(e) =>
              props.setForm({ ...props.form, referance_email: e.target.value })
            }
            value={props.form.referance_email}
            label="Email"
            id="email"
            fullWidth
          />
        </div>
      </div>
      <div className="row" style={{ height: "15px" }}></div>
      <div className="row">
        <div className="col-12">
          <TextInput
          disabled
            value={props.form.reference_start_time}
            label="Başlangıç Tarihi"
            id="date"
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
