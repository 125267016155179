import React, { useEffect, useRef, useState } from "react";
import logo   from '../../../../assets/logo.svg';
import Button from '@mui/material/Button';
import './VideoComponent.css';
import i18next, { t } from "i18next";

export function VideoComponent(props){
    //const videoRef = useRef(undefined);
    let [index , setIndex] = useState(-1);
    const [timeoutID,setTimeOutId] = useState(null);
    const changeVideo=()=>{
        index = (index + 1) % props.videos.length;    
        document.getElementById('videoComponentVideosContainer'+index).style.opacity = '1' ;
        document.getElementById('videoComponentVideosContainer'+index).style.display = '' ;
        
        document.getElementById('videoComponentVideosContainer'+(index+props.videos.length-1)%props.videos.length).style.opacity = '0';  
        document.getElementById('videoComponentVideosContainer'+(index+props.videos.length-1)%props.videos.length).style.display = 'none';      
        document.getElementById('videoHeader'+index).style.display = 'block' ;
        document.getElementById('videoHeader'+(index+props.videos.length-1)%props.videos.length).style.display = 'none';        
        
        //console.log(index)
        setIndex(index);
        //console.log(props.videos[index].duration)
        //let timeout = setTimeout(changeVideo,(props.videos[index].duration-1)*1000);
        setTimeOutId(setTimeout(changeVideo,(props.videos[index].duration-1)*1000))
    }
    
    useEffect(() => {
        // console.log('im here ', props.video)
        changeVideo()
        //videoRef.current.defaultMuted = true;
        //videoRef.current?.load();
        return ()=>{clearTimeout(timeoutID)};
    },[props.videos])
    return(<>
    <div className="VideoComponentContainer">
        {props.videos.map((video,ind)=>{
            return <>
            <div 
                id ={'videoComponentVideosContainer'+ind} 
                className="videoComponentVideosContainer" 
                style={{top:`${(-1*ind)}%`}}>
                <img
                    id = {'videoPlayer'+ind}
                    //width = {props.isMobil?'':'100%'}
                    //height={props.isMobil?'100%':'unset'}
                    //ref={videoRef}
                    //onPlay={(e)=> console.log(e)}
                    //loop
                    //autoPlay
                    //muted
                    //playsInline
                    style={{
                        width : props.isMobil?'':'100%',
                        height : props.isMobil?'100%':'unset',
                        marginLeft : props.isMobil?'-25%':'unset'
                    }}
                    src = {video.video}
                    preload="none"
                    //type="video/mp4"
                    //type="application/x-mpegURL"
                    //poster={temp}
                />
                
            </div>
            
            <div 
                id = {'videoHeader'+ind}
                className="videoHeader"
                style={{top:`${((-1*ind)-50)}%`}}
            >
                <div className="videoHeader-header">
                    <div className={i18next.language=='AR'?"videoHeader-header-header-AR":"videoHeader-header-header"}>
                        <label className="videoHeader-label" style={{color : video.headerColor}}>
                            {video.header}
                        </label>
                    </div>
                </div>
                <div className="videoHeader-btnContainer">
                    <div className="row" >
                        <div className="col-6" style={{paddingLeft:'0'}}>
                            <Button 
                                variant="contained" 
                                color={ video.btnColor} 
                                fullWidth
                                onClick={()=>{
                                    document.getElementById('ServicePageBody').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                                }}
                            > 
                                {t('READ_MORE.1')}
                            </Button>
                        </div>
                        <div className="col-6" style={{paddingLeft:'0'}}>
                            <Button 
                                variant="contained" 
                                color={ video.btnColor} 
                                fullWidth
                                onClick={()=>{
                                    document.getElementById('ContactUsPageBody').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                                }}
                            > 
                                {t('CONTACT_US.1')}
                            </Button>    
                        </div>
                    </div>
                </div>
            </div>
            </>
        })}
        <div className="DarkLayer"></div>
    </div>
        
    </>);
}