import React, { useEffect , useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { confirm, loading, removeLoading, success } from '../../commons/alerts';
import {TextInput} from '../../commons/InputGroupMui/TextInput';
import PhoneInput from '../../commons/InputGroupMui/PhoneInput';
import { EmailInput } from '../../commons/InputGroupMui/EmailInput';
import { SelectInput } from '../../commons/InputGroupMui/SelectInput';
import { CountryPickerInput } from '../../commons/InputGroupMui/CountryPickerInput';
import { TextAreaInput } from '../../commons/InputGroupMui/TextAreaInput';
import { failed } from '../../commons/alertToast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export function AddRequestForm(props){
    
    const axiosPrivate = useAxiosPrivate();
    const history = useHistory();
    const [services   , setServices] = useState([]);
    const [referances , setReferances] = useState([]);
    const [isReferance , setIsReferance] = useState(null);
    const [form , setForm] = useState({});

    const onSubmit=(e)=>{
        e.preventDefault();
        if(props.request){
            history.push('/app/requests/new/'+props.request?.id);
            return ;
        }
        console.log(form)   
        loading()
        axiosPrivate.post('/requests/post/request',form)
        .then((res)=>{
            console.log(res);
            if(res.data.notifications){
                res.data.notifications.forEach(element => {
                    success(element);        
                });
            }
            success(res.data.message);
            
            if(props.onSubmitted){
                props.onSubmitted();
            }
            confirm('do you want to enter',res.data.message,()=>console.log('asasasd'))
        }).catch((err)=>{
            console.log(err.response);
            failed(err.response.data.message)
        }).finally(()=>{removeLoading()});
    }

    const oncancel=()=>{
        if(props.onCancel){
            props.onCancel();
        }
    }
    

    const getServices=()=>{
        axiosPrivate.get('/services/list')
        .then((res)=>{
            console.log(res.data);
            setServices(res.data.rows.map((row)=>{return {label:row.name , value:row.id}}))
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    const getReferances=()=>{
        axiosPrivate.get('/user/referance/list')
        .then((res)=>{
            console.log(res.data);
            setReferances(res.data.rows.map((row)=>{return {label:row.first_name +' '+ row.last_name , value:row.id}}))
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    const getIsReferance = () => {
        axiosPrivate.get('/user/is/referance')
        .then((res)=>{
            console.log(res.data);
            if(res.data.isReferance){
                setIsReferance(res.data.userId);
            }else{
                setIsReferance(null);
            }
            //setReferances(res.data.rows.map((row)=>{return {label:row.first_name +' '+ row.last_name , value:row.id}}))
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    useEffect(()=>{
        console.log(props.request)
        getServices();
        getReferances();
        getIsReferance();
        if(props.request){
            setForm(props.request);
        }
        return ()=>{};
    },[props.id]);

    return(<>
        <form autoComplete='off' onSubmit={(e)=> onSubmit(e)} style={{height:'100%'}}>
            <div className='row' style={{width:'100%' , height :'92%' , margin:'0px',overflowY:'auto'}}>
                <div className='col-12'>
                    <div className='row' style={{ margin:'0px'}}>
                        <div className='col-6' style={{marginTop:'10px'}}>
                            <TextInput
                                id="firstNameAddRequestForm"
                                fullWidth
                                label="Ad"
                                placeholder 
                                value={form?.first_name}
                                onChange={(e)=> setForm({...form , first_name : e.target.value})}
                                //value={request?.first_name}
                                required
                                //error           = {errorfn}
                                helperText      = {'lütfen ad giriniz'}
                                disabled = {props.request}
                            />
                        </div>
                        <div className='col-6' style={{marginTop:'10px'}}>
                            <TextInput
                                id="lastNameAddRequestForm"
                                fullWidth
                                label="Soyad"
                                placeholder 
                                value={form?.last_name}
                                onChange={(e)=> setForm({...form , last_name : e.target.value})}
                                required
                                //error           = {errorfn}
                                helperText      = {'lütfen soyad giriniz'}
                                disabled = {props.request}
                            />
                        </div>
                        <div className='col-6' style={{marginTop:'10px'}}>
                            <PhoneInput
                                id="PhoneNumberNameAddRequestForm"
                                required
                                label="Telefon" 
                                defaultValue={form?.tel_no}
                                onChange={(e)=> setForm({...form , tel_no : e})}
                                //error           = {errortn}
                                helperText      = {'lütfen telefon no giriniz'}
                                disabled = {props.request}
                            />
                        </div>
                        <div className='col-6' style={{marginTop:'10px'}}>
                            <EmailInput
                                id="EmailAddRequestForm"
                                fullWidth
                                label="Email" 
                                value={form?.email}
                                onChange={(e)=> setForm({...form , email : e.target.value})}
                                //error           = {errore}
                                helperText      = {'lütfen email giriniz'}
                                disabled = {props.request}
                            />
                        </div>
                        <div className='col-6'></div>
                        <div className='col-6' style={{marginTop:'10px'}}>
                            <SelectInput
                                id="GenderAddRequestForm"
                                options = {[{ad:'Erkek',value:'MALE'},{ad:'Kadın',value:'FEMALE'},{ad :'Diğer',value:'OTHER'}].map((d)=>{return{label:d.ad,value:d.value}})}
                                label="Cinsiyet" 
                                //required
                                value={form?.gender}
                                onChange={(e)=> setForm({...form , gender : e.target.value})}
                                disabled = {props.request}
                            />
                        </div>
                        <div className='col-12' style={{marginTop:'10px'}}>
                            <SelectInput
                                id="ServiceAddRequestForm"
                                options = {services}
                                label="Servisler" 
                                required
                                value={form?.service_id}
                                onChange={(e)=> setForm({...form , service_id : e.target.value})}
                                disabled = {props.request}
                            />
                        </div>
                        <div className='col-12' style={{marginTop:'10px'}}>
                            <CountryPickerInput
                                id = "NationalityAddRequestForm"
                                label = 'Uyruk'
                                defaultValue={form?.country}
                                disabled = {props.request}
                                onChange={(e)=> setForm({...form , country : e})}
                            />
                        </div>
                        <div className='col-12' style={{marginTop:'10px'}}>
                            <SelectInput
                                id="ReferanceAddRequestForm"
                                options = {referances}
                                label="Referans" 
                               // required
                                value={form?.reference_id ? form?.reference_id : isReferance?isReferance :''}
                                onChange={(e)=> setForm({...form , reference_id : e.target.value})}
                                disabled
                            />
                        </div>
                        <div className='col-12' style={{marginTop:'10px'}}>
                            <TextAreaInput
                                placeholder = {'notlar...'}
                                id = "NotesAddRequestForm"
                                multiline
                                fullWidth
                                minRows = {3}
                                maxRows = {5}
                                label = 'Notlar...'
                                value={form?.notes}
                                onChange={(e)=> setForm({...form , notes : e.target.value})}
                                disabled = {props.request}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row' style={{width:'100%' , height :'8%' , margin :'0px'}}>
                <div className='row'>
                    <div className='col-6'>
                        <button
                            className='btn btn-danger form-control'
                            style={{height:'75%', display:'flex',justifyContent:'space-between',alignItems:'center'}}
                            onClick={()=> oncancel()}
                            type='button'
                        >
                            <i class="fa-regular fa-circle-xmark"></i>
                            &nbsp;
                            <b>İptal</b>
                        </button>
                    </div>
                    <div className='col-6'>
                        <button
                            className='btn btn-primary form-control'
                            style={{height:'75%', display:'flex',justifyContent:'space-between',alignItems:'center'}}
                            onClick={()=>{}}
                        >
                            {props.request?<i class="fa-regular fa-circle-check"></i>:<i class="fa-solid fa-plus"></i>}
                            &nbsp;
                            <b>{props.request? "Devam Et" :"Yeni İstek Ekle"}</b>
                        </button>
                    </div>
                </div>
                
            </div>
        </form>
        
    </>);
}