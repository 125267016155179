import React, { useEffect, useRef, useState } from "react";
import "./ServicesPage.css";
import { ServicesPageMenu } from "./servicesPageMenu/ServicesPageMenu";
import { SubServicesComponent } from "./subServicesComponents/SubServicesComponent";
import img1 from '../../../../assets/servicesImg/1.jpg';
import img2 from '../../../../assets/servicesImg/2.jpg';
import img3 from '../../../../assets/servicesImg/3.jpg';
import img4 from '../../../../assets/servicesImg/4.jpg';
import img5 from '../../../../assets/servicesImg/5.jpg';
import img6 from '../../../../assets/servicesImg/6.jpg';
import img7 from '../../../../assets/servicesImg/7.jpg';
import img8 from '../../../../assets/servicesImg/8.jpg';
import img9 from '../../../../assets/servicesImg/9.jpg';
import img10 from '../../../../assets/servicesImg/10.jpg';
import img11 from '../../../../assets/servicesImg/11.jpg';
import img12 from '../../../../assets/servicesImg/12.jpg';
import img13 from '../../../../assets/servicesImg/13.jpg';
import img14 from '../../../../assets/servicesImg/14.jpg';
import img15 from '../../../../assets/servicesImg/15.jpg';
import img16 from '../../../../assets/servicesImg/16.jpg';
import img17 from '../../../../assets/servicesImg/17.jpg';
import img18 from '../../../../assets/servicesImg/18.jpg';
import img19 from '../../../../assets/servicesImg/19.jpg';
import img20 from '../../../../assets/servicesImg/20.jpg';
import img21 from '../../../../assets/servicesImg/21.jpg';
import img22 from '../../../../assets/servicesImg/22.jpg';
import img23 from '../../../../assets/servicesImg/23.jpg';
import img24 from '../../../../assets/servicesImg/24.jpg';
import img25 from '../../../../assets/servicesImg/25.jpg';
import img26 from '../../../../assets/servicesImg/26.jpg';
import img27 from '../../../../assets/servicesImg/27.jpg';
import img28 from '../../../../assets/servicesImg/28.jpg';
import img29 from '../../../../assets/servicesImg/29.jpg';
import img30 from '../../../../assets/servicesImg/30.jpg';
import img31 from '../../../../assets/servicesImg/31.jpg';
import img32 from '../../../../assets/servicesImg/32.jpg';
import img33 from '../../../../assets/servicesImg/33.jpg';
import img34 from '../../../../assets/servicesImg/34.jpg';
import img35 from '../../../../assets/servicesImg/35.jpg';
import img36 from '../../../../assets/servicesImg/36.jpg';
import img37 from '../../../../assets/servicesImg/37.jpg';
import img38 from '../../../../assets/servicesImg/38.jpg';
import img39 from '../../../../assets/servicesImg/39.jpg';
import img40 from '../../../../assets/servicesImg/40.jpg';
import img41 from '../../../../assets/servicesImg/41.jpg';
import img42 from '../../../../assets/servicesImg/42.jpg';
import img43 from '../../../../assets/servicesImg/43.jpg';
import img44 from '../../../../assets/servicesImg/44.jpg';
import img45 from '../../../../assets/servicesImg/45.jpg';
import img46 from '../../../../assets/servicesImg/46.jpg';
import img47 from '../../../../assets/servicesImg/47.jpg';
import img48 from '../../../../assets/servicesImg/48.jpg';
import img49 from '../../../../assets/servicesImg/49.jpg';
import img50 from '../../../../assets/servicesImg/50.jpg';
import img51 from '../../../../assets/servicesImg/51.jpg';
import img52 from '../../../../assets/servicesImg/52.jpg';
import img53 from '../../../../assets/servicesImg/53.jpg';
import img54 from '../../../../assets/servicesImg/54.jpg';
import { t } from "i18next";

export function ServicesPage(props) {
  const ServiceNum = 54;
  const containerRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [currentX , setCurrentX] = useState(0);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;
    setOffsetX(deltaX);
  };

  const handleTouchEnd = () => {
    // Reset state when touch ends
    console.log(offsetX,currentX)
    let windowWidth = window.innerWidth ;
    if(windowWidth/4 <= Math.abs(offsetX)){
      if(offsetX > 0 && currentX > 0){
        containerRef.current.scrollTo({
          left: currentX-windowWidth,
          behavior: 'smooth', // Optional smooth scrolling
        });
        setCurrentX(currentX-windowWidth);
      }
      else if(offsetX < 0 && currentX < windowWidth * (ServiceNum-1)){
        containerRef.current.scrollTo({
          left: currentX+windowWidth,
          behavior: 'smooth', // Optional smooth scrolling
        });
        setCurrentX(currentX+windowWidth);
      }
      
    }else{
      console.log('asasd',currentX)
      containerRef.current.scrollTo({
        left: currentX,
        behavior: 'smooth', // Optional smooth scrolling
      });
    }
    setStartX(0);
    setOffsetX(0);
  };

  const onClickSubService=(id)=>{
    console.log(id);
    let windowWidth = window.innerWidth ;
    containerRef.current.scrollTo({
      left: id*windowWidth,
      behavior: 'smooth', // Optional smooth scrolling
    });
    setCurrentX(id*windowWidth);
  }
  return (
    <>
      <div id="ServicePageBody" className="ServicePageBody">
        <div className="ServicePageMenu">
          <ServicesPageMenu
            onClickSubService={(id)=>onClickSubService(id)}
          />
        </div>
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          className="ServicePageSubServicesContainer" 
          ref={containerRef}>
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.0.name`)}
            img = {img1}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.0.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.0.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.0.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.1.name`)}
            img = {img2}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.1.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.1.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.1.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.2.name`)}
            img = {img3}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.2.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.2.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.2.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.3.name`)}
            img = {img4}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.3.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.3.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.3.list.2')}}></li>
           </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.4.name`)}
            img = {img5}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.4.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.4.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.4.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.5.name`)}
            img = {img6}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.5.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.5.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.5.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.6.name`)}
            img = {img7}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.6.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.6.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.6.list.2')}}></li>

            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.7.name`)}
            img = {img8}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.7.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.7.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.7.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.8.name`)}
            img = {img9}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.8.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.8.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.8.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.9.name`)}
            img = {img10}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.9.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.9.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.9.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.10.name`)}
            img = {img11}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.10.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.10.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.10.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.11.name`)}
            img = {img12}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.11.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.11.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.11.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.12.name`)}
            img = {img13}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.12.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.12.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.12.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.13.name`)}
            img = {img14}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.13.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.13.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.13.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.14.name`)}
            img = {img15}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.14.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.14.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.14.list.2')}}></li>
            </ul>}
          />
          {/*Dental                 Page*/}
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.15.name`)}
            img = {img16}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.15.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.15.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.15.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.16.name`)}
            img = {img17}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.16.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.16.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.16.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.17.name`)}
            img = {img18}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.17.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.17.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.17.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.18.name`)}
            img = {img19}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.18.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.18.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.18.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.19.name`)}
            img = {img20}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.19.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.19.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.19.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.20.name`)}
            img = {img21}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.20.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.20.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.20.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.21.name`)}
            img = {img22}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.21.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.21.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.21.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.22.name`)}
            img = {img23}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.22.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.22.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.22.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.23.name`)}
            img = {img24}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.23.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.23.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.23.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.24.name`)}
            img = {img25}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.24.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.24.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.24.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.25.name`)}
            img = {img26}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.25.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.25.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.25.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.26.name`)}
            img = {img27}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.26.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.26.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.26.list.2')}}></li>
            </ul>}
          />
          {/*  Hair Transplant */}
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.27.name`)}
            img = {img28}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.27.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.27.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.27.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.28.name`)}
            img = {img29}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.28.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.28.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.28.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.29.name`)}
            img = {img30}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.29.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.29.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.29.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.30.name`)}
            img = {img31}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.30.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.30.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.30.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.31.name`)}
            img = {img32}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.31.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.31.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.31.list.2')}}></li>
            </ul>}
          />
        {/*Plastic Surgery */}
        <SubServicesComponent
            title = {t(`Service.navbar.0.text.32.name`)}
            img = {img33}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.32.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.32.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.32.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.33.name`)}
            img = {img34}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.33.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.33.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.33.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.34.name`)}
            img = {img35}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.34.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.34.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.34.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.35.name`)}
            img = {img36}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.35.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.35.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.35.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.36.name`)}
            img = {img37}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.36.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.36.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.36.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.37.name`)}
            img = {img38}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.37.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.37.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.37.list.2')}}></li>
            </ul>}
          />
          <SubServicesComponent
            title = {t(`Service.navbar.0.text.38.name`)}
            img = {img39}
            list = {<ul className="servicesInfoList">
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.38.list.0')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.38.list.1')}}></li>
              <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.38.list.2')}}></li>
            </ul>}
          />
          {/*  PLASTIC SURGERY */}
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.39.name`)}
          img = {img40}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.39.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.39.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.39.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.40.name`)}
          img = {img41}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.40.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.40.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.40.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.41.name`)}
          img = {img42}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.41.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.41.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.41.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.42.name`)}
          img = {img43}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.42.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.42.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.42.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.43.name`)}
          img = {img44}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.43.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.43.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.43.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.44.name`)}
          img = {img45}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.44.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.44.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.44.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.45.name`)}
          img = {img46}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.45.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.45.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.45.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.46.name`)}
          img = {img47}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.46.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.46.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.46.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.47.name`)}
          img = {img48}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.47.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.47.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.47.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.48.name`)}
          img = {img49}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.48.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.48.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.48.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.49.name`)}
          img = {img50}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.49.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.49.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.49.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.50.name`)}
          img = {img51}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.50.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.50.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.50.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.51.name`)}
          img = {img52}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.51.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.51.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.51.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.52.name`)}
          img = {img53}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.52.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.52.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.52.list.2')}}></li>
          </ul>}
          />
          <SubServicesComponent
          title = {t(`Service.navbar.0.text.53.name`)}
          img = {img54}
          list = {<ul className="servicesInfoList">
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.53.list.0')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.53.list.1')}}></li>
            <li dangerouslySetInnerHTML={{__html:t('Service.navbar.0.text.53.list.2')}}></li>
          </ul>}
          />
        </div>
      </div>
    </>
  );
}
