import React, { useEffect, useImperativeHandle, useState } from 'react';
import Tabel from '../../commons/TableComponent/TableComponent';
import { Button, IconButton, Paper } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { DeleteWarn, loading, removeLoading } from '../../commons/alerts';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

function SubServiceComponent(props,ref){
    const axiosPrivate = useAxiosPrivate();
    const [services,setServices]= useState([]);
    useImperativeHandle(ref,()=>{
        return {
            onChangeSubService(service){
                let index = services.findIndex((d)=> d.id == service.id);
                console.log('index',index);
                if(index >= 0){
                    services[index] = service;
                    setServices([...services]);
                }else{
                    setServices([...services,service]);
                }
            }, 
        }
    });
    
    const getServices=(id)=>{
        if(id){
            loading('');
            axiosPrivate.get('/services/sub/list/'+id)
            .then((res)=>{
                console.log(res.data.rows);
                
                setServices(res.data.rows);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{removeLoading()})
        }
    };
    const onSelectService=(service)=>{
        if(props.onSelectSubService){
            props.onSelectSubService(service);
        }
    }
    const onClickDelete=(e,service,ind)=>{
        e.stopPropagation();
        DeleteWarn('Hizmet Sil','Emin misiniz',()=>{
            loading('');
            axiosPrivate.delete('/services/sub/'+service.id)
            .then((res)=>{
                services.splice(ind,1);
                setServices([...services]);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{removeLoading()})
        })
    }
    
    useEffect(()=>{
        getServices(props.service?.id);
        return ()=>{};
    },[props.service])
    return(
        <Paper sx={{height:'100%'}}>
            <div style={{height:'90%'}}>
                <Tabel
                    data={services}
                    headers={[{id:'name',label:props.service?props.service.name:"Hizmetler",isSortable:true}]}
                    onClickRow={onSelectService}
                    isRightActions
                    rightActionsOptions = {(e,ind)=>{return <>
                        <IconButton aria-label="delete" color='error' onClick={(evt)=>onClickDelete(evt,e,ind)}>
                            <DeleteIcon fontSize='small'/>
                        </IconButton>
                    </>}}
                />
            </div>
            <div style={{height:'10%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Button 
                    variant="outlined" 
                    startIcon={<AddIcon />}
                    sx={{width:'80%'}}
                    onClick={()=> onSelectService({id:0,service_id:props.service?.id})}
                    disabled = {!props.service}
                >   
                    ALT HİZMET EKLE
                </Button>
            </div>
        </Paper>
    )
}

export default React.forwardRef(SubServiceComponent);