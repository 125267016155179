import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Events } from '../Component/Events/Events';

export function EventsRouter(){

    useEffect(()=>{
        return ()=>{};
    },[])

    return (
        <Route exact path = '/app/events'>
            <Events/>
        </Route>      
    )
}