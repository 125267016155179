import React, { useRef, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { ServiceComponent } from './components/ServiceComponent';
import SubServiceComponent from './components/SubServicesComponent';
import { SubServiceDetailComponent } from './components/SubServiceDetailComponent';
import './style.css';

export function Services(){
    const ref = useRef();
    const [selectedService,setSelectedService]=useState();
    const [selectedSubService,setSelectedSubService]=useState();
    const onSelectService=(service)=>{
        setSelectedService(service);
        setSelectedSubService();
    }
    const onSelectSubService=(subService)=>{
        setSelectedSubService(subService);
    }
    const onClickCancel=()=>{
        setSelectedSubService();
    }
    const onSave=(subService)=>{
        setSelectedSubService();
        console.log(subService)
        ref.current.onChangeSubService(subService);

    }
    return (
        <Container className='servicesContainer'>
            <Grid container spacing={1} sx={{padding:'0px',margin:'0px',height:'100%'}}>
                <Grid item xs={4} sx={{padding:'0px',margin:'0px',height:'100%'}}>
                    <ServiceComponent
                        onSelectService={onSelectService}
                    />
                </Grid>
                <Grid item xs={4} sx={{padding:'0px',margin:'0px',height:'100%'}}>
                    <SubServiceComponent
                        ref = {ref}
                        service = {selectedService}
                        onSelectSubService={onSelectSubService}
                    />
                </Grid>
                <Grid item xs={4} sx={{padding:'0px',margin:'0px',height:'100%'}}>
                    <SubServiceDetailComponent
                        subService={selectedSubService}
                        onClickCancel = {onClickCancel}
                        onSave={onSave}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}