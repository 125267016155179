import { Container } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import DragAndSropCalendar from '../commons/Calendar/DragAndSropCalendar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export function Events(){
    const axiosPrivate = useAxiosPrivate();
    const ref = useRef();
    const [events,setEvents] = useState([]);
    const getEvents=()=>{
        ref.current.loading();
        console.log(ref.current.getView());
        axiosPrivate.get('/events',{params:{
            view : ref.current.getView(),
            date : new Date()
        }})
        .then((res)=>{
            setEvents(res.data.events.map((d)=>{return{
                ...d,
                start : new Date(d.start),
                end   : new Date(d.end),
                blocked : true,
                color : d.service?.personel?.color,
                title : d.service?.subService?.name + " - " + d.service?.clinic?.name + " - " + d.service?.personel?.first_name +" "+d.service?.personel?.last_name
            }}));
        }).catch((error)=>{

        }).finally(()=>{ref.current.removeLoading();})
    };

    const changeDate=(date,view)=>{
        console.log(date,view);
    };

    useEffect(()=>{
        getEvents();
        return()=>{};
    },[]);
    return (
    <Container className='evetsContainer'>
        <DragAndSropCalendar
            events={events}
            hight = '100%'
            stopSelectSlot
            stopEdit
            changeFunc = {changeDate}
            ref = {ref}
        />
    </Container>
    )
}