
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import configureStore from './Redux/configureStore';
import { Provider } from 'react-redux';
import './i18next';
import { Suspense } from 'react';
import { LoadingLogo } from './layout/LoadingLogo/LoadingLogo';

const store = configureStore();

ReactDOM.render(
  <Suspense fallback={<></>}>
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>
  </Suspense>
,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
