import React, { useState } from "react";
import { TextInput } from "../../commons/InputGroupMui/TextInput";
import { CountryPickerInput } from "../../commons/InputGroupMui/CountryPickerInput";
import { SelectInput } from "../../commons/InputGroupMui/SelectInput";
import PhoneInput from "../../commons/InputGroupMui/PhoneInput";
import { EmailInput } from "../../commons/InputGroupMui/EmailInput";
import { DateInput } from "../../commons/InputGroupMui/DateInput";

export default function PatentInfo(props) {
  console.log(props, "formmm");
  return (
    <>
      <div className="row">
        <div className="col-6">
          <TextInput
            id="PatintInfoFormFirstName"
            label="Ad"
            onChange={(e) =>
              props.setForm({ ...props.form, first_name: e.target.value })
            }
            value={props.form.first_name}
            required
            fullWidth
            onch
          />
        </div>
        <div className="col-6">
          <TextInput
            id="PatintInfoFormLastName"
            label="Soyad"
            value={props.form.last_name}
            onChange={(e) =>
              props.setForm({ ...props.form, last_name: e.target.value })
            }
            required
            fullWidth
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <CountryPickerInput
            onChange={(e) => props.setForm({ ...props.form, nationality: e })}
            id="PatintInfoFormNationality"
            label="Uyruk"
            defaultValue={props.form.nationality}
          />
        </div>
        <div className="col-6">
          <DateInput
          fullWidth
            onChange={(e) =>
              props.setForm({ ...props.form, dob: e.target.value })
            }
            value={props.form.dob}
            id="PatintInfoFormDOB"
            label="Doğum Tarihi"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <SelectInput
            onChange={(e) =>
              props.setForm({ ...props.form, gender: e.target.value })
            }
            options = {[{ad:'Erkek',value:'MALE'},{ad:'Kadın',value:'FEMALE'},{ad :'Diğer',value:'OTHER'}].map((d)=>{return{label:d.ad,value:d.value}})}
            value={props.form.gender}
            id="PatintInfoFormGender"
            label="Cinsiyet"
            fullWidth
          />
        </div>
        <div className="col-6">
          <PhoneInput
            onChange={(e) => props.setForm({ ...props.form, tel_no: e })}
            defaultValue={props.form.tel_no}
            id="PatintInfoFormTelNo"
            label="Telefon Numarası"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <EmailInput
            onChange={(e) => props.setForm({ ...props.form, email: e.target.value })}
            value={props.form.email}
            id="PatintInfoFormEmail"
            label="Email"
            fullWidth
          />
        </div>
        <div className="col-6">
          <TextInput
            onChange={(e) => props.setForm({ ...props.form, national_id: e.target.value })}
            value={props.form.national_id}
            id="PatintInfoFormNationalID"
            label="Kimlik Numarası"
            fullWidth
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextInput
            onChange={(e) => props.setForm({ ...props.form, passport_id: e.target.value })}
            value={props.form.passport_id}
            id="PatintInfoFormPassPortID"
            label="Pasaport Numarası"
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
