import React , {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from './TableHead/TableHeadComponent';
import { Oval } from 'react-loader-spinner'
import { getComparator , stableSort } from './TableUtil/TableUtil';
import './TableComponent.css'

export default function Tabel(props){
    const { headers,            // hearders of the table 
            getTotalCount,      // tstableSorthe total number of records
            onChangedfilter,    // this function will be called when ever a filter was added or when the paging parametere were changed
                                // it is up to the user to update the total count of the records and update the rows accordingly
            isFilter,           // when true then filter will be added to the headers , the filter params will be sent to the onChaange function
            data,               //
            isRightActions,     //
            rightActionsOptions,//      
            selectedPage,       // 
            selectedpageSize,   // 
            rowsPerPageOptions, //
            onChangePage,       //
            onChangePageSize,   //
            isLeftActions,      //  
            leftActionsOptions, //
            isPagination,
            fontSize,
            filtersvalues,
            isLoading,
            id,
            isToptActions,      //  
            topActionsOptions, // 
            onClickRow,
            rightActionWidth    
        } = props;

    const [page, setPage] = useState(selectedPage?selectedPage:0);
    const [rowsPerPage, setRowsPerPage] = useState(selectedpageSize?selectedpageSize:Number.MAX_SAFE_INTEGER);
    const [filters, setFilters] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    
    const handleChangePage = (event, newPage) => {
        openLoading();
        setPage(newPage);
        //onChangedfilter(newPage,rowsPerPage,filters);
        if(onChangePage){
            onChangePage(newPage);
        }
    };
    
    const handleChangeRowsPerPage = (event) => {
        openLoading();
        setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
        //onChangedfilter(0,parseInt(event.target.value, 10),filters);
        if(onChangePageSize){
            onChangePageSize(parseInt(event.target.value));
        }
    };

    const onClickSearch=(filters) =>{
        openLoading();
        setFilters(filters)
        let filterJson = {...filters};
        onChangedfilter(page,rowsPerPage,filterJson);
    }  

    const openLoading = () =>{
        var rect = document.getElementById('tableContainer'+(id?id:'')).getBoundingClientRect();
        var rectHeader = document.getElementById('tableHeader'+(id?id:'')).getBoundingClientRect();
        //console.log(rect,rectHeader);
        console.log(id)
        console.log('loadingComponent'+(id?id:''))
        console.log(document.getElementById('loadingComponent'))
        // document.getElementById('loadingComponent'+(id?id:'')).style.display = 'flex';
        // document.getElementById('loadingComponent'+(id?id:'')).style.top = rectHeader.bottom-rectHeader.height+20+'px';
        // document.getElementById('loadingComponent'+(id?id:'')).style.bottom = rect.bottom + 'px';
        // document.getElementById('loadingComponent'+(id?id:'')).style.x = rect.x + 'px';
        // document.getElementById('loadingComponent'+(id?id:'')).style.y = rectHeader.y+ rectHeader.height+ 'px';
        // document.getElementById('loadingComponent'+(id?id:'')).style.width = rect.width + 'px';
        // document.getElementById('loadingComponent'+(id?id:'')).style.height = rect.height - rectHeader.height + 'px';

    };

    const closeLoading = () =>{
        document.getElementById('loadingComponent'+(id?id:'')).style.display = 'none';
    };
    
    const handleRequestSort = (event, property,header) => {
        console.log('handleRequestSort', event, property, header);
        const isAsc = orderBy.id === header.id && order === 'asc';
        console.log('isAsc', isAsc);
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header);
    };

    useEffect(() => {
        //closeLoading();   
    },[data]);

    useEffect(() => {
        if(isLoading){
            //openLoading();
        }
           
    },[isLoading]);

    return (
    <div style={{width:"100%",height:'100%',overflowX:'auto'}}>
        {isToptActions?
        <div className='topActionRow' style={{}} >
            {topActionsOptions(filters)}
        </div>
        :''}
        <Box style={{ width: '100%'}} id = {'tableContainer'+(id?id:'')}>
            <Paper style={{ width: '100%', mb:2,height:'100%'}}>
                    <Table
                        stickyHeader 
                        size={'small'}                                         
                    >
                    <TableHead
                        id = {'tableHeader'+(id?id:'')}
                        filter = {isFilter}
                        filtersvalues = {filtersvalues} 
                        headers = {headers}
                        onClickSearch = {(filters) => onClickSearch(filters)}
                        isRightActions = {isRightActions}
                        isLeftActions = {isLeftActions}
                        //isGeoJson 
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        fontSize = {fontSize}
                        rightActionWidth = {rightActionWidth}
                    />
                    {isLoading ? 
                        <TableRow className='tableRow'>
                            <TableCell 
                                
                                sx={{   
                                    display:'flex',
                                    justifyContent:'space-evenly',
                                    padding:'0px',
                                    fontSize:'25px',
                                    height:'100%',
                                    color:'blue',
                                    width:'100%'
                                }}
                            >
                                <div 
                                    id = {'loadingComponent'+(id?id:'')}
                                    style={{
                                        position: 'relative',
                                        top : '0px',
                                        bottom : "0px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor:'rgba(180,180,180,0.2)',
                                        width:'100%',
                                        height:'75px'
                                    }}
                                >
                                    <Oval
                                        height={55}
                                        width={55}
                                        color="#00aeff"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#00aeffa9"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    :
                        <TableBody >
                            {stableSort(data, getComparator(order, orderBy))
                            .slice(0, rowsPerPage)
                            .map((row,ind)=>{
                                return <TableRow key={row.name} className='tableRow' onClick={onClickRow?()=>onClickRow(row):()=>{}}>
                                    {isLeftActions?
                                        <TableCell 
                                            component="th" 
                                            scope="row"
                                            sx={{   display:'flex',
                                                    justifyContent:'space-evenly',
                                                    paddingLeft:'0px',
                                                    paddingRight:'0px',
                                                    fontSize:'25px',
                                                    height:'100%',
                                                    color:'blue',
                                                    width:'100%'
                                            }}
                                        >
                                            <div style={{display:'flex',
                                                        justifyContent:'space-evenly',
                                                        alignItems:'center',
                                                        width:'80%',
                                                        height:'100%'}}>
                                                {leftActionsOptions(row)}
                                            </div> 
                                    </TableCell>
                                    :''}
                                    {headers.map((header)=>{
                                        return <TableCell component="th" scope="row">
                                            <label 
                                                style={{fontSize:fontSize?fontSize:'',
                                                        whiteSpace:'nowrap',
                                                        overflow:'hidden',
                                                        textOverflow:'ellipsis'}}>
                                                {row[header.id]}
                                            </label>
                                        </TableCell>
                                    })}
                                    {isRightActions?
                                        <TableCell  component="th" 

                                                    sx={{   display:'flex',
                                                            justifyContent:'space-evenly',
                                                            paddingLeft:'0px',
                                                            paddingRight:'0px',
                                                            fontSize:'20px',
                                                            height:'100%',
                                                            color:'blue',
                                                            width:'100%'
                                                            }}
                                                    scope="row"> 
                                            <div style={{display:'flex',
                                                        justifyContent:'space-evenly',
                                                        alignItems:'center',
                                                        width:'80%',
                                                        height:'100%'}}>
                                                {rightActionsOptions(row,ind)}            
                                            </div>
                                            
                                        </TableCell>
                                    :""}
                                </TableRow>
                            })}
                        </TableBody>
                    }
                    {isPagination?
                    <TablePagination
                        labelRowsPerPage='satir '
                        sx={{
                            '.MuiTablePagination-toolbar': {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',  
                              backgroundColor: 'white',
                              width: '100%',
                              color: 'rgb(41, 39, 39)',
                              height: '50px',
                            },
                            '.MuiTablePagination-selectLabel':{
                                fontSize:'14px'  
                            }
                          }}
                        rowsPerPageOptions={rowsPerPageOptions?rowsPerPageOptions:[10, 25, 50]}
                        colSpan={headers.length+(isRightActions?1:0)+(isLeftActions?1:0)}
                        count={getTotalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        //ActionsComponent={TablePaginationActions}
                    />
                    :""}
                    </Table>
                
            </Paper>
        </Box>
        
    </div>)
}


TableHead.propTypes = {
    headers :PropTypes.arrayOf(PropTypes.shape({
             id: PropTypes.string.isRequired,
             numeric: PropTypes.bool,
             disablePadding: PropTypes.bool,
             label: PropTypes.string,
            })).isRequired,
        getTotalCount: PropTypes.number.isRequired        
};