import { alpha, createTheme, getContrastRatio } from "@mui/material/styles";
const violetBase = "#00ca88";
const darkColorBase = "#252525";
const whiteColorBase = "#ffffff";
const violetMain = alpha(violetBase, 0.7);
const darkMain = alpha(darkColorBase, 0.7);
const whiteMain = alpha(whiteColorBase, 0.7);

const theme = createTheme({
  palette: {
    test: {
      main: "#fff", // Change this to your desired color for the input
      light: "#222222",
      dark: "#222222",
      contrastText: "#fff",
    },
    logoColor: {
      main: violetMain,
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText:
        getContrastRatio(violetMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
    whiteColor: {
      main: whiteMain,
      light: alpha(whiteMain, 0.5),
      dark: alpha(whiteMain, 0.9),
      contrastText: getContrastRatio(whiteMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
    darkColor: {
      main: darkMain,
      light: alpha(darkMain, 0.5),
      dark: alpha(darkMain, 0.9),
      contrastText: getContrastRatio(darkMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
  },
  typography: {
    fontFamily: [
      "RobotoSlab-VariableFont_wght",
    ].join(","),
  },
});

export default theme;
