import React, { useState } from "react";
import "./LandingPage.css";
import { NavBar } from "./navBar/NavBar";
import { HomePage } from "./pages/homePage/HomePage";

import { AboutPage } from "./pages/aboutPage/AboutPage";

import { ServicesPage } from "./pages/servicesPage2/ServicesPage";
import { ClinicsPage } from "./pages/clinicsPage/ClinicsPage";


import { BlogsPage } from "./pages/blogsPage/BlogsPage";
import { ContactUsPage } from "./pages/contactUsPage/ContactUsPage";
import { SocialMediaBar } from "./socialMediaBar/SocialMediaBar";
import ClinicPage2 from "./pages/clinicsPage2/ClinicPage2";
import AboutPage2 from "./pages/aboutPage2/AboutPage2";

export function LandingPage(props) {

  return (
    <>
      <div className="landingPageBody">
        <NavBar lang={props.lang} detectedLang={props.detectedLang}/>
        <SocialMediaBar />  
        <HomePage />
        <AboutPage2 />
        <ServicesPage />
        <ClinicPage2 />
        {/* <BlogsPage /> */}
        <ContactUsPage />
      </div>
    </>
  );
}
