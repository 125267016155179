import React from "react";
import "./OurValues2.css";
import teeth from "../../../../../assets/teeth.jpg";
import i18next, { t } from "i18next";

export default function OurValues2() {
  return (
    <div className="ourValues2">
      <div className="content">
        <div className="header">
          <div className={i18next.language?"header-text-AR":"header-text"}>{t('OUR_VALUES.content.header')}</div>
        </div>
        <div className="body">
          <div className="body-inner">
            <img src={teeth} />
            <div className="body-innerone">
              <div className="innerone-circle">
                <div className="innerall">
                  <div className="head-text">
                    <div className="head-inner-text">{t('OUR_VALUES.content.body.body_header1')}</div>
                  </div>
                  <div style={{width:'60%',height:'1px',background:'var(--logo-green)',marginBottom:'10px',opacity:'0.4'}}></div>
                  <div className="body-text">
                    <div className="text" dangerouslySetInnerHTML={{__html:t('OUR_VALUES.content.body.text1')}}>
                    </div>
                  </div>
                </div>
              </div>
              <div className="innerone-circle">
                <div className="innerall">
                  <div className="head-text">
                    <div className="head-inner-text">{t('OUR_VALUES.content.body.body_header2')}</div>
                  </div>
                  <div style={{width:'60%',height:'1px',background:'var(--logo-green)',marginBottom:'10px',opacity:'0.4'}}></div>
                  <div className="body-text">
                    <div className="text" dangerouslySetInnerHTML={{__html:t('OUR_VALUES.content.body.text2')}}>
                    </div>
                  </div>
                </div>
              </div>
              <div className="innerone-circle">
                <div className="innerall">
                  <div className="head-text">
                    <div className="head-inner-text">{t('OUR_VALUES.content.body.body_header3')}</div>
                  </div>
                  <div style={{width:'60%',height:'1px',background:'var(--logo-green)',marginBottom:'10px',opacity:'0.4'}}></div>
                  <div className="body-text">
                    <div className="text" dangerouslySetInnerHTML={{__html:t('OUR_VALUES.content.body.text3')}}>
                    </div>
                  </div>
                </div>
              </div>
              <div className="innerone-circle">
                <div className="innerall">
                  <div className="head-text">
                    <div className="head-inner-text">{t('OUR_VALUES.content.body.body_header4')}</div>
                  </div>
                  <div style={{width:'60%',height:'1px',background:'var(--logo-green)',marginBottom:'10px',opacity:'0.4'}}></div>
                  <div className="body-text">
                    <div className="text" dangerouslySetInnerHTML={{__html:t('OUR_VALUES.content.body.text4')}}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
