import React, { useEffect, useImperativeHandle, useState } from "react";
import Calendar from "./Calendar";
import './style.css';
import { Oval } from "react-loader-spinner";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { DateInput } from "../InputGroupMui/DateInput";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { height } from "@mui/system";
const now = () => new Date();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const dayColors = [
    "#c6cbda",
    "#fee1e8",
    "#fed7c3",
    "#f6eac2",
    "#ecd5e3",
    "#ffd8be",
    "#eceae4"
  ];
let selectTimeout;
function DragAndSropCalendar({changeFunc,onClickFunc,events,setEvents,personelId,service_info_id,addEvent,removeEvent,editEvent,hight,stopSelectSlot},ref) {
    //const [events, setEvents] = useState([]);
    //console.log(events,'asdfsdfsdf')
    const axiosPrivate = useAxiosPrivate();
    const [loading,setLoading]=useState(false);
    const [date, setDate] = useState(now());
    const [view, setView] = useState("week");
    const [dialogOpen , setDialogOpen] = useState(false);
    const [selectedDate , setSelectedDate] = useState({});
    const [blockedEvents, setBlockedEvents] = useState([]);
    const [selectedEvent , setSelectedEvent] = useState(false);
    useImperativeHandle(ref,()=>{
      return {
        loading(){
          setLoading(true);
        },
        removeLoading(){
          setLoading(false);
        },
        getView(){
          return view;
        } 
      }
    });
    const padZero=(num)=> {
      return num.toString().padStart(2, '0');
    }
    const getFormatedDate = (date)=>{
      const originalDate = new Date(date);
      const offset = originalDate.getTimezoneOffset();
      originalDate.setHours(originalDate.getHours()-Math.floor(offset/60))
      return `${originalDate.getUTCFullYear()}-${padZero(originalDate.getUTCMonth() + 1)}-${padZero(originalDate.getUTCDate())}T${padZero(originalDate.getUTCHours())}:${padZero(originalDate.getUTCMinutes())}`;;
    }
    const getDateWithOffset=(dateStr)=>{
      let date = new Date(dateStr+':00.000Z');
      let offset = date.getTimezoneOffset();
      date.setHours(date.getHours()+Math.floor(offset/60))
      return date
    }

    const eventStyleGetter = (event) => {
      const backgroundColor = event.color || ''; // Default color if not specified
      const style = {
        backgroundColor,
        borderRadius: '5px',
        opacity: 1,
        color: 'white',
        //border: 'none',
        textAlign: 'center'
        // Add more styles as needed
      };
      return {
        style
      };
    };
    const onNavigate = (newDate) => { //today,back,next için çalışan fonksiyon 
        setDate(newDate); 
        console.log("sdfsdfsdfgs",newDate)
        changeFunc(newDate,view)
        const originalDate = new Date(newDate);

        const formattedDate = new Intl.DateTimeFormat('tr-TR',{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).format(originalDate);

        console.log(formattedDate);
    }
    
    const onView = (newView) => { //day,month,week için çalışan fonksiyon 
        setView(newView);
        console.log("newView",onView)
        // changeFunc(newView)
    }

    const accessors = {
        draggableAccessor: (event) => !event.blocked,
        resizableAccessor: (event) => !event.blocked
      };
    const dayPropGetter = (date) => { //
      //console.log('dayPropGette ', date, ' day ', date.getDay());
      return {
        style: {
         
          backgroundColor: dayColors[date.getDay()]
        }
      };
    };
    

    const onSelectSlot = ({ start, end, action }) => {
      if(!stopSelectSlot){
        selectTimeout && window.clearTimeout(selectTimeout);
        selectTimeout = setTimeout(() => {
          console.log("onSelectSlot 1: ", { start, end, action });
          setDialogOpen(true)
          setSelectedDate({
            startDate : getFormatedDate(start), 
            endDate : getFormatedDate(end)
          })
        }, 250);
      }
    };

    const onSelectEvent = (event) => {
      if(!event.blocked){
          selectTimeout && window.clearTimeout(selectTimeout);
          selectTimeout = setTimeout(() => {
            console.log("onSelectEvent: ", event);
            setSelectedEvent(event.id);
            setDialogOpen(true);
            setSelectedDate({
              startDate : getFormatedDate(event.start), 
              endDate : getFormatedDate(event.end)
            });
          }, 250);
      }
    };

    const onRemoveEvent=(id)=>{
      console.log(id)
      if(removeEvent){
        removeEvent(id);
      }
      // let ind = events.findIndex((d)=>d.id == id);
      // console.log(ind);
      // events.splice(ind,1)
      // setEvents([...events]);
      onCloseDialog()
    }

    const onDoubleClickEvent = (event) => {
        selectTimeout && window.clearTimeout(selectTimeout);
    
        selectTimeout = setTimeout(() => {
          console.log("onDoubleClickEvent: ", event);
        }, 250);
      };

      const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        let allDay = event.allDay;
        console.log("on moveevent 1: ", { start, end, event });
        if (!event.allDay && droppedOnAllDaySlot) {
          allDay = true;
        } else if (event.allDay && !droppedOnAllDaySlot) {
          allDay = false;
        }
    
        const updatedEvent = { ...event, start, end, allDay };
        editEvent(event.id,updatedEvent);
        //const filtered = events.filter((it) => it.id !== event.id);
        // setEvents([...filtered, { 
          
        //   ...updatedEvent
        // }])
        
        // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
      };

      const resizeEvent = ({ event, start, end }) => {
        if(!event.blocked){
          editEvent(event.id,{start , end})
          }
      };

      const onKeyPressEvent = ({ event, ...other }) => {
        console.log("[onKeyPressEvent] - event", event);
        console.log("[onKeyPressEvent] - other", other);
      };

      const onSelecting = (range) => {
        console.log("[onSelecting] range: ", range);
      };


    const onClickEtkinlik  = async (data) => {
        console.log(data.id)//plan id
        onClickFunc(data.id);
        setSelectedEvent(false);
        setDialogOpen(true);
        setSelectedDate({
        //  startDate : getFormatedDate(start), 
        //  endDate : getFormatedDate(end)
        });
    }
    
    const handleSubmit=(id)=>{
        console.log(selectedDate)
        if(id){
          editEvent(id,{
            start : getDateWithOffset(selectedDate.startDate),
            end : getDateWithOffset(selectedDate.endDate),
          })
          // let ind = events.findIndex((d)=> d.id == id);
          // events[ind].start = getDateWithOffset(selectedDate.startDate);
          // events[ind].end   = getDateWithOffset(selectedDate.endDate);
          // setEvents([...events]);
        }else{
          addEvent({
            //title:'asd',
            id : new Date().getTime(),
            start:getDateWithOffset(selectedDate.startDate),
            end :getDateWithOffset(selectedDate.endDate)
          })
          // setEvents([...events,{
          //   title:'asd',
          //   id : new Date().getTime(),
          //   start:getDateWithOffset(selectedDate.startDate),
          //   end :getDateWithOffset(selectedDate.endDate)
          // }])  
        }
        onCloseDialog();
    }
    const onCloseDialog=()=>{
        setDialogOpen(false);
        setSelectedDate({});
        setSelectedEvent(false);
    };
    const getPersonelEventes=()=>{
      if(personelId){
        setLoading(true);
        axiosPrivate.get('/user/events/by/user/'+personelId,{params:{
          st : date,
          period: view,
          service_info_id
        }})
        .then((res)=>{
          setBlockedEvents(res.data.events.map((d)=>{return {
            ...d,
            start: new Date(d.start),
            end : new Date(d.end),
            blocked : true,
            color : '#dc3545'
          }}));
        }).catch((err)=>{

        }).finally(()=>{setLoading(false)})
      }
    };
    useEffect(()=>{
      getPersonelEventes();
      return ()=>{};
    },[personelId,view,date]);
    return(<>
        <div className="calenderBackgroun" style={{ height: hight?hight:"60vh" ,position:'relative',display:'flex',justifyContent:'center',alignItems:'center'}}>
            {loading &&
            <div style={{width:'calc(100% - 8px)',height:'calc(100% - 8px)' , position:'absolute',top:'4px',zIndex:'2',display:'flex',justifyContent:'center',alignItems:'center',background:'#eeeeee66'}}>
                <Oval
                    height={85}
                    width={85}
                    color="#00aeff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#00aeffa9"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            }
            <div style={{width:'calc(100% - 8px)',height:'calc(100% - 8px)' , position:'absolute',top:'4px'}}>
                <Calendar
                    {...{
                    events:[...events,...blockedEvents],
                    date,
                    onNavigate,
                    view,
                    onView,
                    onSelectSlot,
                    onSelectEvent,
                    onSelecting,
                    onDoubleClickEvent,
                    onKeyPressEvent,
                    dayPropGetter
                    }}
                    eventPropGetter = {eventStyleGetter}
                    onEventDrop={moveEvent}
                    onEventResize={resizeEvent}
                    getNow={now}
                    //onSelectEvent={(e)=> onClickEtkinlik(e)}
                    {...accessors}
                    selectable
                />
            </div>
        </div>
        <Dialog
            open={dialogOpen}
            //TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth={'xs'}
            onClose={()=> onCloseDialog()}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Seans Ekle"}</DialogTitle>
            <DialogContent>
                <DateInput
                    fullWidth
                    label = 'başlangıç Tarih'
                    type = 'datetime-local'
                    value = {selectedDate?.startDate}
                    onChange={(e)=> setSelectedDate({...selectedDate,startDate:e.target.value})}
                />
                <DateInput
                    fullWidth
                    label = 'Bitiş Tarih'
                    type = 'datetime-local'
                    value = {selectedDate?.endDate}
                    onChange={(e)=> setSelectedDate({...selectedDate,endDate:e.target.value})}
                />
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {selectedEvent && 
                      <Button color='error' onClick={()=> onRemoveEvent(selectedEvent)}>Sil</Button>
                  }
                </div>
                <div>
                  <Button color='error' onClick={()=> onCloseDialog()}>Iptal</Button>
                  <Button onClick={()=>handleSubmit(selectedEvent)}>Tamam</Button>
                </div>
            </DialogActions>
        </Dialog>
    </>);
}

export default React.forwardRef(DragAndSropCalendar);