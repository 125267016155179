import React, { useState } from "react";
import "./AboutPage2.css";
import AboutShereenCare2 from "./AboutComponent/AboutShereenCare2";
import OurValues2 from "./AboutComponent/OurValues2";
import OurServices2 from "./AboutComponent/OurServices2";
import WhoWeServed2 from "./AboutComponent/WhoWeServed2";
import { t } from "i18next";

export default function AboutPage2() {
  const [page, setPage] = useState(0);
  const [activeButton, setActiveButton] = useState(0);

  const maxPage = 3;
  const next = () => {
    setPage((d) => (d + 1) % (maxPage + 1));
    setActiveButton((d) => (d + 1) % (maxPage + 1));
  };
  const previos = () => {
    setPage((d) => (d - 1 + maxPage + 1) % (maxPage + 1));
    setActiveButton((d) => (d - 1 + maxPage + 1) % (maxPage + 1));
  };

  const handleButtonClick = (ind) => {
    setPage(ind);
    setActiveButton(ind);
  };
  return (
    <div className="aboutComponent" id="AboutPageBody">
      <div className="content">
        <div className="header">
          <div className="header-button">
            <button
              className={` ${activeButton === 0 ? "activeButton" : ""}`}
              type="button"
              onClick={() => handleButtonClick(0)}
            >
              {t('ABOUT_SHEREEN_CARE.header')}
            </button>
            <button
              className={` ${activeButton === 1 ? "activeButton" : ""}`}
              type="button"
              onClick={() => handleButtonClick(1)}
            >
            {t('OUR_VALUES.header')}
            </button>
            <button
              className={` ${activeButton === 2 ? "activeButton" : ""}`}
              type="button"
              onClick={() => handleButtonClick(2)}
            >
              {t('OUR_GOALS.header')}
            </button>
            <button
              className={` ${activeButton === 3 ? "activeButton" : ""}`}
              type="button"
              onClick={() => handleButtonClick(3)}
            >
            {t('WHO_WE_SERVED.header')}
            </button>
          </div>
        </div>
        <div className="body">
          <div className="body-inner">
            {page == 0 && <AboutShereenCare2 />}
            {page == 1 && <OurValues2 />}
            {page == 2 && <OurServices2 />}
            {page == 3 && <WhoWeServed2 />}
          </div>
        </div>
      </div>
    </div>
  );
}
