import React, { useEffect , useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { confirm, loading, removeLoading, success, warning } from '../../commons/alerts';
import { useHistory, useParams } from 'react-router-dom';
import CardView2 from '../../commons/CardView2';
import { PatintBrief } from './PatintBrief';
import { ServiceTable } from './ServiceTable';
import { SelectInput } from '../../commons/InputGroupMui/SelectInput';
import { TextInput } from '../../commons/InputGroupMui/TextInput';
import SlideInComponent from '../../commons/SlideInComponent';
import { PatintInfoForm } from './PatintInfoForm';
import { TextAreaInput } from '../../commons/InputGroup/TextAreaInput';
import { PatentDocument } from '../../commons/PatentDocument';
import { ReferenceInfo } from './ReferenceInfo';
import { failed } from '../../../utils/alerts';
import DragAndSropCalendar from '../../commons/Calendar/DragAndSropCalendar';
import { InputAdornment } from '@mui/material';
import { EventTable } from './EventsTable';


export function FirsetProcess(props){
    const axiosPrivate = useAxiosPrivate();
    const history = useHistory();
    const id = useParams().id ;
    const [data,setData] = useState();

    const [services,setServices] = useState([]);
    const [subServices,setsubServices] = useState([]);
    const [isSlideInOpen,setIsSlideInOpen] = useState(false);
    const [isEdit,setIsEdit] = useState();
    const [selecetedEditRow , setSelecetedEditRow] = useState(null);

    const [patent,setPatent] = useState();
    const [notes ,setNotes] = useState([]);
    const [patientDocuments , setPatientDocuments] = useState([]);
    const [referenceInfo , setReferenceInfo] = useState();

    const [clinics  ,setClicnics ] = useState([]);
    const [personel ,setPersonel ] = useState([]);
    const [currentServis ,setCurrentServise] = useState();
    const [selectedServices,setSelectedServices] = useState([]);
    


    const onCloseSlideIn=(e)=>{
        setIsSlideInOpen(false);
    }

    const onClickAddService=()=>{
        if(!currentServis?.service_id){
            failed('Bir Servis Seçiniz');
            return;
        }else if(!currentServis?.sub_service_id){
            failed("Alt servıs seçiniz");
            return;
        }
        let json = {
            ...currentServis,
            clinic : clinics.find((i)=> i.id== currentServis.clinic_id),
            personel : personel.find((i)=> i.id == currentServis.personel_id),
            service : services.find((i)=> i.value == currentServis.service_id),
            subService : subServices.find((i)=> i.value == currentServis?.sub_service_id),
            currencySimbol : currentServis.currency == 1 ? 'TL' : currentServis.currency == 2 ? '$' : '',
            id             : new Date().getTime()
        }
        console.log(json)
        //selectedServices.push(currentServis);
        setSelectedServices([...selectedServices,json]);
        setCurrentServise({});
        return 
        // let json = {
            
        //     service : services.find((i)=> i.id == document.getElementById('propcess_service_select').value),
        //     subService : subServices.find((i)=> i.id == document.getElementById('propcess_sub_service_select').value),
        //     quantity : document.getElementById('quantity').value,
        //     ppu : document.getElementById('ppu').value,
        //     discount : document.getElementById('discount').value
        // }
        for(var i in json){
            if(json[i] == '' || json[i] == undefined){return;}
        }
        setSelectedServices([...selectedServices,json])
        resetInputs();
    }

    const putInProcess=async()=>{
        loading();
        await axiosPrivate.put('/requests/new/to/process/'+id)
        .then((res)=>{
            console.log(res.data);
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
            history.goBack();
            failed('Request is taken by another user')
        }).finally(()=>{removeLoading()})
    }

    const outOfProcess= async()=>{
        await axiosPrivate.put('/requests/new/out/process/'+id)
        .then((res)=>{
            console.log(res.data);
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
            history.goBack();
            failed('')
        }).finally(()=>{})
    }

    const getRequestData=async()=>{
        await axiosPrivate.get('/requests/id/'+id)
        .then((res)=>{
            console.log(res.data);
            setData(res.data.obj)
            setReferenceInfo({...res.data.obj.reference,reference_start_time:res.data.obj.date});
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
            //history.goBack();
            failed('')
        }).finally(()=>{removeLoading()})
    }

    const getServices=async()=>{
        await axiosPrivate.get('/services/list')
        .then((res)=>{
            setServices(res.data.rows.map((row)=> {return {id:row.id,value:row.id,label:row.name}}));
            console.log(res.data.rows)
            
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    const onChangeService=async(e)=>{
        await axiosPrivate.get('/services/sub/list/'+e)
        .then((res)=>{
            setsubServices(res.data.rows.map((row)=>{return {...row,value:row.id,label:row.name}}));
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    
    };
    const onChangeSubService=async(e)=>{
        let _subService = subServices.find((d)=> d.id == e.target.value);
        setCurrentServise({
            ...currentServis,
            sub_service_id:_subService.id,
            currency : _subService.default_currency,
            ppu      : _subService.default_currency==1?_subService.cost_tl : _subService.default_currency==2?_subService.cost_dollar : undefined,
            discount : _subService.discount,
            quantity : 1
        })
    };
    const onChangeClinic=(id)=>{
        axiosPrivate.get('/clinics/staff/list/'+id)
        .then((res)=>{
            setPersonel(res.data.rows.map((row)=>{return {id:row.id,value:row.id,label:row.first_name+' '+row.last_name }}));
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    }

    const getRequestProcessData=async()=>{
        loading();
        await axiosPrivate.get('/requests/inprocess/id/'+id)
        .then((res)=>{
            console.log(res.data);
            setPatent(res.data.request_process.patient);
            setData(res.data.request_process.patient)
            setNotes(res.data.request_process.notes_info);
            setSelectedServices(res.data.request_process.subServices.map((d)=>{
                return {
                    events:d.events.map((e)=>{return {
                        start : new Date(e.start),
                        end   : new Date(e.end),
                        title : d.name,
                        id : e.id
                    }}),
                    subService : {
                        id : d.id,
                        value : d.id,
                        label : d.name
                    },
                    service :{
                        id : d.service.id,
                        value : d.service.id,
                        label : d.service.name
                    },
                    clinic :{
                        id : d.clinic.id,
                        value : d.clinic.id,
                        label : d.clinic.name
                    },
                    personel :{
                        id : d.personel.id,
                        value : d.personel.id,
                        label : d.personel.name
                    },
                    service_id : d.service.id,
                    sub_service_id : d.id,
                    clinic_id : d.clinic.id,
                    personel_id : d.personel.id,
                    id       : d.request_process_info_services.id,
                    quantity : d.request_process_info_services.quantity ,
                    ppu : d.request_process_info_services.ppu,
                    discount : d.request_process_info_services.discount,
                    currency : d.request_process_info_services.currency,
                    currencySimbol : d.request_process_info_services.currency == 1 ? 'TL' : d.request_process_info_services.currency == 2 ? '$' : ''
                }
            }))
            
            //setData(res.data.obj)
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
            //history.goBack();
            failed('')
        }).finally(()=>{removeLoading()})
    };

    const getClicnics=()=>{
        axiosPrivate.get('/clinics/list')
        .then((res)=>{
            setClicnics(res.data.rows.map((row)=> {return {id:row.id,value:row.id,label:row.name}}));
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    }

    const getData=()=>{
        if(props.inProcess){
            getRequestProcessData();
        }else{
            getRequestData();
        }
        getServices();
        getClicnics();   
    }

    const onClickDeleteService=(ind)=>{
        selectedServices.splice(ind,1);
        setSelectedServices([...selectedServices]);
    };

    const resetInputs = ()=>{
        document.getElementById('propcess_service_select').value = '';
        document.getElementById('propcess_sub_service_select').value = '';
        document.getElementById('quantity').value = '1';
        document.getElementById('ppu').value = '0';
        document.getElementById('discount').value = '0';
    }

    const onClickEdit= async(ind)=>{
        console.log(selectedServices[ind])
        setIsEdit(true);
        setSelecetedEditRow(ind);
        setCurrentServise(selectedServices[ind])
        //loading();
        await onChangeService(selectedServices[ind]?.service?.id);
        await onChangeClinic(selectedServices[ind]?.clinic?.id);
        //removeLoading();
          
    };

    const onClickEditCancel=()=>{
        setIsEdit(false);
        setSelecetedEditRow(null);
        setCurrentServise({});
    }

    const onClickEditSubmit= () => {
        let ind = selecetedEditRow
        selectedServices[ind] = {
            ...currentServis,
            clinic : clinics.find((i)=> i.id== currentServis.clinic_id),
            personel : personel.find((i)=> i.id == currentServis.personel_id),
            service : services.find((i)=> i.value == currentServis.service_id),
            subService : subServices.find((i)=> i.value == currentServis?.sub_service_id),
            currencySimbol : currentServis.currency == 1 ? 'TL' : currentServis.currency == 2 ? '$' : ''
        }
        setSelectedServices([...selectedServices]);
        onClickEditCancel();
    }

    const onSavePatent=(e)=>{
        console.log(e)
        setIsSlideInOpen(false);
        setPatent(e);
        setData({
            ...data ,
            ...e
        })
        if(e.id && referenceInfo){
            warning('The Selected Patient already existed the refereance info will be deleted');
            axiosPrivate.get('/patient/reference/'+props.id)
            .then((res)=>{
                setReferenceInfo(res.data.reference);
            }).catch((err)=>{

            }).finally(()=>{});
        }
    };

    const onClickSave=()=>{
        let json = {
            request_id : props.inProcess?0 : id ,
            request_process_id : props.inProcess?id : 0 ,
            patient:patent,
            services : selectedServices,
            notes : [...notes , {
                note :document.getElementById('newNote').value,
            }],
            referenceInfo : referenceInfo
        }
        console.log(json);
        
        if(!patent){
            warning('Hasta Bilgilerini Doldurunuz!')
            return ;
        }
        confirm("Bilgiler Kaydedilecek!",'Emin misiniz?',async()=>{
            //console.log("ok");
            loading();
            await axiosPrivate.post('/requests/save',json)
            .then((res)=>{
                console.log(res);
                success(res.data.info.msg);
                if(res.data.info.patient_id && patientDocuments.length > 0){
                    let formdata = new FormData();
                    for(let i = 0 ; i < patientDocuments.length ; i++){
                        formdata.append('files',patientDocuments[i]);
                    }
                    formdata.append('patient_id',res.data.info.patient_id);
                    axiosPrivate.post('/document/to/patient',formdata,{headers: {'Content-Type': 'multipart/form-data'}})
                    .then((res)=>{
                        res.data.notifications.forEach(n => {
                            if(n.status){
                                success(n.msg);
                            }else{
                                warning(n.msg);
                            }
                        });
                    }).catch((err)=>{
                        console.log(err);
                        warning('Error occured while uploading documents');
                    }).finally(()=>{
                        history.goBack();
                    })
                }else{
                    history.goBack();
                }
                //setsubServices(res.data.rows);
                //setSideBarItem(res.data.rows);
            }).catch((err)=>{
                console.log(err);
            }).finally(()=>{
                removeLoading();
            })
        })   
    }

    const onAddEvent=(event)=>{
        if(currentServis.events){
            currentServis.events.push({
                ...event,
                title : 'new'
            })
        }else{
            currentServis.events = [{
                ...event,
                title : 'new'
            }]
        }
        setCurrentServise({...currentServis});
    }

    const onRemoveEvent=(id)=>{
        let ind = currentServis.events?.findIndex((d)=> d.id == id);
        currentServis.events.splice(ind,1);
        setCurrentServise({...currentServis});
    }

    const onEditEvent=(id,event)=>{
        let ind = currentServis.events?.findIndex((d)=> d.id == id);
        console.log(ind)
        if(ind>=0){
            currentServis.events[ind]={
                ...currentServis.events[ind],
                ...event
            };
            setCurrentServise({...currentServis});
        }
    }

    useEffect(()=>{
        //putInProcess();
        console.log('asdasasd')
        getData()
        return async()=>{
            
        //    outOfProcess();
        }
    },[]);
    return(<>
        <div style={{width:'100%',height:'calc(100% - 60px)',overflowY:'auto',overflowX:'hidden',padding:'5px'}}>
            <div className='row'>
                <div className='col-12'>
                    <CardView2 title='Hasta Bilgileri' isIcon onClickIcon={()=> {setIsSlideInOpen(true);console.log('asfddsf')}}>
                        <PatintBrief defultValue = {data}/>
                    </CardView2>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{marginTop:'5px'}}>
                    <CardView2 title='İstek Bilgileri'>
                        <div className='row'>
                            <div className='col-4'>
                                <SelectInput
                                    id = 'propcess_service_select'
                                    label = 'Servis*' 
                                    options = {services}
                                    value = {currentServis?.service_id}
                                    onChange = {(e)=> {onChangeService(e.target.value);setCurrentServise({...currentServis,service_id:e.target.value,sub_service_id:null})}}
                                />
                            </div>
                            <div className='col-4'>
                                <SelectInput
                                    id = 'propcess_sub_service_select'
                                    label = 'Alt Servis*' 
                                    options = {subServices}
                                    value = {currentServis?.sub_service_id}
                                    onChange = {(e)=> onChangeSubService(e)}
                                
                                />
                            </div>
                            <div className='col-2'></div>
                            
                        </div>
                        <div className='row '></div>
                        <div className='row mt-6'>
                            <div className='col-4'>
                                <SelectInput
                                    id = 'clinic'
                                    label = 'CLINIC' 
                                    options = {clinics}
                                    //onChange = {(e)=> onChangeService(e.target.value)}
                                    value = {currentServis?.clinic_id}
                                    onChange = {(e)=> {onChangeClinic(e.target.value);setCurrentServise({...currentServis,clinic_id:e.target.value,personel_id:null})}}
                                />
                            </div>
                            <div className='col-4'>
                                <SelectInput
                                    id = 'personel'
                                    label = 'personel' 
                                    options = {personel}
                                    value = {currentServis?.personel_id}
                                    onChange = {(e)=> setCurrentServise({...currentServis,personel_id:e.target.value})}
                                />
                            </div>
                            <div className='col-2'/>
                            <div className='col-2'>
                                <TextInput
                                    id = 'seans '
                                    label = 'TOPLAM SEANS SAYISI'
                                    fullWidth
                                    value = {currentServis?.events?.length || '0'}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='row mt-6'>
                            <div className='col-2'>
                                <TextInput
                                    id = 'quantity'
                                    label = 'Adet' 
                                    fullWidth
                                    value = {currentServis?.quantity}
                                    onChange = {(e)=> setCurrentServise({...currentServis,quantity:e.target.value})}
                                />
                            </div>
                            <div className='col-3'>
                                <TextInput
                                    id = 'ppu'
                                    label = 'Birim Fiyatı' 
                                    fullWidth
                                    value = {currentServis?.ppu}
                                    onChange = {(e)=> setCurrentServise({...currentServis,ppu:e.target.value})}
                                />
                            </div>
                            <div className='col-2'>
                                <SelectInput
                                    id = 'currency'
                                    label = 'Para Birimi'
                                    options = {[{label:'TL',value:'1'},{label:'DOLAR',value:'2'}]} 
                                    fullWidth
                                    value = {currentServis?.currency}
                                    onChange = {(e)=> setCurrentServise({...currentServis,currency:e.target.value})}
                                />
                            </div>
                            <div className='col-2'>
                                <TextInput
                                    id = 'discount'
                                    label = 'İndirim' 
                                    fullWidth
                                    value = {currentServis?.discount}
                                    onChange = {(e)=> setCurrentServise({...currentServis,discount:e.target.value})}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className='col-1'></div>
                            <div className='col-2'>
                                <TextInput
                                    id = 'total'
                                    label = 'TOPLAM'
                                    fullWidth
                                    value = {(parseInt(currentServis?.quantity)*parseFloat(currentServis?.ppu) * ((100 - (parseFloat(currentServis?.discount)))/100) )}
                                    disabled
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{currentServis?.currency==1?'TL':'$'}</InputAdornment>,
                                    }}
                                    //onChange = {(e)=> setCurrentServise({...currentServis,total:e.target.value})}
                                />
                            </div> 
                        </div>
                            {currentServis?.personel_id &&<div className='col-12'>
                                <div className='row'>
                                    {/* <div className='col-2'  >
                                        <EventTable
                                            events = {currentServis?.events}
                                        />
                                    </div> */}
                                    <div className='col-12' style={{marginTop:'8px'}}>
                                        <DragAndSropCalendar
                                            personelId = {currentServis?.personel_id}
                                            events = {
                                                (currentServis?.events || [])
                                                .concat(selectedServices.filter((d)=> d.id != currentServis.id)
                                                        .reduce((a,u)=> a.concat(u.events?.map((e)=>{return{...e,color:'green',blocked:true}})) ,[]))
                                            }
                                            //setEvents={(e)=> setCurrentServise({...currentServis,events:e})}
                                            addEvent={(event)=> onAddEvent(event)}
                                            removeEvent = {(id)=> onRemoveEvent(id)}
                                            editEvent = {(id , event)=> onEditEvent(id,event)}
                                            changeFunc = {(d,v) => console.log(d,v)}
                                            onClickFunc = {()=>{}}
                                            service_info_id = {id}
                                            //loading
                                        />
                                    </div>
                                </div>
                            </div>}
                        <div className='row mt-6'>
                            {isEdit?<>
                                <div className='col-8'></div>
                                <div className='col-2'>
                                    <button 
                                        type="button" 
                                        style={{width:'100%'}} 
                                        class="btn btn-danger"
                                        onClick={()=> onClickEditCancel()}
                                    >   
                                        İPTAL
                                    </button>
                                </div>
                                <div className='col-2'>
                                    <button 
                                        type="button" 
                                        style={{width:'100%'}} 
                                        class="btn btn-success"
                                        onClick={()=> onClickEditSubmit()}
                                    >   
                                        DÜZENLE
                                    </button>
                                </div>    
                            </>:<>
                                <div className='col-8'></div>
                                <div className='col-4'>
                                    <button 
                                        type="button" 
                                        style={{width:'100%'}} 
                                        class="btn btn-success"
                                        onClick={()=> onClickAddService()}
                                    >   
                                        EKLE
                                    </button>
                                </div>
                            </>}   
                        </div>
                        <div className='row mt-6'>
                            <div className='col-12'>
                                <ServiceTable 
                                    list={selectedServices}
                                    selectedRow = {selecetedEditRow}
                                    onClickDelete = {(ind)=> onClickDeleteService(ind)}
                                    onClickEdit   = {(ind)=> onClickEdit(ind)}
                                />
                            </div>
                        </div>
                    </CardView2>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{marginTop:'5px'}}>
                    <CardView2 title='İSTEK GEÇMİŞİ' >
                        {notes.map((d)=>{
                            return <TextAreaInput 
                                id = {'process_notes-'+d.id} 
                                label='notes' 
                                lwidth='100px' 
                                col={12} 
                                disabled 
                                value={d.notes}
                                info = {d.created_at}
                            />
                        })}
                        <TextAreaInput id = 'newNote' label='notlar' lwidth='100px' col={12}></TextAreaInput>
                        
                    </CardView2>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{marginTop:'5px'}}>
                    <CardView2 title='HASTA BELGELERİ' >
                        <PatentDocument
                           id = {patent?.id}
                           patientDocuments = {patientDocuments}
                           setPatientDocuments = {setPatientDocuments}
                        />
                    </CardView2>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{marginTop:'5px'}}>
                    <CardView2 title='REFERANS BİLGİLERİ' >
                        <ReferenceInfo
                           id = {patent?.id}
                           data = {referenceInfo}
                        />
                    </CardView2>
                </div>
            </div>
        </div>
        <div style={{width:'100%',height:'60px',display:'flex',justifyContent:'end',alignItems:'center',paddingInline:'20px',borderTop:'1px #ddd solid'}}>
            <button type="button" class="btn btn-danger" style={{width:'150px',marginRight:'20px'}}>İPTAL</button>
            <button type="button" class="btn btn-success" style={{width:'150px'}} onClick={()=> onClickSave()}>KAYDET</button>
        </div>
        <SlideInComponent 
            title = 'HASTA BİLGİSİ'
            isOpen = {isSlideInOpen} 
            onClose={(e)=> onCloseSlideIn(e)}
            width = '45%'
        >
            <PatintInfoForm
                id = {patent?.id}
                data = {data}
                onSavePatent = {(e)=> onSavePatent(e)}
                onClose = {(e)=> onCloseSlideIn(e)}
            />
        </SlideInComponent>
    </>)
}