import React from 'react'
import './blogPost.css'

function BlogPost() {
    return (
        <>
            <div className="blogpost">
                <img className='blogPostImg' src="https://img.freepik.com/free-photo/woman-doctor-wearing-lab-coat-with-stethoscope-isolated_1303-29791.jpg?w=900&t=st=1702045194~exp=1702045794~hmac=dd343c62d0b814a87f9478fc7a8f0ad448304305149b76b43816d14a95b9af3b" alt="" />

                <div className="blogPostInfo">
                    <div className="blogPostCategories">
                        <span className="blogpostCategory">BEAUTY SERVICES</span>
                        <span className="blogpostCategory">DENTAL CARE</span>
                    </div>
                    <span className="blogPostTitle">Lorem ipsum dolor sit amet.</span>
                    <hr />
                    <span className="blogPostDate"> 1 hour ago</span>
                    <p className="blogPostDescription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus amet nihil blanditiis ut repellat tempora quidem animi, fugit minima odit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus amet nihil blanditiis ut repellat tempora quidem animi, fugit minima odit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus amet nihil blanditiis ut repellat tempora quidem animi, fugit minima odit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus amet nihil blanditiis ut repellat tempora quidem animi, fugit minima odit? Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus amet nihil blanditiis ut repellat tempora quidem animi, fugit minima odit?</p>
                </div>
            </div>
        </>
    )
}

export default BlogPost