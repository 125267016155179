import React, { useEffect } from 'react';
import Tabel from '../../commons/TableComponent/TableComponent';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useState } from 'react';
import PopupComponent from '../../commons/PopupComponent';
import { RequestPeeker } from '../forms/RequestPeeker';
import { AddRequestForm } from '../forms/AddRequestForm';


export function NewRequests(){
    const axiosPrivate = useAxiosPrivate();
    const [totalCount , setTotalCount]           = useState(0);
    const [tempFilters,setTempFilters]           = useState({});
    const [isLoading , setIsLoading]             = useState(false);
    const [selectedPage,setSelectedPage]         = useState(0);
    const [selectedPageSize,setSelectedPageSize] = useState(25);
    const [data,setData]                         = useState([]);
    const [selectedRequest , setSelectedRequest] = useState(null);
    const [popup,setPopup]                       = useState(false);
    const [addPopup,setAddPopup]                 = useState(false);
    
    const headers = [
        {id : 'first_name',numeric:false,disablepadding:false,label:'Ad',isSortable:true},
        {id : 'last_name',numeric:false,disablepadding:false,label:'Soyad',isSortable:true},
        {id : 'tel_no',numeric:false,disablepadding:false,label:'Telefon',isSortable:false},
        {id : 'country',numeric:false,disablepadding:false,label:'Uyruk',isSortable:false},
        {id : 'date',numeric:false,disablepadding:false,label:'Tarih',isSortable:true,isDate:true,pattern:'yyyy-MM-dd'},   
    ];

    const getNewRequestsCount= async (filters)=>{
        await axiosPrivate.post('/requests/new/count',filters)
        .then((res)=>{
            setTotalCount(res.data.count);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    const onChangePage=async(e)=>{
        setSelectedPage(e);
        await getRequestList(e,selectedPageSize,tempFilters);
    };

    const onChangePageSize=async(e)=>{
        setSelectedPageSize(e);
        await getRequestList(selectedPage,e,tempFilters);
    };

    const onChangeFilter=async(page,rowPerPage,filter)=>{
        setTempFilters(filter);
        await getNewRequestsCount(filter);
        await getRequestList(selectedPage,selectedPageSize,filter);
    }
    const getRequestList= async (pageNum,pageSize,filters)=>{
        await axiosPrivate.post('/requests/new/'+pageNum+'/'+pageSize,filters)
        .then((res)=>{
            setData(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    }
    const getData= async()=>{
        await getNewRequestsCount(tempFilters);
        await getRequestList(selectedPage,selectedPageSize,tempFilters);
    }

    const onClickPick=(e,ind)=>{
        setSelectedRequest(e);
        setPopup(true);
    };

    const onClosePopup=(d)=>{
        setSelectedRequest(null);
        setPopup(false);
        if(d){
            getData();
        }
    };

    const onCloseAddPopup=(d)=>{
        setPopup(false);
        if(d){
            getData();
        }
    };

    const onClickAddRequest=()=>{
        setAddPopup(true);
        setSelectedRequest(null);
        setPopup(true);
    }
    useEffect(()=>{
        getData();
        return ()=>{};
    },[])

    return (<>
        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{width:'95%',height:'95%'}}>
                <Tabel
                    headers = {headers}
                    data    = {data}
                    getTotalCount = {totalCount}
                    isFilter 
                    filtersvalues = {tempFilters}
                    onChangedfilter = {(page,rowPerPage,filter)=> onChangeFilter(page,rowPerPage,filter)}
                    isPagination
                    isLoading = {isLoading}
                    selectedPage = {selectedPage}
                    onChangePage = {(e)=> onChangePage(e)}
                    selectedpageSize = {selectedPageSize}
                    onChangePageSize = {(e)=> onChangePageSize(e)}
                    isToptActions
                    isRightActions
                    topActionsOptions = {(e)=>{return <>
                        <button
                            type = 'button'
                            className='btn btn-outline-primary form-control'
                            style={{width:'200px',height:'85%', display:'flex',justifyContent:'space-between',alignItems:'center'}}
                            onClick={()=> onClickAddRequest()}
                        >
                            <i class="fa-solid fa-plus"></i>
                            &nbsp;
                            <b>Yeni İstek Ekle</b>
                        </button>
                        <button
                            className='btn btn-success btn-sm form-control'
                            style={{width:'150px',height:'85%', display:'flex',justifyContent:'space-between',alignItems:'center',marginLeft:'4px'}}
                        >
                            <i className='far fa-file-excel' style={{fontSize:"18px"}}/>
                            &nbsp;
                            <b>Excel</b>
                        </button>
                    </>}}
                    rightActionsOptions = {(e,ind)=>{return <>
                            <i class="fa-regular fa-eye tableActionIcon" style={{fontSize:"26px"}} onClick={()=> onClickPick(e,ind)}></i>
                            <i className='far fa-edit tableActionIcon' style={{fontSize:"26px"}}/>
                            
                    </>}}
                />
            </div>
        </div> 
        <PopupComponent
            width = '60%' 
            height = '80%'
            isOpen = {popup} 
            onClickClose={()=> onClosePopup(true)} 
            title='İstek Bilgileri'
        >
            <AddRequestForm
                request     = {selectedRequest}
                onCancel    = {()=> onCloseAddPopup(false)}
                onSubmitted = {()=> onCloseAddPopup(true)}
            />
        </PopupComponent>
    </>)
}