import Notiflix, { Confirm } from 'notiflix';

export const load = Notiflix.Loading ;

export const loading=(msg)=>{
    load.circle(msg?msg:'',{
        backgroundColor  : 'rgba(0,0,0,0.70)',
        svgSize          : '150px',
        messageMaxLength : 1000,
        messageColor     : 'white',
        svgColor         : '#0B2447'        
    });
};

export const removeLoading=()=>{
    load.remove();
}

export const success = (msg)=>{
    Notiflix.Notify.success(msg);
};

export const failed = (msg)=>{
    Notiflix.Notify.failure(msg);
};

export const DeleteWarn=(title,body,fun)=>{
    Notiflix.Confirm.init({
        titleColor : 'red',
        okButtonBackground : 'red'
    })
    return Notiflix.Confirm.show(
        title,body,'Sil','İptal',fun
    )
}

export const confirm=(title,body,fun)=>{
    Notiflix.Confirm.init({
        titleColor : 'black'
    })
    return Notiflix.Confirm.show(
        title,body,'Kaydet','İptal',fun
    )
}

export const warning =(msg)=>{
    Notiflix.Notify.warning(msg);
};