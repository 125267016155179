import React, { useEffect, useState } from 'react';
import '../style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setUserInfo } from '../../Redux/Actions/userActions';
import axios from 'axios';
import { _serverURL } from '../../config/config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { loading, removeLoading } from '../../Component/commons/alerts';

export function SideBar(){
    const history = useHistory();
    const user = useSelector(state => state.getUserInfo);
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    

    const [sideBarItem , setSideBarItem] = useState([]);


    const onClickArrow=(e)=>{
        e.stopPropagation();
        let arrowParent = e.target.parentElement.parentElement.parentElement;
        arrowParent.classList.toggle('showMenu');
    }
    const onMouseEvent=(option)=>{
        if(option == 'in'){
            document.getElementById('sideBar').classList.remove('close');
            document.getElementById('TopBarContainer').style.width = 'calc(100% - 270px)';
            
        }else{
            document.getElementById('sideBar').classList.add('close');
            document.getElementById('TopBarContainer').style.width = 'calc(100% - 78px)';
        }
        
    }

    const onClickLogOut=()=>{
        loading()
        axiosPrivate.get('/logout',{
            withCredentials: true
        }).then((res)=>{
            dispatch(setUserInfo({
                first_name : null,
                last_name  : null,
                username : null,
                auth     : false ,
                id       : null,
                rols     : null,
            }))
        }).catch((err)=>{

        }).finally(()=>{removeLoading()})
        
        //history.push('/');
    }

    const getItems=async()=>{
        console.log('odddddddd')
        const response = await axiosPrivate.get('/user/navbar')
        .then((res)=>{
            console.log(res.data.rows);
            setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };
    const onClickItem=(path)=>{
        history.push(path);
    };
    useEffect(()=>{
        getItems();
        return()=>{};
    },[])

    return (
    <div 
        id = 'sideBar'
        className='sideBar close' 
        onMouseEnter = {(e)=> onMouseEvent('in')}
        onMouseLeave = {(e)=> onMouseEvent('out')}>
        <div className='logo-details'>
            <div className='logo-container'>
                <img src = {require('../../assets/shereen_care_logo1.png')}></img>
            </div>
            <span className='logo-name' style={{}}>
                <img src = {require('../../assets/shereen_care_logo2.png')}></img>
            </span>
        </div>
        <ul className='nav-links'>
            {sideBarItem.map((item)=>{
                return  <li> 
                    <div className='icon-link'>
                        <div  onClick={()=> onClickItem(item.path)} style={{cursor:'pointer'}}>
                            <i className ={item.icon}></i>
                            <span className = 'link-name'> 
                                {item.name}
                            </span>
                            {item.subMenu.length >0 && 
                                <i
                                onClick={(e)=> onClickArrow(e)} 
                                class ='bx bxs-chevron-down arrow'/>
                            }
                        </div>
                    </div>
                    <ul className='sub-menu'>
                        {item.subMenu.map((subItem)=>{
                            return <li onClick={()=> onClickItem(item.path+subItem.path)} style={{cursor:'pointer'}}>
                                <div >
                                    {subItem.name}   
                                </div>
                            </li> 
                        })}
                    </ul>
                </li>
            })}
            
            <li>
                <div className='profile-details'>
                    <div className='profile-content'>
                        <img src={require('../../assets/profile.png')} alt='profile'></img>
                    </div>
                    <div className='name-job'>
                        <div className='profile-name'>
                            {user.first_name + " " + user.last_name}
                        </div>
                        <div className='job'>
                            {user.username}
                        </div>
                    </div>
                    <i 
                        onClick={()=> onClickLogOut()} 
                        style={{fontSize:'22px',cursor:'pointer'}} 
                        class='bx bx-log-out logoutIcon' >
                    </i>
                </div>
            </li>
        </ul>
    </div>
    )
}