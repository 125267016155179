import React, { useEffect, useState } from 'react';
import './style.css';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Select from 'react-select';
import { failed, loading, removeLoading, success } from '../../commons/alerts';

export function AddEditUserForm(props){
    const axiosPrivate = useAxiosPrivate();
    const [roles,setRoles] = useState([]);
    const [user , setUser] = useState(null);
    const [selectedOptions,setSelectedOptions] = useState([]);
    const onClickClose=()=>{
        if(props.onClickClose){
            props.onClickClose();
        }
    }

    const onSubmit=(e)=>{
        e.preventDefault()
        const {
            first_name , last_name , id_no , tel_no , email , address , username , password
        } = e.target;
        console.log(address.value);
        console.log(e.target);
        let json = {
            first_name : first_name.value , 
            last_name  : last_name.value , 
            id_no      : id_no.value, 
            tel_no     : tel_no.value, 
            email      : email.value, 
            address    : address.value, 
            username   : username.value, 
            password   : password.value,
            roles      : selectedOptions.map((d)=> {return d.value}), 
        }
        console.log(json);
        loading();
        if(props.id){
            axiosPrivate.put('/user/id/'+props.id,json)
            .then((res)=>{
                success(res.data.message);
                console.log(res.data);
                //setSideBarItem(res.data.rows);
            }).catch((err)=>{
                console.log(err);
                failed(err.message);
            }).finally(()=>{removeLoading();props.onClickClose(true);})
        }else{
            axiosPrivate.post('/user',json)
            .then((res)=>{
                success(res.data.message);
                console.log(res.data);
                //setSideBarItem(res.data.rows);
            }).catch((err)=>{
                console.log(err);
                failed(err.message);
            }).finally(()=>{removeLoading();props.onClickClose(true);})
        }
    }

    const getRoles=()=>{
        axiosPrivate.get('/user/roles')
        .then((res)=>{
            console.log(res.data);
            setRoles(res.data.rows.map((d)=>{return {label : d.name , value : d.id}}));
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{})
    };

    const getUserInfo=()=>{
        loading('');
        axiosPrivate.get('/user/id/'+props.id)
        .then((res)=>{
            console.log(res.data);
            setUser(res.data.obj);
            if(res.data.obj?.roles?.length > 0){
                setSelectedOptions(res.data.obj.roles.map((d)=> {return {label : d.name , value : d.id}}));
            }
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{removeLoading();})
    }
    useEffect(()=>{
        getRoles();
        if(props.id){
            getUserInfo();
        }
        return ()=>{};
    },[])

    return(<>
    <form style={{width:'100%',height:'100%', paddingTop:'20px'}} autoComplete='off' onSubmit={(e)=> onSubmit(e)}>
        <div className='row' style={{height:'calc(100% - 50px)',overflowY:'auto'}}>
            <div className='col-12'>
                <div className='row'>
                    <div class="col-6">
                        <label for="first_name" class="form-label bold">Ad</label>
                        <input type="text" class="form-control form-control-sm" id="first_name" placeholder="Ad" required defaultValue={user?.first_name}/>
                    </div>
                    <div class="col-6">
                        <label for="last_name" class="form-label bold">Soyad</label>
                        <input type="text" class="form-control form-control-sm" id="last_name" placeholder="Soyad" required defaultValue={user?.last_name}/>
                    </div>    
                </div>
                <div className='row'>
                    <div class="col-6">
                        <label for="id_no" class="form-label bold">Kimlik No</label>
                        <input type="text" class="form-control form-control-sm" id="id_no" placeholder="xxxxxxxxxxx"  defaultValue={user?.id_no}/>
                    </div>
                    <div class="col-6">
                        <label for="tel_no" class="form-label bold">Telefon Numarası</label>
                        <input type="text" class="form-control form-control-sm" id="tel_no" placeholder="0 555 555 55 55" required defaultValue={user?.tel_no}/>
                    </div>
                </div>
                <div className='row'>
                    <div class="col-6">
                        <label for="email" class="form-label bold">Email Adresi</label>
                        <input type="email" class="form-control form-control-sm" id="email" placeholder="user@email.com" defaultValue={user?.email}/>
                    </div>
                </div>
                <div className='row'>
                    <div class="col-12">
                        <label for="address" class="form-label bold">Adres</label>
                        <textarea class="form-control" id="address" rows="3" defaultValue={user?.address}></textarea>
                    </div>
                </div>
                <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                    <hr style={{width:'80%'}}/>
                </div>
                <div className='row'>
                    <div class="col-6">
                        <label for="username" class="form-label bold">Kullanıcı Adı</label>
                        <input type="text" class="form-control form-control-sm" id="username" placeholder="usename" required defaultValue={user?.username}/>
                    </div>
                    <div class="col-6">
                        <label for="password" class="form-label bold">Şifre</label>
                        <input type="text" class="form-control form-control-sm" id="password" placeholder="pasword" required defaultValue={user?.password}/>
                    </div>
                </div>
                <div className='row'>
                    <div class="col-12">
                        <label for="username" class="form-label bold">Yetki</label>
                        <Select
                            isClearable
                            isMulti
                            placeholder = 'Chosse Role(s) for the user '
                            options ={roles}
                            value={selectedOptions}
                            onChange={(e)=>{setSelectedOptions(e)}}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='row' style={{height:'50px'}}>
            <div className='col-6' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <button 
                type="button" 
                className="btn btn-danger btn-sm" 
                style={{width:'95%'}}
                onClick={()=> onClickClose()}
            >   
                İptal
            </button>
            </div>
            <div className='col-6' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <button 
                    type="submit" 
                    className="btn btn-success btn-sm" 
                    style={{width:'95%'}} 
                >
                    Kaydet
                </button>
            </div>
        </div>
    </form>
    </>);
}