import React, { useEffect, useState } from "react";

import CardView2 from "../../commons/CardView2";
import { PatintBrief } from "../../Requests/forms/PatintBrief";
import PatentInfo from "../Forms/PatentInfo";
import PatentAddress from "../Forms/PatentAddress";
import { PatentDocument } from "../../commons/PatentDocument";
import ReferanceInfos from "../Forms/ReferanceInfos";
import "./PatentAdd.css";
import { axiosPrivate } from "../../../api/axios";
import { confirm, success, warning } from "../../commons/alerts";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function PatentAdd() {
  const id = useParams().id;
  const [form, setForm] = useState({});
  //const [patient, setPatient] = useState();
  const [patientDocuments, setPatientDocuments] = useState([]);
  const onClickSave = () => {
    confirm("Bilgiler Kaydedilecek!", "Emin misiniz?", async () => {
      if(id){
        await axiosPrivate.put("/patient/id/"+id, form).then((res) => {
          console.log(res, "ressss");
          success(res.data.msg);
          
        });
      }else{
        await axiosPrivate.post("/patient/save", form).then((res) => {
          console.log(res, "ressss");
          success(res.data.info.msg);
          if (res.data.info.patient_id && patientDocuments.length > 0) {
            let formdata = new FormData();
            for (let i = 0; i < patientDocuments.length; i++) {
              formdata.append("files", patientDocuments[i]);
            }
            formdata.append("patient_id", res.data.info.patient_id);
            axiosPrivate
              .post("/document/to/patient", formdata, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                res.data.notifications.forEach((n) => {
                  if (n.status) {
                    success(n.msg);
                  } else {
                    warning(n.msg);
                  }
                });
              })
              .catch((err) => {
                console.log(err);
                warning("Error occured while uploading documents");
              });
            // .finally(()=>{
            //     history.goBack();
            // })
          }
        });
      }
    });
  };
  const getData = async () => {
    await axiosPrivate
      .get("/patient/id/" + id)
      .then((res) => {
        setForm(res.data.patient);
      })
      .catch((err) => {
        console.log(err);
        warning("Error Occured while retriving patient info");
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }

    return () => {};
  }, []);
  useEffect(() => {
    console.log(form,"formMMM");

    return () => {};
  }, [form]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "5px",
        }}
      >
        <div className="row">
          <div className="col-6">
            <CardView2 title="Hasta Bilgileri">
              {/* <PatintBrief /> */}
              <PatentInfo form={form} setForm={setForm} />
            </CardView2>
          </div>
          <div className="col-6">
            <CardView2 title="Hasta Adresi">
              <PatentAddress form={form} setForm={setForm} />
            </CardView2>
          </div>
        </div>
        <div className="row" style={{ height: "10px" }}></div>
        <div className="row">
          <div className="col-12">
            <CardView2 title="Hasta Belgeleri">
              <PatentDocument
                id={form?.id}
                patientDocuments={patientDocuments}
                setPatientDocuments={setPatientDocuments}
              />
            </CardView2>
          </div>
        </div>
        <div className="row" style={{ height: "10px" }}></div>
        <div className="row">
          <div className="col-12">
            <CardView2 title="Referans Bilgileri">
              <ReferanceInfos form={form} setForm={setForm} />
            </CardView2>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "60px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          paddingInline: "20px",
          borderTop: "1px #ddd solid",
        }}
      >
        <button
          type="button"
          class="btn btn-danger"
          style={{ width: "150px", marginRight: "20px" }}
        >
          İPTAL
        </button>
        <button
          type="button"
          class="btn btn-success"
          style={{ width: "150px" }}
          onClick={() => onClickSave()}
        >
          KAYDET
        </button>
      </div>
    </>
  );
}
