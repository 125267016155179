import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { UserTable } from './comonents/UserTable';
import PopupComponent from '../commons/PopupComponent';
import { AddEditUserForm } from './comonents/AddEditUserForm';
import { DeleteWarn, load, loading, removeLoading } from '../commons/alerts';
import { Confirm } from 'notiflix';


export function Users(){
    const axiosPrivate = useAxiosPrivate();
    const [users,setUsers] = useState([]);
    const [selectedUser,setSelectedUser] = useState(null);
    const [isPopup , setIsPopup] = useState(false);

    const getUsers=()=>{
        loading('fetch users');
        axiosPrivate.get('/user')
        .then((res)=>{
            console.log(res.data.rows);
            setUsers(res.data.rows);
            //setSideBarItem(res.data.rows);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{removeLoading()})
    };

    const onClickEdit=(id)=>{
        console.log(id);
        setSelectedUser(id);
        setIsPopup(true);
    }

    const onClosePopup=(d)=>{
        setSelectedUser(null);
        setIsPopup(false);
        if(d){
            getUsers();
        }
    }

    const onClickDelete=(id,ind)=>{
        DeleteWarn(
            'Kullanıcı Silinecek',
            'Emin misiniz?',
            ()=>{
                loading();
                axiosPrivate.delete('/user/id/'+id)
                .then((res)=>{
                    console.log(res.data.rows);
                    users.splice(ind,1);
                    setUsers([...users]);
                    //setSideBarItem(res.data.rows);
                }).catch((err)=>{
                    console.log(err);
                }).finally(()=>{removeLoading()})
            }
        )
    }
    useEffect(()=>{
        getUsers();
        return ()=>{};
    },[])
    return (<>
        <div style={{width:'100%','height':'100%',overflow:'hidden'}}>
            <div className='row' style={{height:'50px',borderBottom:'1px grey solid'}}>
                <div className='col-10' style={{background:''}}>
                </div>
                <div className='col-2' style={{display:'flex',alignItems:'center'}}>
                    <button 
                        type="button" 
                        className="btn btn-outline-success btn-sm" 
                        style={{width:'100%',marginLeft:'-5px',fontSize:'16px'}}
                        onClick={()=> setIsPopup(true)}
                    >
                        <i class="fa-solid fa-user-plus" style={{marginRight:'5px'}}></i>
                        Personel Ekle
                    </button>
                </div>
            </div>
            <div className='row' style={{height:'calc(100% - 50px)',paddingTop:'25px'}}>
                <UserTable
                    list = {users}
                    onClickEdit = {(id)=>{onClickEdit(id)}}
                    onClickDelete = {(id,ind) => onClickDelete(id,ind)}
                />
            </div>
        </div>
        <PopupComponent
            width = '60%' 
            isOpen = {isPopup} 
            onClickClose={()=> onClosePopup()} 
            title='Kullanıcı Ekle'>
            <AddEditUserForm
                id           = {selectedUser}
                onClickClose = {(d)=> onClosePopup(d)}
            />
        </PopupComponent>
    </>)
}