import React from 'react'
import './blogPageHome.css'
import Sidebar from '../BlogSidebar/BlogSidebar'
import BlogPageHeader from '../BlogPageHeader/BlogPageHeader'
import BlogPosts from '../BlogPosts/BlogPosts'




function BlogPageHome() {
  return (
    <>
        <BlogPageHeader/>
       <div className="blogPageHome">
        <BlogPosts/>
        <Sidebar/>
       </div>
    </>
  )
}

export default BlogPageHome