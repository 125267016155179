import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { TextField, withStyles } from '@material-ui/core';
//import 'react-phone-number-input/style.css';
import "react-phone-input-2/lib/material.css";
import 'react-phone-input-material-ui/lib/material.css';
import './style.css';
import { border } from '@mui/system';
import { TextInput } from './TextInput';
import axios from 'axios';

function PhoneInput(props) {
  const { value, defaultCountry, onChange, classes , required ,defaultValue} = props;
  //console.log(classes)
  const [dc , setdc] = useState();

  const getDefaultCountry=()=>{
    if(props.id){
      setdc('+90');
      return
      axios.get('https://ipapi.co/json/')
      .then((response) => {
          //document.getElementById(props.id).value = response.data.country_calling_code ;
          setdc( response.data.country_calling_code)
      }).catch((error) => {
          console.log(error);
      });
    }
  }

  useEffect(()=>{
    console.log(defaultValue)
    if(defaultValue == undefined){
      getDefaultCountry()
    }else{
      console.log(defaultValue)
      setdc(defaultValue)
    }
    return ()=>{};
  },[defaultValue])
  return (
    <ReactPhoneInput
        id = {props.id}
        //countryCodeEditable={false}
        //defaultCountry={'USA'}
        //  enableSearchField
        required = {required}
        onChange={onChange}
        component={TextInput}
        label="phone"
        disabled = {props.disabled}
        value    = {dc}
        inputProps = {{
          id : props.id,
          label : props.label,
          required : props.required,
          inputFontColor : props.inputFontColor,
          labelColorNF : props.labelColorNF,
          labelColorAF : props.labelColorAF,
          backgroundColor : props.backgroundColor,
          error           : props.error,
          inputSx:{
            marginLeft:'55px !important',
            width:'calc(100% - 55px)'
          },
          sx : {
            '& label': {
              marginLeft:'55px !important',
            },
            '& label.Mui-focused': {
              //marginLeft:'55px !important'
              //width:'calc(100% - 55px)'
            },
            '& .MuiInput-underline:after': {     /// border color when focused 
              //marginLeft:'55px !important'
              //width:'calc(100% - 55px)'
            },
            '& .MuiInput-underline:before': {    /// border color when not focused 
              //marginLeft:'55px !important'
              //width:'calc(100% - 55px)'
            },
          },
        }}
    />
      // <ReactPhoneInput
      //   id = {props.id}
      //   //countryCodeEditable={false}
      //   //defaultCountry={"dk"}
      //   //  enableSearchField
      //   onChange={onChange}
      //   component={TextField}
      //   label="phone"
      //   inputStyle={{ 
      //     border: "none", 
      //     fontSize: "10px !important" ,
      //     width :'100%',
      //     margin:'0px',
      //     height: '48px',
      //     overflow: 'hidden',
      //     display: 'flex',
      //     justifyContent: 'center',
      //     alignItems: 'top',
      //     background: 'transparent'
      //   }}
        
      //   inputProps = {{
      //     id : props.id,
      //     required : props.required
      //   }}
      // />
  );
}

export default PhoneInput;